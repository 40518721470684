import { FunctionComponent, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import { styles } from "../../styles/themes/style";
import styled from "styled-components";

import { useAppHandlers } from "../../services/actions/app/useAppHandlers";

import Button from "../Button";
import Text from "../Text";
import leadDeltaLogo from '../../assets/img/header/lead-delta-logo.png'
import leadDeltaDarkLogo from '../../assets/img/header/lead-delta-dark-logo.png'
import SvgFragment from "../../fragments/SvgFragment";

interface IModal extends IModalHeader, IModalFooter {
    children?: ReactNode
    logo?: boolean
    checkmark?: boolean
    icon?: string
    divider?: boolean
    confirmationMessage?: string
    cancelationMessage?: string
    bodyPadding?: string
    headingTextCenter?: boolean
}

interface IModalHeader {
    headingText?: ReactNode
    descriptionText?: ReactNode
    descriptionTextBold?: ReactNode
    closeButton?: boolean
}

interface IModalFooter {
    $center?: boolean
    confirmButton?: IButtonStyle
    cancelButton?: IButtonStyle
    infoButton?: IButtonStyle
}

interface IButtonStyle {
    text?: string
    type?: string
    disable?: boolean
    bigButton?: boolean
    mediumButton?: boolean
    extraBigButton?: boolean
    customWidth?: boolean
    svgType?: string
    tooltipMessage?: string
    isLoading?: boolean
    customDisabled?: boolean
    onClickHandler?: (e: any) => void
}

const CustomModal: FunctionComponent<IModal> = ({ confirmationMessage, cancelationMessage, children, checkmark, icon, divider, headingText, descriptionText, descriptionTextBold, closeButton, $center, confirmButton, cancelButton, infoButton, bodyPadding, headingTextCenter }) => {
    const { hideModal, setConfirmationModal } = useAppHandlers()

    const onConfirmationMessageHandler = (message: string) => {
        setConfirmationModal({
            descriptionTextBold: message,
            onCancelHandler: cancelButton?.onClickHandler,
            onConfirmHandler: confirmButton?.onClickHandler
        })
    }

    const renderButton = (button: IButtonStyle | undefined, defaultType: string, messageHandler: string | undefined) => {
        if (!button) return null;
    
        const {
            disable,
            type = defaultType,
            bigButton,
            mediumButton,
            extraBigButton,
            customWidth,
            svgType,
            tooltipMessage,
            isLoading,
            onClickHandler,
            text,
            customDisabled
        } = button;
    
        return (
            <Button
                disabled={disable}
                $type={type}
                $bigButton={bigButton}
                $mediumButton={mediumButton}
                $extraBigButton={extraBigButton}
                $customWidth={customWidth}
                $SVGtype={svgType}
                $customDisabled={customDisabled}
                tooltipMessage={tooltipMessage ?? ''}
                isLoading={isLoading}
                onClickHandler={(e) => {
                    messageHandler ? onConfirmationMessageHandler(messageHandler) : onClickHandler?.(e);
                }}
            >
                {text}
            </Button>
        );
    };

    return (
        <>
            <StyledHeading checkmark={checkmark} headingTextCenter={headingTextCenter}>
                <Heading>
                    {icon && <SvgFragment type={icon} />}
                    {checkmark && <Checkmark><SvgFragment type='checkmark' /></Checkmark>}
                    {(headingText && !checkmark) && headingText}
                    {(!headingText && !checkmark) && <StyledLogo />}
                </Heading>
                {closeButton && <X onClick={() => hideModal()}> <SvgFragment type="close" /> </X>}
            </StyledHeading>
            {divider && <Divider />}
            {(descriptionTextBold || descriptionText) && <TextWrapper>
                {descriptionTextBold && <Text $heading3 $black>{descriptionTextBold}</Text>}
                {descriptionText && <Text $lighter>{descriptionText}</Text>}
            </TextWrapper>}
            {children && <StyledBody padding={bodyPadding}>{children}</StyledBody>}
            {divider && <Divider />}
            <StyledModalFooter $center={$center}>
                {renderButton(cancelButton, 'cancel', cancelationMessage)}
                {renderButton(infoButton, 'grey', confirmationMessage)}
                {renderButton(confirmButton, 'blue', confirmationMessage)}
            </StyledModalFooter>
        </>
    )
}

const StyledHeading = styled(Modal.Header)<{checkmark?: boolean, headingTextCenter?: boolean}>`
    border-bottom: none;
    padding: 24px;
    align-items: flex-start;
    gap: 16px;
    justify-content: space-between;
    ${({ checkmark, headingTextCenter }) => (checkmark || headingTextCenter) && 'justify-content: center;'}

    & .btn-close { padding-top: 18px; }
    & .btn-close:focus { box-shadow: none; }
    /* & .modal-title { ${({ closeButton }) => closeButton ? 'margin-left: auto' : 'margin: auto;'} } */
`

const Heading = styled(Modal.Title)`
    display: flex;
    flex-direction: row;
    font-family: NunitoSans;
    align-items: center;
    gap: 6px;
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    color: ${({ theme: { primary } }) => primary ? styles.colors.black600 : styles.colors.darkTextcolor};
`

const Checkmark = styled.div`
    width: 86px;
    height: 86px;
    background-color: ${styles.colors.green200};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .svg{
        width: 46px;
        height: 46px;
        .path {
            fill: ${styles.colors.green600}
        }
    }
`

const TextWrapper = styled.div`
    padding: 0 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    /* & span:nth-child(2) { margin-top: 10px; } */
`

const StyledBody = styled(Modal.Body)<{padding?: boolean}>`
    ${({ padding }) => padding ? `padding: ${padding}` : 'padding: 0 24px 24px 24px;'}
`

const StyledLogo = styled.img`
    width: 141px;
    height: 32px;
    content: url('${({ theme: { primary } }) => primary ? leadDeltaLogo : leadDeltaDarkLogo}');
`

const X = styled.div`
    position: absolute;
    right: 20px;
    cursor: pointer;

    & .svg {
        height: 24px;
        width: 24px;
    }
`
const Divider = styled.div`
    border: 1px solid ${({ theme: { quinaryColor } }) => `${quinaryColor}`};
`
const StyledModalFooter = styled(Modal.Footer)`
    border-top: none;
    display: flex;
    flex-wrap: nowrap;
    gap: 15px;
    padding: 0 24px 24px 24px;

    ${({ $center }) => $center && `align-self: center;`}

    & button { margin: 0; }
`

export default CustomModal