import React from 'react';
import styled from 'styled-components';

import Input from '../../../components/Input';
import { isValid } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { useAppHandlers } from '../../../services/actions/app/useAppHandlers';
import { useAuthRequest } from '../../../services/apis/auth/useAuthRequest';
import { AppRoutes } from '../../../interfaces/store/appStore';
import { styles } from '../../../styles/themes/style';
import Logo from '../../../components/Logo';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import ActionText from '../../../components/ActionText';
import { useExternalHandlers } from '../../../services/actions/navigation/useExternalHandlers';


const ForgotPasswordSection = React.forwardRef((props: any, ref: any) => {
    const [step, setStep] = React.useState(1);
    const [values, setValues] = React.useState<any>({});
    const [valid, setValid] = React.useState(false);
    const [loaded, setLoaded] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState<any>(false);
    const { showSuccessToast, showErrorToast } = useAppHandlers();
    const { verificationCode, verificationCodeValidate, resetPassword } = useAuthRequest();
    const { openTermsAndConditions, openPrivacyPolicy } = useExternalHandlers()
    const navigate = useNavigate();

    /* useEffect(() => {
        resetAuthStore()
    }, []) */

    const SendResetInstructions = React.useCallback(async () => {
        setLoading(true);

        try {
            if (valid) {
                const response = await verificationCode({
                    email: values.email
                });

                if (response?.success) {
                    setStep(2);

                    showSuccessToast({ message: `Your verification code has been successfully sent.` });
                }
                else {
                    showErrorToast({ message: response?.message });
                }
            }
        }
        catch (error) {
            console.log('Sign up with email', error);
        }

        setLoading(false);
    }, [values, valid]);

    const VerificationCodeConfirm = React.useCallback(async () => {
        setLoading(true);

        try {
            if (valid) {
                const response = await verificationCodeValidate({
                    email: values.email,
                    code: values.verificationCode
                });

                if (response?.success) {
                    setStep(3);

                    updateValue('token', response.data.token);

                    showSuccessToast({ message: `Your verification code has been confirmed successfully.` });
                }
                else {
                    showErrorToast({ message: response?.message });
                }
            }
        }
        catch (error) {
            console.log('Sign up with email', error);
        }

        setLoading(false);
    }, [values, valid]);

    const UpdateNewPassword = React.useCallback(async () => {
        setLoading(true);

        try {
            if (valid) {
                const response = await resetPassword({
                    email: values.email,
                    password: values.newPassword,
                    passwordConfirmation: values.newPasswordConfirmation,
                    token: values.token
                });

                if (response?.success) {
                    showSuccessToast({ message: `Your password has been successfully created.` });

                    navigate(AppRoutes.SIGNIN);
                }
                else {
                    showErrorToast({ message: response?.message });
                }
            }
        }
        catch (error) {
            console.log('Sign up with email', error);
        }

        setLoading(false);
    }, [values, valid]);

    const updateValid = React.useCallback((valuesNew: any) => {
        const validNew = !!(
            isValid.email(valuesNew.email)
        );

        setValid(validNew);
    }, []);

    const updateValue = React.useCallback((property: string, valueNew: any) => {
        setValues((previous: any) => {
            const valuesNew = {
                ...previous,
                [property]: valueNew
            };

            // Valid 
            updateValid(valuesNew);

            return valuesNew;
        });
    }, []);

    const parts = {
        form: (
            <InnerContainer>
                <Column
                    style={{
                        gap: 16
                    }}
                >
                    <Logo />
                    <Title>Forgot your password?</Title>
                    <Label>
                        Email address
                        <Input
                            placeholder='Enter your email address'
                            type='email'
                            value={values.email || ''}
                            onChangeHandler={valueNew => updateValue('email', valueNew)}
                        />
                    </Label>
                    <Button
                        type='submit'
                        $type='light'
                        tooltipMessage=''
                        disabled={!valid}
                        onlySpinner={loading}
                        onClickHandler={SendResetInstructions}
                    >
                        Send Reset Instruction
                    </Button>
                    <Link color={styles.colors.black300} onClick={() => navigate(AppRoutes.SIGNIN)}>Back to login</Link>
                    <Meta>
                        For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.
                    </Meta>
                </Column>
            </InnerContainer>
        ),
        formVerficationConfirm: (
            <InnerContainer>
                <Column
                    style={{
                        gap: 16
                    }}
                >
                    <Logo />
                    <Title>Forgot your password?</Title>
                    <Label>
                        Verification code
                        <Input
                            placeholder='Enter the code you received via email'
                            type='verificationCode'
                            value={values.verificationCode || ''}
                            onChangeHandler={valueNew => updateValue('verificationCode', valueNew)}
                        />
                    </Label>
                    <Button
                        type='submit'
                        $type='light'
                        tooltipMessage=''
                        onlySpinner={loading}
                        onClickHandler={VerificationCodeConfirm}
                        disabled={!(values.verificationCode) || loading}
                    >
                        Submit Verification Code
                    </Button>
                    <Link color={styles.colors.black300} onClick={() => navigate(AppRoutes.SIGNIN)}>Back to login</Link>
                    <Meta>
                        For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.
                    </Meta>
                </Column>
            </InnerContainer>
        ),
        updateNewPassword: (
            <InnerContainer>
            <Column
                style={{
                    gap: 16
                }}
            >
                <Logo />
                <Title>Reset password</Title>
                <Label>
                    <span>Password</span>
                    <Input
                        placeholder='Enter new password'
                        type={showPassword === 'newPassword' ? 'text' : 'password'}
                        value={values.newPassword || ''}
                        onChangeHandler={valueNew => updateValue('newPassword', valueNew)}
                        style={{
                            paddingRight: 60
                        }}
                    />
                    <Show
                        onClick={() => setShowPassword((previous: any) => previous === 'newPassword' ? false : 'newPassword')}
                    >
                        {showPassword === 'newPassword' ? 'Hide' : 'Show'}
                    </Show>
                </Label>
                <Label>
                    <span>Password Confirmation</span>
                    <Input
                        placeholder='Confirm new password'
                        type={showPassword === 'newPasswordConfirmation' ? 'text' : 'password'}
                        value={values.newPasswordConfirmation || ''}
                        onChangeHandler={valueNew => updateValue('newPasswordConfirmation', valueNew)}
                        style={{
                            paddingRight: 60
                        }}
                    />
                    <Show
                        onClick={() => setShowPassword((previous: any) => previous === 'newPasswordConfirmation' ? false : 'newPasswordConfirmation')}
                    >
                        {showPassword === 'newPasswordConfirmation' ? 'Hide' : 'Show'}
                    </Show>
                </Label>
                <Button
                        type='submit'
                        $type='light'
                        tooltipMessage=''
                        onlySpinner={loading}
                        onClickHandler={UpdateNewPassword}
                        disabled={!(
                            values.newPassword &&
                            values.newPasswordConfirmation &&
                            values.newPassword === values.newPasswordConfirmation
                        ) || loading}
                    >
                        Submit New Password
                </Button>
                <Link color={styles.colors.black300} onClick={() => navigate(AppRoutes.SIGNIN)}>Back to login</Link>
                <Meta>
                    For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.
                </Meta>
                </Column>
            </InnerContainer>
        )
    }

    return (
        <Container>
            {loaded && <>
                {step === 1 && parts.form}

                {step === 2 && parts.formVerficationConfirm}

                {step === 3 && parts.updateNewPassword}
            </>}
            <FooterColumn>
                <Text>{'LeadDelta Inc. All rights Reserved.'}</Text>
                <Row><ActionText onClickHandler={()=>openTermsAndConditions()}>Terms of Service</ActionText><Text>and</Text><ActionText onClickHandler={()=>openPrivacyPolicy()}>Privacy Policy.</ActionText></Row>
            </FooterColumn>
        </Container>
    );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 116px 470px 0px 470px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 400px;
  height: 100%;
  background-color: white;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column; 
    align-items: center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
`;

const Link = styled.span<{ color?: string }>`
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    ${({ color }) => color ? `color: ${color};` : `color: ${styles.colors.primary600};`}
    transition: opacity 0.3s ease 0s; 

    &:hover {
        opacity: 0.54;
    }
`;

const Title = styled.h1`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px; 
    color: ${(props: any) => props.theme.primaryColor}; 
    margin-bottom: 12px; 
`;

const Label = styled.label`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 6px; 
    width: 100%;
    font-family: NunitoSans;
    letter-spacing: 0.5px;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    font-weight: bold;
    color: ${(props: any) => props.theme.nonaryColor}; 
    cursor: default;
`;

const Show = styled.span`
    position: absolute;
    bottom: 8px;
    right: 12px;
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${(props: any) => props.theme.primary600};
    cursor: pointer;
    user-select: none; 
`
const Meta = styled.p`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${(props: any) => props.theme.nonaryColor}; 
    margin-top: 4px; 
    text-align: center;
`
const FooterColumn = styled(Column)`
    padding: 28px;
    gap: 4px;
`

export default ForgotPasswordSection; 
