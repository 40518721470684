import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Logo from './Logo'
import { styles } from './../styles/themes/style';
import ProfilePhotoDropdown from './dropdown/header/ProfilePhotoDropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes, SidebarTypes, TMenuItemState } from '../interfaces/store/appStore';
import { useAppStore } from '../stores/application/useAppStore';
import { useAppHandlers } from '../services/actions/app/useAppHandlers';
import { ReactComponent as HamburgerIcon } from "../assets/img/hamburger.svg";
import { useSwipeable } from "react-swipeable";
import useMediaQuery from "./useMediaQuery";
import { ReactComponent as LogoEntire } from "../assets/img/logo.svg";
import SvgFragment from "../fragments/SvgFragment";
import Button from './Button';
import Row from './Row';
import { useTasksHandlers } from '../services/actions/tasks/useTasksHandlers';
import { useAuthStore } from '../stores/auth/useAuthStore';
import Notifications from '../fragments/Notifications';
import TooltipWrapper from './TooltipWrapper';
import moment from 'moment';
import { useAuthHandlers } from '../services/actions/auth/useAuthHandlers';

interface IHeaderProps { }

const Header: FunctionComponent<IHeaderProps> = () => {
  const navigate = useNavigate();

  const {
    store: { headerState, extensionInstalled, extensionVersion, onboardingStep },
  } = useAppStore(); //todo: set this component somewhere where it makes more sense
  const [headerList, setHeaderList] = useState<TMenuItemState[]>([]);
  const { openExtension, openSidebar, setSyncConnectionsModalHandler, setAddMembersModal } = useAppHandlers();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { completeSingleTask } = useTasksHandlers()
  const { store: { extensionInboxTaskId, user, disablePremiumFeatures, isLoggedToLinkedin, workspace } } = useAuthStore()
  const { syncConnectionsHandler } = useAuthHandlers()
  const isSmallMobile = useMediaQuery("(max-width: 968px)");

  /* const disableInbox = //todo: check this
        localStorage.getItem('newInboxUser') !== null ? 
            localStorage.getItem('newInboxUser') === 'true' ? true : false 
        : false */
  const location = useLocation()
  const pathname = location.pathname

  useEffect(() => {
    let headerList: any = [];
    if (headerState)
      headerList = Object.keys(headerState).map((key) => ({
        ...headerState[key],
      }));
    setHeaderList(headerList);
  }, [headerState]);

  const handleClick = useCallback(
    async (headerItem: TMenuItemState) => {
      if(headerItem.title === 'Inbox' && extensionInboxTaskId) await completeSingleTask(extensionInboxTaskId, true, false)
      if (headerItem.routeInternal) navigate(headerItem.routeInternal ?? "");
      if (headerItem.extension) openExtension({ query: `redirectTo=${headerItem.extension}`})
    },
    [headerState, extensionInboxTaskId]
  );

  //todo: encapsulate and add tooltips and create burger menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
  });

  const getTooltipText = (headerItem: any, isLoggedToLinkedin: boolean, extensionInstalled: boolean, extensionVersion: string) => {
    if(headerItem?.title === 'Inbox'){
      if (workspace?.isStarter) {
        return "Upgrade to Pro to unlock this feature";
      } else if (!extensionInstalled) {
        return "Please install the extension and sync your connections";
      } else if (extensionVersion !== process.env.REACT_APP_VERSION) {
        return "Update the LeadDelta extension";
      } else if (!isLoggedToLinkedin) {
        return "Please log in to LinkedIn to continue use inbox";
      } else {
        return "";
      }
    } else if(workspace.isStarter && (headerItem?.title === 'Templates' || headerItem?.title === 'Integrations')){
      return "Upgrade to Pro to unlock this feature";
    } else {
      return '';
    }
  }

  const isDisabled = (headerItem: any, isLoggedToLinkedin: boolean, extensionInstalled: boolean, extensionVersion: string) => {
    return (headerItem.title === 'Inbox' && (
      !isLoggedToLinkedin ||
      !extensionInstalled ||
      !user.isSynced || 
      extensionVersion !== process.env.REACT_APP_VERSION
    )) || 
    (
      workspace.isStarter && (
        headerItem?.title === 'Inbox' ||
        headerItem?.title === 'Templates' ||
        headerItem?.title === 'Integrations'
      )
    )
  }

  const setLastSyncAt = useCallback(async() => {
      if(!user?.isSynced) setSyncConnectionsModalHandler((user) => syncConnectionsHandler(user))
      else syncConnectionsHandler(user)
  }, [user])

  const lastSyncAtLocalStorage = window.localStorage.getItem('lastSyncAt')
  const lastSyncAt = lastSyncAtLocalStorage && lastSyncAtLocalStorage !== 'undefined' ? lastSyncAtLocalStorage : 0

  return (
    <Container {...swipeHandlers}>
      <LogoContainer>
        <Hamburger onClick={toggleMenu}>
          <HamburgerIcon />
        </Hamburger>
        {isSmallMobile && (<SlideMenu isOpen={isMenuOpen}>
          <Nav isOpen={isMenuOpen}>
            <LogoEntireContainer>
              <LogoEntire />
              <CloseSidebar onClick={toggleMenu}>
                <SvgFragment type={"close"} />
              </CloseSidebar>
            </LogoEntireContainer>
            {!disablePremiumFeatures && pathname !== AppRoutes.DELETE_USER_CONFIRMATION && headerList.map((headerItem, index) => {
              if (!headerItem.visible || (headerItem.title === 'Inbox' && isSmallMobile)) return null

              return (
                headerItem.visible && (
                  <Button
                    $justifyLeft
                    key={index}
                    disabled={isDisabled(headerItem, isLoggedToLinkedin, extensionInstalled, extensionVersion)}
                    {...pathname === headerItem.routeInternal && { $type: 'focused' }}
                    onClickHandler={() => isDisabled(headerItem, isLoggedToLinkedin, extensionInstalled, extensionVersion) ? undefined : handleClick(headerItem)}
                    $SVGtype={headerItem.icon}
                    tooltipMessage={getTooltipText(headerItem, isLoggedToLinkedin, extensionInstalled, extensionVersion)}
                  >
                    {headerItem.title}
                    {workspace.isStarter && (headerItem?.title === 'Inbox' || headerItem?.title === 'Templates' || headerItem?.title === 'Integrations') && 
                    <LockContainer>
                        <SvgFragment type={"lock2"} />
                    </LockContainer>
                  }
                  </Button>
                )
              );
            })}
          </Nav>
      </SlideMenu>)}

      {!isSmallMobile && (<>
          <StyledLogo><Logo onClick={() => navigate(AppRoutes.HOME)} /></StyledLogo>
          <Nav isOpen={isMenuOpen}>
          {!disablePremiumFeatures && pathname !== AppRoutes.DELETE_USER_CONFIRMATION && headerList.map((headerItem, index) => {
            if (!headerItem.visible) return null

            return (
              <TooltipWrapper key={index} tooltipText={getTooltipText(headerItem, isLoggedToLinkedin, extensionInstalled, extensionVersion)}>
                <NavButton
                  $disabled={isDisabled(headerItem, isLoggedToLinkedin, extensionInstalled, extensionVersion)}
                  $selected={headerItem.routeInternal === pathname}
                >
                  <a onClick={() => isDisabled(headerItem, isLoggedToLinkedin, extensionInstalled, extensionVersion) ? undefined : handleClick(headerItem)}>
                    {headerItem.title}
                  </a>
                  {workspace.isStarter && (headerItem?.title === 'Inbox' || headerItem?.title === 'Templates' || headerItem?.title === 'Integrations') && 
                    <LockContainer>
                        <SvgFragment type={"lock2"} />
                    </LockContainer>
                  }
                </NavButton>
              </TooltipWrapper>
            );
          })}
          </Nav>
        </>
      )}
      </LogoContainer>
      {user && <Row gap='10px'>
        {onboardingStep < 0 && pathname !== AppRoutes.DELETE_USER_CONFIRMATION && !disablePremiumFeatures && 
          <Button 
            $type='add-members'
            $customDisabled
            $width='175px'
            $SVGtype={'plus'}
            $iconColor={'white'}
            disabled={workspace?.isStarter}
            tooltipMessage={workspace?.isStarter ? 'Upgrade to Pro or Business to unlock this feature' : ''}
            onClickHandler={() => setAddMembersModal()}>
              {`Grow your network`}
          </Button>}
        <SyncButton>
          <Button 
            $round={true} 
            $height='32px'
            $SVGtype='refresh'
            tooltipMessage='Sync your connections'
            disabled={moment().isBefore(moment(lastSyncAt).add(15, 'minutes'))} 
            onClickHandler={()=>setLastSyncAt()}>
          </Button>
        </SyncButton>
        {onboardingStep < 0 && pathname !== AppRoutes.DELETE_USER_CONFIRMATION && !disablePremiumFeatures && <Notifications />}
        <ProfilePhotoDropdown />
      </Row>}
    </Container>
  );
};

const Container = styled.div`
    height: ${styles.lengths.navbarHeight};
    width: 100%;
    padding: 0px 16px 0px 0px;
    border-bottom: 2px solid ${({ theme: { quinaryColor } }) => quinaryColor};
    background-color: ${({ theme: { secondaryColor } }) => secondaryColor};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

const StyledLogo = styled.div`
  width: ${styles.lengths.railWidth};
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
`

const Hamburger = styled.div`
    display: none;
    cursor: pointer;
    font-size: 1.5em;
    width: ${styles.lengths.railWidth};
    @media (max-width: 968px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
`;

const SlideMenu = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 318px;
    background-color: ${({ theme: { secondaryColor } }) => secondaryColor};
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transform: ${({ isOpen }) =>
      isOpen ? "translateX(0)" : "translateX(-100%)"};
    transition: transform 0.3s ease;
    z-index: 1006;
    padding: 16px;
`;

const NavButton = styled.div<{$disabled: boolean, $selected: boolean}>`
    line-height: 20px;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: bold;
    padding: 0px 12px;
    height: 100%;
    display: flex;
    align-items: center;
    a {
      ${({ theme: { primaryColor } }) => primaryColor && `color: ${primaryColor};`}
    }
    ${({ $disabled }) => $disabled && `
        cursor: default;
        a, &:hover a, &:focus a{
            cursor: default;
            color: ${styles.colors.disabledState} !important;
        }
    `}
    ${({ $selected }) => $selected && `
        border-bottom: 2px solid #5964FF;
        cursor: default;
        a {
            cursor: default;
            color: ${styles.colors.primary600} !important;
        }
    `}
`

const Nav = styled.nav<{ isOpen: boolean }>`
    display: flex;
    height: ${styles.lengths.navbarHeight};
    width: 100%;
    padding: 0 4px;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 968px) {
      display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
      flex-direction: column;
      width: 100%;
      align-items: start;
      padding: 0;

      > * {
        margin: 8px 0;
      }
    }
`;

const LogoEntireContainer = styled.span`
    display: flex;
    width: 100%;
    height: 32px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    & > svg {
      height: 32px;
      width: auto;
    }
`;

const CloseSidebar = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .svg {
      width: 24px;
      height: 24px;
      .path {
        fill: ${({ theme: { disabledState } }) => disabledState};
      }
    }
`;

const LockContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-left: 8px;
`;

const SyncButton = styled.div`
    width: 34px;
    height: 34px;
    border-radius: 50%;
    color: white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default Header;
