import moment from "moment";
import React, {
  FormEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import ActionText from "../components/ActionText";
import Row from "../components/Row";
import { styles } from "../styles/themes/style";
import { useConnectionsHandlers } from "../services/actions/connections/useConnectionsHandlers";
import TooltipWrapper from "../components/TooltipWrapper";

interface IConnectionFilterLabelsProps {
  searchValues?: any[];
  connectionsFilter?: any;
  onRemoveLabel?: (key: string) => void;
  resetFiltersHandler?: () => void;
  saveFiltersHandler?: () => void;
}

interface IToggleOperatorProps {
  isActive?: boolean;
}

const ConnectionFilterLabels: FunctionComponent<
  IConnectionFilterLabelsProps
> = ({
  searchValues,
  connectionsFilter,
  onRemoveLabel,
  resetFiltersHandler,
  saveFiltersHandler,
}) => {
  const [labels, setLabels] = useState<
    {
      key: string;
      value: string;
      tooltip?: string;
      uid?: string;
      operator?: string;
    }[]
  >([]);
  const { setConnectionsFilterHandler } = useConnectionsHandlers();
  const [selected, setSelected] = useState("AND");

  const conversationStatusOptions = [
    { value: "all", label: "All conversations" },
    { value: "conversationsStarted", label: "Conversation started" },
    { value: "lastMessageOutgoing", label: "Last message outgoing" },
    { value: "lastMessageIncoming", label: "Last message incoming" },
    { value: "noConversationRecorded", label: "No conversation recorded" },
  ];

  useEffect(() => {
    if (connectionsFilter) {
      const newLabels: {
        key: string;
        value: string;
        tooltip?: string;
        uid?: string;
        operator?: string;
      }[] = [];

      connectionsFilter.searchValues.forEach((searchObject: any) => {
        if (searchObject.searchValue) {
          const searchCriteria = [
            searchObject.searchByName === "yes" ? "Name" : "",
            searchObject.searchByHeadline === "yes" ? "Headline" : "",
            searchObject.searchByJobTitle === "yes" ? "Job Title" : "",
            searchObject.searchByCompany === "yes" ? "Company" : "",
            searchObject.searchByLocation === "yes" ? "Location" : "",
            searchObject.searchByNote === "yes" ? "Note" : "",
          ].filter(Boolean);

          const tooltip = `${searchCriteria.join(", ")} : ${
            searchObject.searchValue
          }`;

          newLabels.push({
            uid: searchObject.uid,
            key: "searchValue",
            value: `Search: ${searchObject.searchValue}`,
            tooltip: tooltip,
            operator:
              newLabels?.length && searchObject?.isOutterValueSearchOr
                ? searchObject?.isOutterValueSearchOr === "yes"
                  ? "OR"
                  : "AND"
                : undefined,
          });
        }
      });

      if (
        connectionsFilter.connectedAtFrom !== undefined &&
        connectionsFilter.connectedAtFrom !== ""
      ) {
        let dateConnected = `Date Connected: ${moment(
          connectionsFilter.connectedAtFrom
        ).format("MMM D, YYYY")}`;
        if (
          connectionsFilter.connectedAtTo !== undefined &&
          connectionsFilter.connectedAtTo !== ""
        ) {
          dateConnected += ` - ${moment(connectionsFilter.connectedAtTo).format(
            "MMM D, YYYY"
          )}`;
        }
        newLabels.push({
          key: "connectedAt",
          value: dateConnected,
          tooltip: dateConnected,
        });
      } else if (
        connectionsFilter.connectedAtTo !== undefined &&
        connectionsFilter.connectedAtTo !== ""
      ) {
        newLabels.push({
          key: "connectedAt",
          value: `Date Connected: - ${moment(
            connectionsFilter.connectedAtTo
          ).format("MMM D, YYYY")}`,
          tooltip: `Date Connected: - ${moment(
            connectionsFilter.connectedAtTo
          ).format("MMM D, YYYY")}`,
        });
      }

      if (connectionsFilter.selectedUsersMutual?.length) {
        if (connectionsFilter.selectedUsersMutual?.length === 1) {
          newLabels.push({
            key: "selectedUsersMutual",
            value: `Shared Connections: ${connectionsFilter.selectedUsersMutual[0].firstName} ${connectionsFilter.selectedUsersMutual[0].lastName}`,
            tooltip: `Shared Connections: ${connectionsFilter.selectedUsersMutual[0].firstName} ${connectionsFilter.selectedUsersMutual[0].lastName}`,
          });
        } else {
          let tooltip = `Shared Connections: `;
          tooltip += connectionsFilter.selectedUsersMutual
            .map((user: any) => `${user.firstName} ${user.lastName}`)
            .join(", ");
          newLabels.push({
            key: "selectedUsersMutual",
            value: `Shared Connections: ${connectionsFilter.selectedUsersMutual.length} Selected`,
            tooltip: tooltip,
          });
        }
      }

      if (
        connectionsFilter.lastMessagedFrom !== undefined &&
        connectionsFilter.lastMessagedFrom !== ""
      ) {
        let dateLastMessaged = `Last Messaged: ${moment(
          connectionsFilter.lastMessagedFrom
        ).format("MMM D, YYYY")}`;
        if (
          connectionsFilter.lastMessagedTo !== undefined &&
          connectionsFilter.lastMessagedTo !== ""
        ) {
          dateLastMessaged += ` - ${moment(
            connectionsFilter.lastMessagedTo
          ).format("MMM D, YYYY")}`;
        }
        let tooltip = dateLastMessaged;
        if (connectionsFilter.conversationStatus !== undefined) {
          tooltip +=
            ", Conversation status: " +
            conversationStatusOptions?.find(
              (option: any) =>
                option?.value === connectionsFilter?.conversationStatus
            )?.label;
        }
        if (connectionsFilter.conversationOwners !== undefined) {
          if (
            connectionsFilter.conversationOwners.some(
              (item: any) => item.id === "all"
            )
          ) {
            tooltip += ", All members";
          } else if (connectionsFilter.conversationOwners?.length > 2) {
            tooltip += `, ${connectionsFilter.conversationOwners?.length} members selected`;
          } else {
            tooltip +=
              ", Conversation owners: " +
              connectionsFilter.conversationOwners
                .map((user: any) => user?.value)
                .join(", ");
          }
        }
        if (connectionsFilter.onlyLast === true) {
          tooltip += ", Show last messaged only";
        }
        newLabels.push({
          key: "lastMessaged",
          value: dateLastMessaged,
          tooltip: tooltip,
        });
      } else if (
        connectionsFilter.lastMessagedTo !== undefined &&
        connectionsFilter.lastMessagedTo !== ""
      ) {
        let tooltip = `Last Messaged: - ${moment(
          connectionsFilter.connectedAtTo
        ).format("MMM D, YYYY")}`;
        if (connectionsFilter.conversationStatus !== undefined) {
          tooltip +=
            ", Conversation status: " +
            conversationStatusOptions?.find(
              (option: any) =>
                option?.value === connectionsFilter?.conversationStatus
            )?.label;
        }
        if (connectionsFilter.conversationOwners !== undefined) {
          if (
            connectionsFilter.conversationOwners.some(
              (item: any) => item.id === "all"
            )
          ) {
            tooltip += ", All members";
          } else if (connectionsFilter.conversationOwners?.length > 2) {
            tooltip += `, ${connectionsFilter.conversationOwners?.length} members selected`;
          } else {
            tooltip +=
              ", Conversation owners: " +
              connectionsFilter.conversationOwners
                .map((user: any) => user?.value)
                .join(", ");
          }
        }
        if (connectionsFilter.onlyLast === true) {
          tooltip += ", Show last messaged only";
        }
        newLabels.push({
          key: "lastMessaged",
          value: `Last Messaged: - ${moment(
            connectionsFilter.connectedAtTo
          ).format("MMM D, YYYY")}`,
          tooltip: tooltip,
        });
      } else if (
        connectionsFilter.conversationStatus !== undefined &&
        connectionsFilter.conversationStatus !== ""
      ) {
        let tooltip = `Last Messaged: `;
        let value = `Last Messaged: `;
        if (connectionsFilter.conversationStatus !== undefined) {
          tooltip +=
            "Conversation status: " +
            conversationStatusOptions?.find(
              (option: any) =>
                option?.value === connectionsFilter?.conversationStatus
            )?.label;
          tooltip += ", ";
          value += conversationStatusOptions?.find(
            (option: any) =>
              option?.value === connectionsFilter?.conversationStatus
          )?.label;
          value += ", ";
        }
        if (connectionsFilter.conversationOwners !== undefined) {
          if (
            connectionsFilter.conversationOwners.some(
              (item: any) => item.id === "all"
            )
          ) {
            value += "All members";
            tooltip += "All members";
          } else if (connectionsFilter.conversationOwners?.length > 2) {
            value += `${connectionsFilter.conversationOwners?.length} members selected`;
            tooltip += `${connectionsFilter.conversationOwners?.length} members selected`;
          } else {
            value += connectionsFilter.conversationOwners
              .map((user: any) => user?.value)
              .join(", ");
            tooltip +=
              "Conversation owners: " +
              connectionsFilter.conversationOwners
                .map((user: any) => user?.value)
                .join(", ");
          }
        }
        if (connectionsFilter.onlyLast === true) {
          tooltip += ", Show last messaged only";
        }
        newLabels.push({
          key: "lastMessaged",
          value: value,
          tooltip: tooltip,
        });
      } else if (connectionsFilter.conversationOwners !== undefined) {
        let tooltip = `Last Messaged: `;
        let value = `Last Messaged: `;
        if (
          connectionsFilter.conversationOwners.some(
            (item: any) => item.id === "all"
          )
        ) {
          value += "All members";
          tooltip += "All members";
        } else if (connectionsFilter.conversationOwners?.length > 2) {
          value += `${connectionsFilter.conversationOwners?.length} members selected`;
          tooltip += `${connectionsFilter.conversationOwners?.length} members selected`;
        } else {
          value += connectionsFilter.conversationOwners
            .map((user: any) => user?.value)
            .join(", ");
          tooltip +=
            "Conversation owners: " +
            connectionsFilter.conversationOwners
              .map((user: any) => user?.value)
              .join(", ");
        }
        if (connectionsFilter.onlyLast === true) {
          tooltip += ", Show last messaged only";
        }

        newLabels.push({
          key: "lastMessaged",
          value: value,
          tooltip: tooltip,
        });
      } else if (connectionsFilter.onlyLast === true) {
        newLabels.push({
          key: "lastMessaged",
          value: "Show last messaged only",
          tooltip: "Show last messaged only",
        });
      }

      if (connectionsFilter.hiddenConnectionsValue?.value !== "no") {
        newLabels.push({
          key: "hiddenConnections",
          value: `Hidden Connections: ${connectionsFilter.hiddenConnectionsValue?.label}`,
          tooltip: `Hidden Connections: ${connectionsFilter.hiddenConnectionsValue?.label}`,
        });
      }

      if (
        connectionsFilter.birthDateFrom !== undefined &&
        connectionsFilter.birthDateFrom !== ""
      ) {
        let dateConnected = `Date Of Birth: ${moment(
          connectionsFilter.birthDateFrom
        ).format("MMM D, YYYY")}`;
        if (
          connectionsFilter.birthDateTo !== undefined &&
          connectionsFilter.birthDateTo !== ""
        ) {
          dateConnected += ` - ${moment(connectionsFilter.birthDateTo).format(
            "MMM D, YYYY"
          )}`;
        }
        newLabels.push({
          key: "birthDate",
          value: dateConnected,
          tooltip: dateConnected,
        });
      } else if (
        connectionsFilter.birthDateTo !== undefined &&
        connectionsFilter.birthDateTo !== ""
      ) {
        newLabels.push({
          key: "birthDate",
          value: `Date Connected: - ${moment(
            connectionsFilter.birthDateTo
          ).format("MMM D, YYYY")}`,
          tooltip: `Date Connected: - ${moment(
            connectionsFilter.birthDateTo
          ).format("MMM D, YYYY")}`,
        });
      }

      if (connectionsFilter.selectedLanguages?.length) {
        if (connectionsFilter.selectedLanguages?.length === 1) {
          newLabels.push({
            key: "selectedLanguages",
            value: `Language: ${connectionsFilter.selectedLanguages[0]}`,
          });
        } else {
          let tooltip = `Languages, ${connectionsFilter.languageCondition}: `;
          tooltip += connectionsFilter.selectedLanguages
            .slice(0, 3)
            .map((lang: any) => lang)
            .join(", ");
          if (connectionsFilter.selectedLanguages.length > 3) {
            tooltip += ", ...";
          }
          newLabels.push({
            key: "selectedLanguages",
            value: `Language: ${connectionsFilter.selectedLanguages.length} Selected`,
            tooltip: tooltip,
          });
        }
      }

      if (
        connectionsFilter?.contactInfo?.value &&
        connectionsFilter.contactInfo?.value !== "all"
      ) {
        newLabels.push({
          key: "contactInfo",
          value: `Contact Info: ${connectionsFilter.contactInfo?.label}`,
          tooltip: `Contact Info: ${connectionsFilter.contactInfo?.label}`,
        });
      }

      if (
        connectionsFilter?.industry?.value &&
        connectionsFilter.industry?.value !== ""
      ) {
        newLabels.push({
          key: "industry",
          value: `Industry: ${connectionsFilter.industry?.label}`,
          tooltip: `Industry: ${connectionsFilter.industry?.label}`,
        });
      }

      if (
        connectionsFilter?.searchByFollowers !== undefined &&
        connectionsFilter?.searchByFollowers !== ""
      ) {
        let searchByFollowers = `Number of Followers: ${connectionsFilter.searchByFollowers}`;
        if (connectionsFilter.searchByMaxFollowers !== undefined) {
          searchByFollowers += ` - ${connectionsFilter.searchByMaxFollowers}`;
        }
        newLabels.push({
          key: "searchByFollowers",
          value: searchByFollowers,
          tooltip: searchByFollowers,
        });
      } else if (
        connectionsFilter.searchByMaxFollowers !== undefined &&
        connectionsFilter?.searchByMaxFollowers !== ""
      ) {
        newLabels.push({
          key: "searchByFollowers",
          value: `Number of Followers: 0 - ${connectionsFilter.searchByMaxFollowers}`,
          tooltip: `Number of Followers: 0 - ${connectionsFilter.searchByMaxFollowers}`,
        });
      }

      if (connectionsFilter.selectedTags?.length) {
        if (connectionsFilter.selectedTags?.length === 1) {
          newLabels.push({
            key: "selectedTags",
            value: `Tags: ${connectionsFilter.selectedTags[0]?.title}`,
          });
        } else {
          let tooltip = `Tags, ${connectionsFilter.tagCondition}: `;
          tooltip += connectionsFilter.selectedTags
            .slice(0, 3)
            .map((tag: any) => tag.title)
            .join(", ");
          if (connectionsFilter.selectedTags.length > 3) {
            tooltip += ", ...";
          }
          newLabels.push({
            key: "selectedTags",
            value: `Tags: ${connectionsFilter.selectedTags.length} Selected`,
            tooltip: tooltip,
          });
        }
      }

      if (connectionsFilter.noTags) {
        newLabels.push({
          key: "selectedTags",
          value: `Without Tags`,
          tooltip: `Without Tags`,
        });
      }

      if (connectionsFilter.selectedExcludeTags?.length) {
        if (connectionsFilter.selectedExcludeTags?.length === 1) {
          newLabels.push({
            key: "selectedExcludeTags",
            value: `Exclude Tags: ${connectionsFilter.selectedExcludeTags[0]?.title}`,
          });
        } else {
          let tooltip = `Exclude Tags, ${connectionsFilter.tagCondition}: `;
          tooltip += connectionsFilter.selectedExcludeTags
            .slice(0, 3)
            .map((tag: any) => tag.title)
            .join(", ");
          if (connectionsFilter.selectedExcludeTags.length > 3) {
            tooltip += ", ...";
          }
          newLabels.push({
            key: "selectedExcludeTags",
            value: `Exclude Tags: ${connectionsFilter.selectedExcludeTags.length} Selected`,
            tooltip: tooltip,
          });
        }
      }

      if (
        connectionsFilter.note !== "all" &&
        connectionsFilter.note?.value !== "all"
      ) {
        newLabels.push({
          key: "note",
          value: `Note: ${connectionsFilter.note?.label}`,
          tooltip: `Note: ${connectionsFilter.note?.label}`,
        });
      }

      if (connectionsFilter.autotagsFilter !== undefined) {
        let tooltip = `Autotags: `;
        let value = `Autotags: `;
        if (connectionsFilter.autotagsFilter?.length > 2) {
          value += `${connectionsFilter.autotagsFilter?.length} selected`;
          tooltip += connectionsFilter.autotagsFilter
            .map((tag: any) =>
              tag._id === "LD Removed" || tag._id === "Li Removed"
                ? tag._id
                : tag?.title
            )
            .join(", ");
        } else {
          value += connectionsFilter.autotagsFilter
            .map((tag: any) =>
              tag._id === "LD Removed" || tag._id === "Li Removed"
                ? tag._id
                : tag?.title
            )
            .join(", ");
          tooltip += connectionsFilter.autotagsFilter
            .map((tag: any) =>
              tag._id === "LD Removed" || tag._id === "Li Removed"
                ? tag._id
                : tag?.title
            )
            .join(", ");
        }

        newLabels.push({
          key: "autotags",
          value: value,
          tooltip: tooltip,
        });
      }

      setLabels(newLabels);
    }
  }, [connectionsFilter]);

  const handleToggleOperator = (newValue: string, uid?: string) => {
    const updatedFilter = {
      ...connectionsFilter,
      searchValues: connectionsFilter.searchValues.map((val: any) =>
        val.uid === uid ? { ...val, isOutterValueSearchOr: newValue } : val
      ),
      sortClicked: false
    };
    setConnectionsFilterHandler(updatedFilter);
  };

  const handleRemoveLabel = (label: {
    key: string;
    value: string;
    uid?: string;
  }) => {
    let updatedFilter;
    if (label.key === "searchValue") {
      updatedFilter = {
        ...connectionsFilter,
        searchValues: connectionsFilter.searchValues.filter(
          (val: any) => val.uid !== label.uid
        ),
        sortClicked: false
      };
    } else if (label.key === "connectedAt") {
      updatedFilter = {
        ...connectionsFilter,
        connectedAtFrom: undefined,
        connectedAtTo: undefined,
        sortClicked: false
      };
    } else if (label.key === "selectedUsersMutual") {
      updatedFilter = {
        ...connectionsFilter,
        selectedUsersMutual: [],
        sortClicked: false
      };
    } else if (label.key === "lastMessaged") {
      updatedFilter = {
        ...connectionsFilter,
        lastMessagedFrom: undefined,
        lastMessagedTo: undefined,
        conversationOwners: undefined,
        conversationStatus: undefined,
        onlyLast: undefined,
        sortClicked: false
      };
    } else if (label.key === "hiddenConnections") {
      updatedFilter = {
        ...connectionsFilter,
        hiddenConnectionsValue: { label: "No", value: "no" },
        sortClicked: false
      };
    } else if (label.key === "birthDate") {
      updatedFilter = {
        ...connectionsFilter,
        birthDateFrom: undefined,
        birthDateTo: undefined,
        sortClicked: false
      };
    } else if (label.key === "selectedLanguages") {
      updatedFilter = {
        ...connectionsFilter,
        selectedLanguages: [],
        sortClicked: false
      };
    } else if (label.key === "contactInfo") {
      updatedFilter = {
        ...connectionsFilter,
        contactInfo: { label: "All", value: "all" },
        sortClicked: false
      };
    } else if (label.key === "industry") {
      updatedFilter = {
        ...connectionsFilter,
        industry: { label: "All Industries", value: null },
        sortClicked: false
      };
    } else if (label.key === "searchByFollowers") {
      updatedFilter = {
        ...connectionsFilter,
        searchByFollowers: undefined,
        searchByMaxFollowers: undefined,
        sortClicked: false
      };
    } else if (label.key === "selectedTags") {
      updatedFilter = {
        ...connectionsFilter,
        selectedTags: [],
        noTags: false,
        sortClicked: false
      };
    } else if (label.key === "selectedExcludeTags") {
      updatedFilter = {
        ...connectionsFilter,
        selectedExcludeTags: [],
        sortClicked: false
      };
    } else if (label.key === "note") {
      updatedFilter = {
        ...connectionsFilter,
        note: { label: "All", value: "all" },
        sortClicked: false
      };
    } else if (label.key === "autotags") {
      updatedFilter = {
        ...connectionsFilter,
        autotagsFilter: undefined,
        sortClicked: false
      };
    }
    setConnectionsFilterHandler(updatedFilter);
  };

  const saveSavedFiltersHandler = (e: FormEvent) => {
    e.preventDefault();
    saveFiltersHandler && saveFiltersHandler();
  };

  return labels?.length ? (
    <StyledForm>
      <Row gap="10px">
        <ActionText onClickHandler={(e) => saveSavedFiltersHandler(e)}>
          Save filters
        </ActionText>
        <ActionText
          danger
          onClickHandler={() => resetFiltersHandler && resetFiltersHandler()}
        >
          Clear all
        </ActionText>
      </Row>

      <Row gap="8px">
        {labels.map((label, index) => (
          <React.Fragment key={index}>
            {label?.operator && (
              <Label style={{ padding: "0px 4px" }}>
                <Option
                  isActive={label?.operator === "AND"}
                  onClick={() => handleToggleOperator("no", label?.uid)}
                >
                  And
                </Option>
                <Separator />
                <Option
                  isActive={label?.operator === "OR"}
                  onClick={() => handleToggleOperator("yes", label?.uid)}
                >
                  Or
                </Option>
              </Label>
            )}
            <TooltipWrapper tooltipText={label?.tooltip ?? ""}>
              <Label style={{ padding: "2px 4px" }}>
                {label?.value}
                <CloseButton
                  onClick={() => handleRemoveLabel(label)}
                  className="clear-svg"
                  width="12"
                  height="12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.96 11.825 6 7.785l4.04 4.04a.6.6 0 0 0 .846 0l.94-.939a.6.6 0 0 0 0-.847L7.785 6l4.039-4.04a.6.6 0 0 0 0-.846l-.939-.939a.6.6 0 0 0-.847 0L6 4.215 1.96.175a.6.6 0 0 0-.846 0l-.939.939a.6.6 0 0 0 0 .847L4.215 6 .175 10.04a.6.6 0 0 0 0 .847l.94.938a.6.6 0 0 0 .846 0z"
                    fill="#8C96AD"
                    fillRule="evenodd"
                  />
                </CloseButton>
              </Label>
            </TooltipWrapper>
          </React.Fragment>
        ))}
      </Row>
    </StyledForm>
  ) : null;
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 8px;
  width: 100%;
`;

const Label = styled.div`
  font-family: NunitoSans;
  font-size: 14px;
  background-color: ${styles.colors.primary100};
  border: 1px solid;
  border-color: ${styles.colors.primary600};
  color: ${styles.colors.primary600};
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const CloseButton = styled.svg`
  background: none;
  border: none;
  margin-left: 8px;
  cursor: pointer;
  font-size: 12px;
  cursor: pointer;
  path {
    fill: ${styles.colors.primary600};
  }
  &:hover path {
    fill: ${styles.colors.primary500};
  }
`;

const Option = styled.div<IToggleOperatorProps>`
  padding: 2px 4px;
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  color: ${styles.colors.primary600};
  cursor: pointer;
`;

const Separator = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${styles.colors.primary600};
`;

export default ConnectionFilterLabels;
