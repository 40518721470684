import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTasksHandlers } from '../../../../services/actions/tasks/useTasksHandlers'
import Checkbox from '../../../../components/Checkbox'
import Table from '../../../../components/Table'
import Text from '../../../../components/Text'
import moment from 'moment'
import styled from 'styled-components'
import { styles } from '../../../../styles/themes/style'
import Column from '../../../../components/Column'
import SvgFragment from '../../../../fragments/SvgFragment'
import Tag from '../../../../components/Tag'
import { calculateColumnWidth, capitalLetters } from '../../../../utils'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import Row from '../../../../components/Row'
import { useRemindersHandlers } from '../../../../services/actions/reminders/useRemindersHandlers'
import { useAppStore } from '../../../../stores/application/useAppStore'
import EmptyPage from '../../../../components/EmptyPage'
import { ITask } from '../../../../interfaces/models/task'
import { ITaskResponse } from '../../../../interfaces/response/task'
import useMediaQuery from '../../../../components/useMediaQuery'
import { SidebarTypes } from '../../../../interfaces/store/appStore'

interface ITasksTableProps {
    tasks: ITaskResponse[]
 }

interface IStyledText {
    $red?: boolean
    $associations?: boolean
}

interface ICellContent {
    $bold?: boolean
    $blueText?: boolean
    $greyText?: boolean
    $redText?: boolean
    $cursorPointer?: boolean
    $inlineBlock?: boolean
}

interface ICheckMark {
    $completed?: boolean
}

interface ITableColumn {
    header: string;
    key: string[];
    width?: string;
}

export const renderTags = (props: number) => {
    switch (props) {
        case 0:
            return '';
        case 1:
            return <Tag title="Low" $color={styles.colors.blue600} $borderColor={styles.colors.white} />;
        case 2:
            return <Tag title="Medium" $color={styles.colors.green500} $borderColor={styles.colors.white} />;
        case 3:
            return <Tag title="High" $color={styles.colors.orange600} $borderColor={styles.colors.white} />;
        case 4:
            return <Tag title="Urgent" $color={styles.colors.red600} $borderColor={styles.colors.white} />;
        default:
            return null;
    }
}

const TasksTable: FunctionComponent<ITasksTableProps> = ({ tasks }) => {
    const tableRef = useRef<HTMLDivElement>(null)

    const isSmall = useMediaQuery("(max-width: 768px)");

    const [currentTasks, setCurrentTasks ] = useState<ITaskResponse[]>(tasks)

    const { completeSingleTask } = useTasksHandlers()
    const {  openSidebar, setConfirmationModal } = useAppHandlers()

    const { store: { requestLoading }} = useAppStore()
    const [tasksLoading, setTasksLoading ] = useState<boolean>(true)

    useEffect(() => {
        setCurrentTasks(tasks)
    }, [tasks])

    useEffect(() => {       
        if('getTasks' in requestLoading) setTasksLoading(requestLoading?.['getTasks'])
    }, [requestLoading]);

    const [columns, setColumns] = useState([
        { header: 'Task name', key: ['titleColumn'], width: '40%', show: true, showSmall: true },
        { header: 'Due date', key: ['endColumn'], show: true, showSmall: true },
        { header: 'Priority', key: ['priorityColumn'], width: '10%', show: true, showSmall: true }
    ])

    useEffect(() => {
        const offsetWidth = tableRef.current?.offsetWidth

        const adjustedColumns = calculateColumnWidth(offsetWidth, columns, isSmall) 
        setColumns(adjustedColumns)

    }, [currentTasks, isSmall])

    const onCompeteTaskHandler = async (taskId: string) => {
        const result = await completeSingleTask(taskId)
        if(result){
            setCurrentTasks(currentTasks.filter((task) => task._id !== result._id))
        }
    }

    return (
        <Table
            ref={tableRef}
            pageLoading={!currentTasks?.length && tasksLoading ? 5 : undefined}
            noDataPage={
                <EmptyPageWrapper>
                    <SvgFragment type='templateEmptyBlue' />
                    <Text $heading4>No Tasks to Show</Text>
                    <Text $lighter $paragraph4>Create and complete tasks, or assign them to your workspace members, set reminders.</Text>
                </EmptyPageWrapper>
            }
            $bodySectionGap={'6px'}
            columns={currentTasks?.length ? [...columns] : []}
            data={currentTasks.map((task: any) => {
                return ({
                    object: { ...task, borderColor: styles.colors.black200},
                    onRowClick: () => openSidebar(SidebarTypes.TASK_EDIT_CREATE, { task }),
                    titleColumn:
                        <StyledColumn alignItems>
                            <CheckMark $completed={task.completed} onClick={(e: any) => {e.stopPropagation(); onCompeteTaskHandler(task._id)}}>
                                {/* SvgFragment hover wont change color */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.16321 11.2024L12.5299 6.06189C12.7121 5.88731 12.7121 5.60429 12.5299 5.42973L11.8699 4.79757C11.6877 4.62302 11.3922 4.62302 11.21 4.79757L6.83324 8.98985L4.78985 7.03257C4.60761 6.858 4.31212 6.858 4.12988 7.03257L3.46993 7.66473C3.28769 7.8393 3.28769 8.12233 3.46993 8.29689L6.50326 11.2024C6.68548 11.377 6.98096 11.377 7.16321 11.2024Z" fill="white" />
                                </svg>
                            </CheckMark>
                            <Text $bold $ellipsis='1'>{task.title}</Text>
                        </StyledColumn>,
                    endColumn: task.end === 0 ? (
                        <Text $lighter>No Due Date</Text>
                    ) : (
                        <StyledColumn alignItems>
                            <StyledText $red={moment() > moment.unix(task.end) && task.end}>
                                {task.end ? moment.unix(task.end).format("MMM D, YYYY") : 'No due date'}
                            </StyledText>
                            {(task.repeating?.unit && task.repeating?.unit !== 'Never') &&
                                <ImageContainer>
                                    <SvgFragment type='repeat' />
                                    <span>This task repeats {task?.repeating?.unit.toLowerCase()}.</span>
                                </ImageContainer>}
                        </StyledColumn>
                    ),
                    priorityColumn: <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={(e: any) => e.stopPropagation()}>{renderTags(task.priority)}</div>,
                    ...task
                })
            })}
            />
    )
}

const StyledColumn = styled(Row)`

    &:hover .reminder {
        visibility: visible;
    }
`
const StyledText = styled(Text) <IStyledText>`
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis; 

    ${({ $red }) => $red && `color: ${styles.colors.red600}`}
    ${({ $associations }) => $associations && `
        color: ${styles.colors.primary600};
        font-weight: 700
    `}
`
const CheckMark = styled.div<ICheckMark>`
    border-radius: 50%;
    background-color: ${({ $completed }) => $completed ? styles.colors.primary600 : styles.colors.black300};
    width: 16px;
    height: 16px;
    display: flex;
    margin-right: 10px;
    cursor: pointer;

    ${({ $completed }) => $completed ? 'pointer-events: none;' : ''}

    &:hover { background-color: ${styles.colors.primary600}; }
`
const ImageContainer = styled.div`
    cursor: pointer;
    display: flex;

    & span {
        display: none;
        position: absolute;
        background-color: #333951;
        border-radius: 5px;
        z-index: 1000000;
        transform: translate( -40%, -24px);
        padding: 0 5px;
        font-family: NunitoSans;
        font-size: 12px;
        font-weight: normal;
        line-height: 20px;
        color: white;
        white-space: nowrap;   
     }

     &:hover {
        & span {
            display: block;

        }
     }
`
const EmptyPageWrapper = styled.div`
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: -5px;
    text-align: center;

    & svg {
        width: 42px;
        height: 42px;
    }
`
export default TasksTable