import { ReactNode } from "react";

//Enums
export enum ActionType {
  SET_MODAL = "SET_MODAL",
  SET_TOAST = "SET_TOAST",
  SET_REQUEST_LOADING = "SET_REQUEST_LOADING",
  SET_SPINNER = "SET_SPINNER",
  SET_EXTENSION_INSTALLED = "SET_EXTENSION_INSTALLED",
  SET_EXTENSION_ID = "SET_EXTENSION_ID",
  SET_EXTENSION_VERSION = "SET_EXTENSION_VERSION",
  SET_ONBOARDING_STEP = "SET_ONBOARDING_STEP",
  SET_HEADER_STATE = "SET_HEADER_STATE",
  SET_SETTINGS_STATE = "SET_SETTINGS_STATE",
  RESET_STORE = "RESET_STORE",
  SET_DARK_MODE = "SET_DARK_MODE",
  SET_SIDEBAR = "SET_SIDEBAR",
  SET_LEADDELTA_TAB_IS_ACTIVE = "SET_LEADDELTA_TAB_IS_ACTIVE",
  SET_SYNC_COMPLETION_PERCENTAGE = "SET_SYNC_COMPLETION_PERCENTAGE",
}

export enum AppRoutes {
  HOME = "/",
  PROCESSING = "/processing",
  DISABLED = "/disabled",

  INVITE = "/invite",
  SIGNUP = "/signup",
  SIGNIN = "/signin",
  FORGOT_PASSWORD = "/forgotPassword",
  DELETE_USER_CONFIRMATION = "/delete-confirmation",

  ONBOARDING = "/onboarding",
  ONBOARDING_SURVEY = "/onboarding/survey",
  ONBOARDING_EXTENSION_INSTALL = "/onboarding/extensionInstall",
  ONBOARDING_LINKEDIN = "/onboarding/linkedIn",

  DASHBOARD = "/dashboard",
  CONNECTIONS = "/connections",
  TAGS = "/tags",
  TASKS = "/tasks",
  TEMPLATES = "/templates",
  NOTES = "/notes",
  INTEGRATIONS = "/integrations",

  SETTINGS = "/settings",
  SETTINGS_DATA_MANAGEMENT = "/settings/dataManagement",
  SETTINGS_WORKSPACE_ADMIN = "/settings/admin",
  SETTINGS_BILLING = "/settings/billing",
  SETTINGS_EXTENSION = "/settings/extension",
  SETTINGS_LINKEDIN = "/settings/linkedin",

  BILLING = "/billing",
  BILLING_PLAN = "/billing/plan",
  BILLING_PAYMENT = "/billing/payment",
  BILLING_PROCESSING = "/billing/processing",

  WORKSPACE_MANAGEMENT = "/workspace-management",
  CREATE_WORKSPACE = "/createWorkspace",

  INFO = "/info",
  REDIRECT = "/redirect",

  CSV_IMPORT = "/csv/import",
  CSV_MAPPING = "/csv/mapping",
}

export enum SidebarTypes {
  BULK_MESSAGE = "bulkMessage",

  //filtering
  CONNECTION_FILTER = "connectionFilter",
  TAG_FILTER = "tagFilter",
  CONNECTION_ACTIONS = "connectionActions",
  APPLY_TAG = "applyTags",
  REMOVE_TAG = "removeTags",
  NOTE_FILTER = "noteFilter",
  TASKS_FILTER = "tasksFilter",
  TEMPLATES_FILTER = "templatesFilter",

  //create
  WORKSPACE_CREATE = "workspaceCreate",

  TAG_EDIT_CREATE = "tagCreateEdit",
  TASK_EDIT_CREATE = "taskCreateEdit",
  NOTE_EDIT_CREATE = "noteCreateEdit",
  TEMPLATES_EDIT_CREATE = "templatesCreateEdit",
  CUSTOM_FIELD_EDIT_CREATE = "customFieldCreateEdit",

  //integrations
  PABBLY = "pabbly",
  HUBSPOT = "hubspot",

  SAVE_FILTER = "saveFilter",
}

export enum AppLiterals {
  WORKSPACE = "workspace",
  INDIVIDUAL = "individual",
}

//Interfaces
export interface IToast {
  type?: "success" | "danger" | "info" | "warning" | "error" | "reSendMessage" | "removalScheduled";
  duration?: number;
  message?: string;
  onClick?: {
    onClickHandler?: (e: any) => void;
    label?: string;
  };
}

export interface IModal {
  type?: string;
  headingText?: ReactNode;
  descriptionText?: ReactNode;
  descriptionTextBold?: ReactNode;
  props?: any;
  onConfirmHandler?: (e: any) => void;
  onCancelHandler?: (e: any) => void;
}

export interface IRequestLoading {
  [key: string]: boolean;
}

export interface ISidebar {
  type: string;
  zIndex: number;
  props?: any;
}

//States
export type TAppState = {
  modal?: IModal;
  toast?: IToast;
  requestLoading?: IRequestLoading;
  counterValue?: number;
  spinner?: boolean;
  extensionInstalled?: boolean;
  extensionId?: string;
  extensionVersion?: string;
  onboardingStep: number;
  headerState?: THeaderState;
  settingsState?: THeaderState;
  sidebar: ISidebar[];
  darkMode?: boolean;
  connectionsFilter?: boolean; //same story, remove these please
  leadDeltaTabIsActive?: boolean;
  syncCompletionPercentage?: number;
};

export type TMenuItemState = {
  title: string;
  visible: boolean;
  enabled: boolean;
  route?: string;
  routeInternal?: string;
  extension?: string;
  icon?: string;
  destructive?: boolean;
  showInstallExt?: boolean;
  onClickHandler?: (e: any) => void;
};

export type THeaderState = {
  [key: string]: TMenuItemState;
};

export const headerRoutes: THeaderState = {
  home: {
    title: "Home",
    visible: true,
    enabled: true,
    routeInternal: AppRoutes.HOME,
    icon: "home",
  },
  dashboard: {
    title: "Dashboard",
    visible: true,
    enabled: true,
    routeInternal: AppRoutes.DASHBOARD,
    icon: "folderText",
  },
  connections: {
    title: "Connections",
    visible: true,
    enabled: true,
    routeInternal: AppRoutes.CONNECTIONS,
    icon: "fileText",
  },
  inbox: {
    title: "Inbox",
    visible: true,
    enabled: false,
    extension: "inbox",
    icon: "envelope",
  },
  tags: {
    title: "Tags",
    visible: true,
    enabled: true,
    routeInternal: AppRoutes.TAGS,
    icon: "label",
  },
  tasks: {
    title: "Tasks",
    visible: true,
    enabled: true,
    routeInternal: AppRoutes.TASKS,
    icon: "pen",
  },
  templates: {
    title: "Templates",
    visible: true,
    enabled: true,
    routeInternal: AppRoutes.TEMPLATES,
    icon: "paperText",
  },
  notes: {
    title: "Notes",
    visible: true,
    enabled: true,
    routeInternal: AppRoutes.NOTES,
    icon: "note",
  },
  integrations: {
    title: "Integrations",
    visible: false,
    enabled: true,
    routeInternal: AppRoutes.INTEGRATIONS,
    icon: "puzzlePiece",
  },
};

export const settingsRoutes: THeaderState = {
  workspaceAdmin: {
    title: "Admin",
    visible: true,
    enabled: true,
    routeInternal: AppRoutes.SETTINGS_WORKSPACE_ADMIN,
    icon: "profiles",
  },
  backgroundUpdate: {
    title: "Background update",
    visible: true,
    enabled: true,
    routeInternal: AppRoutes.SETTINGS_EXTENSION,
    icon: "refresh",
  },
  dataManagement: {
    title: "Data management",
    visible: true,
    enabled: true,
    routeInternal: AppRoutes.SETTINGS_DATA_MANAGEMENT,
    icon: "data",
  },
  billing: {
    title: "Billing",
    visible: true,
    enabled: true,
    routeInternal: AppRoutes.SETTINGS_BILLING,
    icon: "card",
  },
  linkedin: {
    title: "LinkedIn sidebar",
    visible: true,
    enabled: true,
    routeInternal: AppRoutes.SETTINGS_LINKEDIN,
    icon: "sidebar",
  },
};

//Actions
interface IModalAction {
  type: ActionType.SET_MODAL;
  payload: TAppState["modal"];
}

interface IShowToastAction {
  type: ActionType.SET_TOAST;
  payload: TAppState["toast"];
}

interface IRequestLoadingAction {
  type: ActionType.SET_REQUEST_LOADING;
  payload: TAppState["requestLoading"];
}

interface ISetSpinner {
  type: ActionType.SET_SPINNER;
  payload: TAppState["spinner"];
}

interface ISetExtensionInstalled {
  type: ActionType.SET_EXTENSION_INSTALLED;
  payload: TAppState["extensionInstalled"];
}

interface ISetExtensionId {
  type: ActionType.SET_EXTENSION_ID;
  payload: TAppState["extensionId"];
}

interface ISetExtensionVersion {
  type: ActionType.SET_EXTENSION_VERSION;
  payload: TAppState["extensionVersion"];
}

interface ISetOnboardingStep {
  type: ActionType.SET_ONBOARDING_STEP;
  payload: TAppState["onboardingStep"];
}

interface ISetHeaderState {
  type: ActionType.SET_HEADER_STATE;
  payload: TAppState["headerState"];
}

interface ISetSettingsState {
  type: ActionType.SET_SETTINGS_STATE;
  payload: TAppState["settingsState"];
}

interface ISetDarkMode {
  type: ActionType.SET_DARK_MODE;
  payload: TAppState["darkMode"];
}

interface IResetStoreAction {
  type: ActionType.RESET_STORE;
}

interface ISetSidebar {
  type: ActionType.SET_SIDEBAR;
  payload: TAppState["sidebar"];
}

interface ISetLeadDeltaTabIsActive {
  type: ActionType.SET_LEADDELTA_TAB_IS_ACTIVE;
  payload: TAppState["leadDeltaTabIsActive"];
}

interface ISetSyncCompletionPercentage {
  type: ActionType.SET_SYNC_COMPLETION_PERCENTAGE;
  payload: TAppState["syncCompletionPercentage"];
}

export type TAppAction =
  | IModalAction
  | IShowToastAction
  | IRequestLoadingAction
  | ISetSpinner
  | ISetExtensionInstalled
  | IResetStoreAction
  | ISetExtensionId
  | ISetExtensionVersion
  | ISetOnboardingStep
  | ISetHeaderState
  | ISetSettingsState
  | ISetSidebar
  | ISetDarkMode
  | ISetLeadDeltaTabIsActive
  | ISetSyncCompletionPercentage
//export type TDispatchType = (args: TAppState) => TAppAction
