import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Input from '../../../components/Input';
import { googleLibrary, isValid } from '../../../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthHandlers } from '../../../services/actions/auth/useAuthHandlers';
import { useAuthRequest } from '../../../services/apis/auth/useAuthRequest';
import { IInvite } from '../../../interfaces/models/invite';
import { AppRoutes } from '../../../interfaces/store/appStore';
import { styles } from '../../../styles/themes/style';
import Logo from '../../../components/Logo';
import SvgFragment from '../../../fragments/SvgFragment';
import Text from '../../../components/Text';
import ActionText from '../../../components/ActionText';
import { useExternalHandlers } from '../../../services/actions/navigation/useExternalHandlers';
import Button from '../../../components/Button';


const SignInSection = React.forwardRef((props: any, ref: any) => {
    const [values, setValues] = React.useState<any>({});
    const [valid, setValid] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [invite, setInvite] = React.useState<any>();
    const [searchParams, setSearchParams] = useSearchParams();
    const { setAuthDataHandler } = useAuthHandlers()
    const { signInEmail, signInGoogle, invitePublic } = useAuthRequest();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const { openTermsAndConditions, openPrivacyPolicy } = useExternalHandlers()

    /* useEffect(() => {
        resetAuthStore()
    }, []) */

    useEffect(() => {
        document.title = "LeadDelta Login";
      }, []);

    const googleCallback = React.useCallback((...args: any[]) => {
        if (typeof googleLibrary.callback === 'function') googleLibrary.callback(...args);
    }, []);

    const initGoogle = React.useCallback(() => {
        // Google
        if ((window as any).google) {
            // new
            (window as any).google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: googleCallback,
                use_fedcm_for_prompt: true
            });

            // oauth2 init token
            googleLibrary.oauth2 = (window as any).google.accounts.oauth2.initTokenClient({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                scope: 'profile email'
            });
        }
    }, []);

    const googlePrompt = React.useCallback(() => {
        if ((window as any).google) {
            googleLibrary.callback = SigninWithGoogle;

            (window as any).google.accounts.id.prompt();
        }
    }, []);

    const cleanUp = React.useCallback(() => {
        window.history.pushState({}, '', window.location.pathname);
    }, []);

    const SigninWithEmail = React.useCallback(async (event?: React.FormEvent) => {
        if (event) event.preventDefault();
        setLoading(true);
        try {
            if (valid) {
                const response = await signInEmail({
                    ...values,

                    ...(invite && { inviteCode: invite?.invite?.code })
                });

                if (response?.user) {
                    // update auth data 
                    setAuthDataHandler({ accessToken: `Bearer ${response.token}`, refreshToken: response.refreshToken });

                    //showSuccessToast({ message: `You signed in successfully!` });

                    // clean up 
                    cleanUp();

                    // Auto open the extension 
                    // if all the checks are good 
                    //navigate(AppRoutes.PROCESSING)
                }
                else {
                    //showErrorToast({ message: response?.message });
                }
            }
        }
        catch (error) {
            console.log('Sign up with email', error);
        }

        setLoading(false);
    }, [values, invite, valid]);

    const SigninWithGoogle = React.useCallback(async (...args: any[]) => {
        setLoading(true);
        console.log('On sign up with Google', ...args);

        const googleResponse = args[0];

        const valid_ = googleResponse.access_token || googleResponse.credential;

        const inviteValueObj = localStorage.getItem('inviteValue')

        try {
            if (valid_) {
                const body: any = {};

                if (inviteValueObj) {
                    const inviteValue = JSON.parse(inviteValueObj) as IInvite;
                    body['inviteCode'] = inviteValue?.['invite']?.['code'];
                    localStorage.removeItem('inviteValue');
                }

                if (googleResponse.access_token) body['access_token'] = googleResponse.access_token;

                if (googleResponse.credential) body['credential'] = googleResponse.credential;

                const response = await signInGoogle(body);

                if (response?.user) {
                    // update auth data 
                    setAuthDataHandler({ accessToken: `Bearer ${response.token}`, refreshToken: response.refreshToken });

                    //showSuccessToast({ message: `You signed in successfully!` });

                    // clean up 
                    cleanUp();

                    // Auto open the extension 
                    // if all the checks are good 
                    //navigate(AppRoutes.PROCESSING)
                }
                else {
                    //showErrorToast({ message: response?.message });
                }
            }
        }
        catch (error) {
            console.log('Sign up with Google', error);
        }

        setLoading(false);
    }, [invite, valid]);

    const init = React.useCallback(async () => {
        // Init Google 
        initGoogle();

        const inviteCode = searchParams.get('invite');

        if (inviteCode) {
            const inviteValue = await invitePublic(inviteCode);

            if (inviteValue?.invite) {
                // Update invite 
                setInvite(inviteValue);
                localStorage.setItem('inviteValue', JSON.stringify(inviteValue));

                // Update values 
                updateValue('email', inviteValue.invite?.to?.email);
            }
        }

        // Google sign up propmpt 
        googlePrompt();
    }, []);

    React.useEffect(() => {
        init();
    }, []);

    const getGoogleAccessToken = () => {
        return new Promise((resolve, reject) => {
            if (googleLibrary.oauth2) {
                googleLibrary.oauth2.callback = (response: any) => resolve(response);

                googleLibrary.oauth2.error_callback = (response: any) => resolve(response);

                googleLibrary.oauth2.requestAccessToken();
            }
        });
    }

    const onGoogleSignInClick = React.useCallback(async () => {
        const response = await getGoogleAccessToken();

        // Use SigninWithGoogle method with response as an argument
        SigninWithGoogle(response);
    }, []);

    const updateValid = React.useCallback((valuesNew: any) => {
        const validNew = !!(
            isValid.email(valuesNew.email) &&
            valuesNew.password?.length > 7
        );

        setValid(validNew);
    }, []);

    const updateValue = React.useCallback((property: string, valueNew: any) => {
        setValues((previous: any) => {
            const valuesNew = {
                ...previous,
                [property]: valueNew
            };

            // Valid 
            updateValid(valuesNew);

            return valuesNew;
        });
    }, []);

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && valid) {
            event.preventDefault();
            SigninWithEmail();
        }
    };

    return (
        <Container>
            {step === 1 ? (
            <InnerContainer>
                <Column
                    style={{
                        gap: 16
                    }}
                >
                    <Logo />
                    <Title>Log in to LeadDelta</Title>
                     <Button
                        onClickHandler={onGoogleSignInClick}
                        disabled={loading}
                        $type="blue"
                    >
                        <SvgFragment type='google' />
                        Log in with Google
                    </Button>
                    <Button
                        $type='light'
                        tooltipMessage=''
                        onClickHandler={(e: any) => setStep(2)}
                    >
                        Log in with Email
                    </Button>

                    <Description>Don’t have an account? <Link onClick={() => navigate(AppRoutes.SIGNUP)}>Sign up</Link> to LeadDelta.</Description>
                </Column>
            </InnerContainer>
            ) : (
            <InnerContainer>
                <form onKeyDown={handleKeyDown}>
                    <Column
                        style={{
                            gap: 16
                        }}
                    >
                        <Logo />
                        <Title>Log in with email address</Title>
                        <Label>
                            Email address
                            <Input
                                placeholder='Enter your email address'
                                type='email'
                                value={values.email || ''}
                                onChangeHandler={valueNew => updateValue('email', valueNew)}
                                disabled={!!invite}
                            />
                        </Label>
                        <Label>
                            <Row
                                style={{
                                    justifyContent: 'space-between'
                                }}
                            >
                                <span>Password</span>

                                <Link
                                    onClick={() => navigate(AppRoutes.FORGOT_PASSWORD)}

                                    style={{
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    Forgot password?
                                </Link>
                            </Row>

                            <Input
                                placeholder='At least 8 characters long'
                                type={showPassword ? 'text' : 'password'}
                                value={values.password || ''}
                                onChangeHandler={valueNew => updateValue('password', valueNew)}
                                style={{
                                    paddingRight: 60
                                }}
                            />

                            <Show
                                onClick={() => setShowPassword(previous => !previous)}
                            >
                                {showPassword ? 'Hide' : 'Show'}
                            </Show>
                        </Label>
                        <Button
                                type='submit'
                                $type='light'
                                tooltipMessage=''
                                disabled={!valid}
                                onlySpinner={loading}
                                onClickHandler={(event) => SigninWithEmail(event)}
                            >
                                Continue with Email
                        </Button>
                        <Link color={styles.colors.black300} onClick={() => setStep(1)}>Back to login</Link>
                    </Column>
                </form>
            </InnerContainer>
            )}
            <FooterColumn>
                <Text>{'LeadDelta Inc. All rights Reserved.'}</Text>
                <Row><ActionText onClickHandler={()=>openTermsAndConditions()}>Terms of Service</ActionText><Text>and</Text><ActionText onClickHandler={()=>openPrivacyPolicy()}>Privacy Policy.</ActionText></Row>
            </FooterColumn>
      </Container>
    );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 116px 470px 0px 470px;

  @media (max-width: 1400px) {
    padding: 180px 10vw 0px 10vw;
  }

  @media (max-width: 1024px) {
    padding: 180px 10vw 0px 10vw;
  }

  @media (max-width: 768px) {
    padding: 180px 5vw 0px 5vw;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 400px;
  height: 100%;
  background-color: white;
`;

const Label = styled.label`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 6px; 
    font-family: NunitoSans;
    letter-spacing: 0.5px;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    font-weight: bold;
    color: ${(props: any) => props.theme.nonaryColor}; 
    cursor: default;
`;

const Show = styled.span`
    position: absolute;
    bottom: 8px;
    right: 12px;
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${(props: any) => props.theme.primary600};
    cursor: pointer;
    user-select: none; 
`

const Link = styled.span<{ color?: string }>`
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    ${({ color }) => color ? `color: ${color};` : `color: ${styles.colors.primary600};`}
    transition: opacity 0.3s ease 0s; 

    &:hover {
        opacity: 0.54;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
        gap: 4px;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FooterColumn = styled(Column)`
    padding: 28px;
    gap: 4px;
`

const Title = styled.h1`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px; 
    color: ${(props: any) => props.theme.primaryColor}; 
    margin-bottom: 12px;
`;

const Description = styled.p`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(props: any) => props.theme.nonaryColor}; 
    margin: 0px; 
`;

export default SignInSection; 
