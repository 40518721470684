import { FunctionComponent, useCallback, useState } from 'react'
import styled from "styled-components"
import Button from '../../../components/Button';
import { useAuthHandlers } from '../../../services/actions/auth/useAuthHandlers';
import { useExternalHandlers } from '../../../services/actions/navigation/useExternalHandlers';
import ActionText from '../../../components/ActionText';
import { styles } from '../../../styles/themes/style';
import { useOutletContext } from 'react-router-dom';

interface IExtensionInstallProps { }

const ExtensionInstall: FunctionComponent<IExtensionInstallProps> = () => {
    const { saveOnboardingData } = useAuthHandlers()
    const { visitChromeStore } = useExternalHandlers()
    const [step, setStep] = useState(1);
    const { goToNextStep } = useOutletContext<{ goToNextStep: () => void }>();


    const skipExtensionInstalationClick = useCallback(async () => {
            await saveOnboardingData({
                startedExtensionInstallation: false,
            })
	},[]); 

    const visitChromeStoreClick = useCallback(async () => {
    await Promise.all([
        saveOnboardingData({
            startedExtensionInstallation: true,
        }),
        (async () => visitChromeStore())(),
    ]);
	},[]); 
    

    return <Container $step={step}>
        {step === 1 ? (
            <StyledForm $step={step}>
                <Button $type='blue' $SVGtype={'openNewTab'} onClickHandler={()=>visitChromeStoreClick()}>{'Download LeadDelta App'}
                    <Text>Recommended</Text>
                </Button>
                <CenterContainer $step={step}><ActionText faded onClickHandler={()=> {setStep(2); goToNextStep();}}>{'I’ll do this later'}</ActionText></CenterContainer>
            </StyledForm>
        ) : (
            <StyledForm $step={step}>
                <Button $type='blue' $SVGtype={'openNewTab'} onClickHandler={()=>visitChromeStoreClick()}>{'Download LeadDelta App'}
                    <Text>Recommended</Text>
                </Button>
                <CenterContainer $step={step}><ActionText faded onClickHandler={()=> {skipExtensionInstalationClick()}}>{'Skip, re-add new connections manually'}</ActionText></CenterContainer>
            </StyledForm>
        )}
    </Container>
}

const Container = styled.div<{$step: number}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    max-width: ${({ $step }) => ($step === 1 ? "547px" : "781px")};
    padding: 5px 40px 5px 40px;
    @media (max-width: 768px) {
        padding: 5px;
    }
    overflow-y: auto;
`
const StyledForm = styled.form<{$step: number}>`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: ${({ $step }) => ($step === 2 ? "0px 120px 0px 120px;" : "none;")};
`

const CenterContainer = styled.div<{$step: number}>`
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: ${({ $step }) => ($step === 1 ? "547px" : "781px")};
    text-align: center;
    margin-top: 8px;
`

const Text = styled.span`
    position: absolute;
    right: 8px;
    font-size: 12px;
    background-color: ${styles.colors.primary100};
    border-radius: 4px;
    padding: 0px 6px 0px 6px;
    color: ${styles.colors.primary600};
`;

export default ExtensionInstall