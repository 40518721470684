import { FunctionComponent, useCallback, useEffect } from 'react'
import TablePageNavigation from '../../../../components/TablePageNavigation'
import Row from '../../../../components/Row'
import FilterButton from '../../../../fragments/FilterButton'
import { SidebarTypes } from '../../../../interfaces/store/appStore'
import Button from '../../../../components/Button'
import { useTasksStore } from '../../../../stores/tasks/useTasksStore'
import { useTasksHandlers } from '../../../../services/actions/tasks/useTasksHandlers'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import TasksBulkActionsDropdown from '../../../../fragments/dropdowns/TasksBulkActionsDropdown'
import styled from 'styled-components'

interface ITaskControlBarProps {
    selectedTasks: any[],
}

const TaskControlBar: FunctionComponent<ITaskControlBarProps> = ({selectedTasks}) => {
    const { store: { tasksParameters, tasksFilters } } = useTasksStore()
    const { openSidebar } = useAppHandlers()
    const { setTasksParametersHandler, numberOfTasksFiltersHandler, bulkUpdateTasksHandler, setClearTasksFilterHandler, getAllTasksHandler } = useTasksHandlers()

    useEffect(() => {
        const getTasks = async () => {
            await getAllTasksHandler();
        };
        getTasks();
    }, [tasksFilters, tasksParameters?.page, tasksParameters?.pageSize, tasksParameters?.sort])

    const resetFilterHandler = useCallback(() => {
        setClearTasksFilterHandler();
    }, [tasksFilters, setClearTasksFilterHandler]);

    return <Row gap='20px' alignItems spaceBetween flexWrap>
        <Row gap="12px">
            <Button $type='blue' $bigButton onClickHandler={() => openSidebar(SidebarTypes.TASK_EDIT_CREATE)}>{'Add new task'}</Button>
            <HideMobile>
                <Button $type='white' disabled={selectedTasks.length === 0} $bigButton $SVGtype='checkmark' onClickHandler={() => bulkUpdateTasksHandler({ isBulkComplete: true, ids: selectedTasks })}>{'Mark Complete'}</Button>
            </HideMobile>
            <TasksBulkActionsDropdown disabled={selectedTasks.length === 0} selectedTasks={selectedTasks} />
        </Row>
        <Row gap='12px'>
            <TablePageNavigation
                page={tasksParameters.page ?? 1}
                pageSize={tasksParameters.pageSize ?? 25}
                totalCount={tasksParameters?.total ?? 0}
                onPageChanged={(value: number) => setTasksParametersHandler({...tasksParameters, page: value})}
                onPageSizeChanged={(value: number) => setTasksParametersHandler({...tasksParameters, pageSize: value, page: '1'})}
            />
            <FilterButton 
                numberOfFilters={numberOfTasksFiltersHandler()} 
                sidebar={SidebarTypes.TASKS_FILTER}
                resetFilter={() => resetFilterHandler()}/>
        </Row>
    </Row>
}
const HideMobile = styled.div`
    @media (max-width: 575px) {
        display: none;
    }
`
export default TaskControlBar