import { FunctionComponent, useState } from 'react'
import CustomModal from '../../CustomModal'
import ActionText from '../../../ActionText'
import { useExternalHandlers } from '../../../../services/actions/navigation/useExternalHandlers'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers';
import Row from '../../../Row'
import Text from '../../../Text'
import styled from 'styled-components'

interface ISyncConnectionsModalProps {
    onClickHandler: () => void
}

const SyncConnectionsModal: FunctionComponent<ISyncConnectionsModalProps> = ({ onClickHandler }) => {

    const { openPermissionHandler } = useExternalHandlers()
    const { hideModal } = useAppHandlers()

    const description = `To make the most out of LeadDelta, we need your consent to sync your LinkedIn First Degree Connections. Learn more about permissions` 
    const dot = `.`
    return (
        <CustomModal
            logo
            closeButton
            descriptionText={<StyledText>{description}<StyledActionText onClickHandler={() => openPermissionHandler()}>here</StyledActionText>{dot}</StyledText>}
            descriptionTextBold={<StyledText>{`LeadDelta is your CRM for managing professional connections efficiently. With features like Tags, Notes, Filters, and the ability to co-share with your circles, you're set to transform your networking experience.`}</StyledText>}
            confirmButton={{
                text: 'Initiate Sync Now',
                bigButton: true,
                onClickHandler: () => (onClickHandler(), hideModal())
            }}>
                <Row gap="10px">
                    <Text>{'By clicking the “Initiate Sync Now” button, you grant LeadDelta permission to synchronize your LinkedIn connections to LeadDelta.'}</Text>
                </Row>
            </CustomModal>
    )
}

const StyledText =  styled(Text)`
    display: inline-block;
    text-align: left;
    font-weight: normal;
`
const StyledActionText = styled(ActionText)`
    display: inline-block;
    margin-left: 3px;
`

export default SyncConnectionsModal