import { FunctionComponent, useCallback, useEffect } from 'react'
import TablePageNavigation from '../../../../components/TablePageNavigation'
import Row from '../../../../components/Row'
import FilterButton from '../../../../fragments/FilterButton'
import { SidebarTypes } from '../../../../interfaces/store/appStore'
import DropdownMenu from '../../../../components/dropdown/DropdownMenu'
import DropdownItem from '../../../../components/dropdown/DropdownItem'
import { useNotesHandlers } from '../../../../services/actions/notes/useNotesHandlers'
import { useAuthStore } from "../../../../stores/auth/useAuthStore"
import { styles } from '../../../../styles/themes/style'
import styled from 'styled-components'
import Button from '../../../../components/Button'

interface INotesControlBarProps {
    notesFilters: any
    selectedNotes: any[],
    notesParameters: any
    isAllSelectedProps?: {
        isAllSelected: boolean
        setIsAllSelected: (e: any) => void
    }
}

const NotesControlBar: FunctionComponent<INotesControlBarProps> = ({ notesFilters, notesParameters, isAllSelectedProps, selectedNotes }) => {
    const { updateNoteAccessHandler, setNotesParametersHandler, numberOfNotesFiltersHandler, setClearNotesFilterHandler, deleteNotesHandler } = useNotesHandlers()
    const { store: { workspace} } = useAuthStore()
    const { setIsAllSelected  } = isAllSelectedProps || {}

    const updateAccessHandler = (isPrivate: boolean) => {
        updateNoteAccessHandler({
            ids: selectedNotes,
            private: isPrivate
        })
    }

    const onClickHandler = (props: boolean) => {
        updateAccessHandler(props)
        setIsAllSelected && setIsAllSelected(false)
    }

    const resetFilterHandler = useCallback(() => {
        setClearNotesFilterHandler();
    }, [notesFilters, setClearNotesFilterHandler]);

    return (
        <Row gap='20px' alignItems spaceBetween flexWrap>
            <Row gap="12px">
                { workspace.isBusiness &&
                    <StyledDropdownMenu disabled={!selectedNotes.length} title={'Update Access'}>
                        <DropdownItem onClickHandler={() => onClickHandler(true)}>Private</DropdownItem>
                        <DropdownItem onClickHandler={() => onClickHandler(false)}>Public</DropdownItem>
                    </StyledDropdownMenu>
                }
                <Divider />
                <Button $type='white' $SVGtype='trashCan' $bigButton $color={styles.colors.red600}
                            disabled={(selectedNotes.length === 0)}
                            onClickHandler={() => deleteNotesHandler(selectedNotes)}
                        >{'Delete Notes'}
                </Button>
            </Row>
            <Row gap='12px'>
                <TablePageNavigation
                    page={notesParameters?.page ?? 1}
                    pageSize={notesParameters?.pageSize ?? 25}
                    totalCount={notesParameters?.total ?? 0}
                    onPageChanged={(value: number) => setNotesParametersHandler({...notesParameters, page: value})}
                    onPageSizeChanged={(value: number) => setNotesParametersHandler({...notesParameters, pageSize: value, page: '1'})}
                />
                <FilterButton 
                    numberOfFilters={numberOfNotesFiltersHandler()} 
                    sidebar={SidebarTypes.NOTE_FILTER}
                    resetFilter={() => resetFilterHandler()}/>
            </Row>
        </Row>
    )
}

const StyledDropdownMenu = styled(DropdownMenu)`
    height: 34px;
    background-color: ${styles.colors.white};

    & .dropdown-toggle {
        padding: 0 7px;
    }
`

const Divider = styled.div`
    height: 18px;
    width: 1px;
    margin-top: 8px;
    background: ${({ theme: { quinaryColor } }) => `${quinaryColor}`};
`

export default NotesControlBar