import { FunctionComponent } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { styles } from '../../../../styles/themes/style'

import ProfilePhoto from '../../../../components/ProfilePhoto'
import EmptyPage from '../../../../components/EmptyPage'
import LoaderCell from '../../../../components/LoaderCell'
import SvgFragment from '../../../../fragments/SvgFragment'
import Row from '../../../../components/Row'
import Column from '../../../../components/Column'
import Text from '../../../../components/Text'
import ActionText from '../../../../components/ActionText'

import { IConnection } from '../../../../interfaces/models/connection'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import { SidebarTypes } from '../../../../interfaces/store/appStore'
import { useAuthStore } from '../../../../stores/auth/useAuthStore'

interface IHomeBirthdateTableProps {
    birthdateConnections: any;
    birthdateConnectionsLoading: any
}

const HomeBirthdateTable: FunctionComponent<IHomeBirthdateTableProps> = ({ birthdateConnections, birthdateConnectionsLoading }) => {
    const { openExtension, openSidebar } = useAppHandlers()
    const { store: { workspace } } = useAuthStore()

    const formatDate = (dateObj: { year?: number; month?: number; day?: number }) => {
        const { year, month, day } = dateObj

        if(month !== undefined && day !== undefined) {
            const date = moment({ year: year ?? 2000, month: month - 1, day })
            return date.isValid()
                ? year !== undefined
                    ? date.format("MMMM D, YYYY")
                    : date.format("MMMM D,")
                : null
        }

        return null
    }

    const messageClickHandler = (e: React.MouseEvent<HTMLElement>, connection: IConnection) => {
        e.stopPropagation();
        openExtension({ query: `redirectTo=inbox&messagedConnection=${connection._id}` })
    }

    return (
        <Container gap='5px'>
            {birthdateConnectionsLoading ? (
                Array(4).fill(undefined).map((_, rowIndex: number) => (
                    <PreloaderRow key={rowIndex}>
                    {Array(1)
                        .fill(undefined)
                        .map((_, colIndex: number) => (
                        <LoaderCell 
                            key={colIndex} 
                            $width="100%" 
                            $height="64px" 
                        />
                        ))}
                    </PreloaderRow>
                ))
            ) : birthdateConnections.length ? (
                birthdateConnections?.map((connection: any) => (
                    <StyledRow gap='16px'>
                        <AvatarWrapper>
                            <ProfilePhoto source={connection.profilePicture} />
                            <SvgWrapper><SvgFragment type='cake' /></SvgWrapper>
                        </AvatarWrapper>
                        <NameWrapper>
                            <ActionText variable onClickHandler={() => openSidebar(SidebarTypes.CONNECTION_ACTIONS, { connection: connection })}>{connection?.firstName + ' ' + connection?.lastName}</ActionText>
                            <Text>{formatDate(connection.birthDate)}</Text>
                        </NameWrapper>
                        <ActionTextWrapper>
                            <ActionText $disabled={workspace?.isStarter} tooltipMessage={workspace?.isStarter ? 'Upgrade to Pro or Business to unlock this feature' : ''} onClickHandler={(e: React.MouseEvent<HTMLElement>) => messageClickHandler(e, connection)} >Say Happy Birthday</ActionText>
                        </ActionTextWrapper>
                    </StyledRow>
                ))
            ) : (
                <EmptyPageWrapper>
                    <SvgFragment type='templateEmptyBlue' />
                    <Text $heading4>No Birthdays Today</Text>
                </EmptyPageWrapper>
            )}
        </Container>
    )
}
const Container = styled(Column)`
    height: 100%;
    padding-bottom: 20px;
`
const StyledRow = styled(Row)`
    background-color: ${styles.colors.black100};
    border-radius: 4px;
    padding: 12px;
`
const AvatarWrapper = styled.div`
    position: relative;
`
const SvgWrapper = styled.div`
    position: absolute;
    top: 16px;
    left: 18px;

    & svg {
        width: 20px;
        height: 20px;
    }
`
const NameWrapper = styled(Column)`
    position: relative;
`
const ActionTextWrapper = styled.div`
    position: absolute;
    right: 10px;
`
const EmptyPageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: -45px;

    & svg {
        width: 42px;
        height: 42px;
    }
`
const PreloaderRow = styled(Row)`
    background: ${({ theme: { secondaryColor } }) => secondaryColor};
    align-items: center;
`
export default HomeBirthdateTable