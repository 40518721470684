import { FunctionComponent} from 'react'
import styled, { keyframes } from 'styled-components'
import { styles } from '../styles/themes/style';
import SvgFragment from '../fragments/SvgFragment';

interface ILoadingSpinnerProps { 
    height?: string,
    width?: string,
    svg?: boolean
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner: FunctionComponent<ILoadingSpinnerProps> = ({svg, ...props}) => {
  return (
    <Container>
      {svg ?
      <SpinnerSvg type={"spinner"} /> :
      <Spinner {...props} />}
    </Container>
  );
}

const Container = styled.div`
`
const Spinner = styled.div<ILoadingSpinnerProps>`
    height: ${({height}) => height ?? '14px'}};
    width: ${({width}) => width ?? '14px'}};
    border: 2px solid ${({color}) => color ?? styles.colors.black200}};
    border-top: 2px solid ${styles.colors.black300};
    border-radius: 50%;
    animation: ${rotate} 1.5s linear infinite;
`
const SpinnerSvg = styled(SvgFragment)`
    animation: ${rotate} 1.5s linear infinite;
`

export default LoadingSpinner
