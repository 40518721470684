import { useTasksStore } from "../../../stores/tasks/useTasksStore";
import { useRemindersRequest } from "../../apis/reminders/useRemindersRequest";
import { useTasksActions } from "../../../stores/tasks/useTasksActions";
import { useTasksHandlers } from "../tasks/useTasksHandlers";

export const useRemindersHandlers = () => {

    const { reminderBulk, removeReminder, bulkRemoveReminders } = useRemindersRequest()
    const { setTasks } = useTasksActions()
    const { setTasksFiltersHandler, getAllTasksHandler } = useTasksHandlers()
    const { store: { tasks, tasksFilters } } = useTasksStore()


    const bulkAddRemindersHandler = async (props: any) => {
        const { reminder, selectedTasksIds } = props

        const selectedTasks = tasks.filter((item: any) => selectedTasksIds.includes(item._id))

        const body = {
            reminderToDelete: selectedTasks.map((item: any) => item?.reminders ? item?.reminders[0]?._id : null),
            objects: selectedTasks.map((selectedTask: any) => {
                return (
                    {
                        associations: {
                          tasks: [selectedTask],
                        },
                        active: true,
                        end: reminder
                    }
                )
            })
        }

        const result = await reminderBulk(body)

        const newTasks = tasks.map((task: any) => {
            if (selectedTasksIds.includes(task._id)) {
                return {
                    ...task,
                    reminders: [{
                        _id: result[0]._id,
                        active: result[0].active,
                        end: result[0].end
                    }]
                }
            }
            return task
        })
        setTasksFiltersHandler({...tasksFilters, selectedTasksIds: []})
        setTasks(newTasks)
    }

    const removeReminderHandler = async (reminderId: string) => {
        setTasksFiltersHandler({...tasksFilters, selectedTasksIds: []})
        
        const result = await removeReminder(reminderId)
        if(result) {
            const selectedTask = tasks.filter((item: any) => item?.reminders.length && (item?.reminders[0]._id === reminderId))
            const newTasks = tasks.map((task: any) => {
                if (selectedTask?.[0]._id === task._id) {
                    return {
                        ...task,
                        reminders: []
                    }
                }
                return task
            })
            setTasks(newTasks)

        }
    }

    const bulkRemoveRemindersHandler = async (selectedTasksIds: string[]) => {
        const selectedTasks = tasks.filter((item: any) => selectedTasksIds.includes(item._id))

        setTasksFiltersHandler({...tasksFilters, selectedTasksIds: []})

        const body = {
            ids: selectedTasks.flatMap((task: any) => task.reminders?.map((reminder: any) => reminder._id))
        }

        await bulkRemoveReminders(body)
        getAllTasksHandler()
    }

    return {
        bulkAddRemindersHandler,
        removeReminderHandler,
        bulkRemoveRemindersHandler
    };
}
