import { FunctionComponent } from "react";
import styled from "styled-components";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import SvgFragment from "../SvgFragment";
import DropdownItem from "../../components/dropdown/DropdownItem";
import Row from "../../components/Row";
import { styles } from "../../styles/themes/style";
import useMediaQuery from "../../components/useMediaQuery";
import { SidebarTypes } from "../../interfaces/store/appStore";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import { useAuthStore } from "../../stores/auth/useAuthStore";

interface ITagsBulkActionsDropdownProps {
  className?: string;
  selectedTags?: any;
  deleteTagsHandler?: (e: any) => void;
  updateAccessHandler?: (isPrivate: boolean) => void;
}

const TagsBulkActionsDropdown: FunctionComponent<
  ITagsBulkActionsDropdownProps
> = ({ className, selectedTags, deleteTagsHandler, updateAccessHandler }) => {
    const { openSidebar } = useAppHandlers()
    const { store: { workspace } } = useAuthStore();
  const isScreenMobile = useMediaQuery("(max-width: 575px)");

  const bulkTagActions = [
    {
      icon: "pen",
      title: `Add New Tag`,
      slug: "addTag",
      enabled: true,
      visible: isScreenMobile,
      destructive: false,
      onClickHandler: () => openSidebar(SidebarTypes.TAG_EDIT_CREATE),
    },
    {
      title: `Update Access`,
      slug: "updateAccess",
      enabled: true,
      visible: workspace.isBusiness,
      destructive: true,
      divider: true,
      onClickHandler: (isPrivate?: boolean) =>
        updateAccessHandler &&
        updateAccessHandler(isPrivate !== undefined ? isPrivate : true),
    },
    {
      icon: "trashCan",
      title: `Delete Tag`,
      slug: "deleteTag",
      enabled: selectedTags.length > 0,
      visible: true,
      destructive: true,
      onClickHandler: () =>
        deleteTagsHandler &&
        deleteTagsHandler(selectedTags),
    },
  ];

  return (
    <DropdownMenu
      tooltipMessage={"More actions"}

      className={className}
      $visibleOverflow={true}
      title={
        <SvgFragmentWrapper>
          <span className="more-text">More</span>
          <SvgFragment type="arrowDown" />
        </SvgFragmentWrapper>
      }
      $hideToggle
    >
      {bulkTagActions.map((connectionAction, index) =>
        connectionAction?.slug === 'updateAccess' && connectionAction?.visible ? (
          <>
            <StyledDropdownItem>
              <StyledDropdownMenu
                key={index}
                disabled={selectedTags.length === 0 || !workspace.isBusiness || !connectionAction.enabled}
                title={"Update Access"}
              >
                <DropdownItem
                  onClickHandler={() => connectionAction.onClickHandler(true)}
                >
                  Private
                </DropdownItem>
                <DropdownItem
                  onClickHandler={() => connectionAction.onClickHandler(false)}
                >
                  Public
                </DropdownItem>
              </StyledDropdownMenu>
            </StyledDropdownItem>
            <Divider />
          </>
        ) : connectionAction?.visible ? (
          <DropdownItem
            $disabled={!connectionAction.enabled}
            key={index}
            $danger={connectionAction.destructive}
            onClickHandler={(e) =>
              connectionAction.onClickHandler &&
              connectionAction.onClickHandler()
            }
          >
            <Row alignItems gap="5px">
              <SvgFragment type={connectionAction.icon} />
              {connectionAction.title}
            </Row>
          </DropdownItem>
        ) : <></>
      )}
    </DropdownMenu>
  );
};

const StyledDropdownItem = styled(Row)`
  padding: 6px 20px;
  .actions-dropdown + div{
    min-width: 128px;
  }
`;
const SvgFragmentWrapper = styled.div`
  width: 75px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  .more-text {
      display: inline;
      @media (max-width: 372px) {
           display: none;
      }
  }
`;
const StyledDropdownMenu = styled(DropdownMenu)`
  height: 34px;
  background-color: ${styles.colors.white};

   ${({disabled}) => disabled && `
            & .dropdownWrapperReference {
                color: ${styles.colors.disabledState};
                svg { cursor: default; }
                &:hover { color: ${styles.colors.disabledState}; }
            }
     `
   }

  & .dropdown-toggle {
    padding: 7px;
  }
`;
const Divider = styled.div`
  height: 2px;
  margin: 8px 16px 2px 16px;
  background-color: rgb(218, 224, 232);
`;

export default TagsBulkActionsDropdown;
