import { FunctionComponent, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import styled from "styled-components"
import CarouselNavigation from '../../components/CarouselNavigation'
import { useAppStore } from '../../stores/application/useAppStore'
import { AppRoutes } from '../../interfaces/store/appStore'
import Text from '../../components/Text'
import SvgFragment from '../../fragments/SvgFragment'
import { styles } from '../../styles/themes/style'

interface IOnboardingPageProps { }

const onboardingTitles = [
    {
        title: "Create your LeadDelta Workspace",
        description: "Use LeadDelta solo or as part of a team. Your team can include coworkers, partners, community members, alumni, or anyone you want to share networks with."
    },
   /*{
        title: "Let's onboard you",
        description: "Tell us about yourself so we can help you set up your workspace."
    },*/
    {
        title: "Download LeadDelta Chrome App",
        description: "Auto-sync your connections daily to LeadDelta, saving you time while unlocking powerful features like data enrichment, messaging, network cleansing, and more."
    },
    {
        title: "Finish the LeadDelta setup",
        description: "Follow the instructions to finish the setup of your account."
    },
    {
        title: "Are you sure you want to continue without LeadDelta Chrome App?",
        description: ""
    }
]

const OnboardingPage: FunctionComponent<IOnboardingPageProps> = () => {
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [currentTitles, setCurrentTitles] = useState<{ title: string, description: string }>(onboardingTitles[0])
    const { store: { onboardingStep, extensionInstalled } } = useAppStore()
    const navigate = useNavigate();
    const [areYouSurePage, setAreYouSurePage] = useState(false);
    const location = useLocation();
    const isLinkedInSection = location.pathname === AppRoutes.ONBOARDING_LINKEDIN;


    useEffect(() => {
        if(onboardingStep < 0) navigate(AppRoutes.HOME)
        setCurrentStep(onboardingStep)
        setCurrentTitles(onboardingTitles[onboardingStep])
    }, [onboardingStep])

    const goToNextStep = () => {
        setAreYouSurePage(true);
        setCurrentTitles(onboardingTitles[3]);
    };

    return <Container>
            <InnerContainer $areYouSurePage={areYouSurePage} $isLinkedInSection={isLinkedInSection}>
                <CenterContainer $areYouSurePage={areYouSurePage} $isLinkedInSection={isLinkedInSection}><Text $heading2 $bold $black>{currentTitles.title}</Text></CenterContainer>
                {areYouSurePage ? 
                    <BoxContainer>
                        <Box>
                            <IconContainer><SvgFragment type='refresh' /></IconContainer>
                            <Text $bold $black $paragraph1>Auto-sync</Text>
                            <Description $lighter $paragraph1>Keep your LinkedIn connections up to date.</Description>
                        </Box>
                        <Box>
                            <IconContainer><SvgFragment type='clock' /></IconContainer>
                            <Text $bold $black $paragraph1>Save time</Text>
                            <Description $lighter $paragraph1>No need to export/import your LinkedIn files.</Description>
                        </Box>
                        <Box>
                            <IconContainer><SvgFragment type='checkmarkBig' /></IconContainer>
                            <Text $bold $black $paragraph1>Set and forget</Text>
                            <Description $lighter $paragraph1>Simple one-time setup let’s you set and forget.</Description>
                        </Box>
                    </BoxContainer> : 
                    <CenterContainer $areYouSurePage={areYouSurePage} $isLinkedInSection={isLinkedInSection}>
                        <Description $lighter>{currentTitles.description}</Description>
                    </CenterContainer>
                }
                <Outlet context={{ goToNextStep }} />
                <CenterContainer $areYouSurePage={areYouSurePage} $isLinkedInSection={isLinkedInSection}>
                    <CarouselNavigation finiteNumber={extensionInstalled ? 3 : 2} currentValue={currentStep} onClickHandler={(value) => setCurrentStep(value)} />
                </CenterContainer>
            </InnerContainer>
        </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 180px 10vw 0px 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow-y:auto;
    height: 100%;
    background: ${({theme: { secondaryColor }}) => secondaryColor};

    @media (max-width: 1400px) {
        padding: 180px 10vw 0px 10vw;
    }

    @media (max-width: 1024px) {
        padding: 180px 10vw 0px 10vw;
    }

    @media (max-width: 768px) {
        padding: 180px 5vw 0px 5vw;
    }
`

const InnerContainer = styled.div<{$areYouSurePage: boolean, $isLinkedInSection: boolean}>`
  display: flex;
  flex-direction: column;
  max-width: ${({ $areYouSurePage, $isLinkedInSection }) => ($areYouSurePage ? "780px" : $isLinkedInSection ? "600px" : "547px")};
  height: 100%;
  width: 100%;
  gap: 24px;
  background-color: white;
`;

const CenterContainer = styled.div<{$areYouSurePage: boolean, $isLinkedInSection: boolean}>`
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: ${({ $areYouSurePage, $isLinkedInSection }) => ($areYouSurePage ? "780px" : $isLinkedInSection ? "600px" : "547px;")};
    padding: ${({ $areYouSurePage }) => ($areYouSurePage ? "0px 120px 0px 120px;" : "none;")};
    text-align: center;
`

const Description = styled(Text)`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
`
const BoxContainer = styled.div`
    display: flex;
    gap: 16px;
    justify-content: center;
`;

const Box = styled.div`
  width: 244px;
  height: 166px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 24px;
  border-radius: 8px;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    & .path { fill: ${styles.colors.primary600}; }
    margin-bottom: 8px;
`;

export default OnboardingPage