import { useNotesActions } from "../../../stores/notes/useNotesActions"
import { useNotesStore } from "../../../stores/notes/useNotesStore"
import { useNoteRequest } from "../../apis/notes/useNotesRequest"
import { useWorkspaceRequest } from "../../apis/workspace/useWorkspaceRequest"
import { TInitialNotesParameters } from "../../../interfaces/store/notesStore"
import { useConnectionsActions } from "../../../stores/connections/useConnectionsActions";
import { useConnectionsStore } from "../../../stores/connections/useConnectionsStore"
import { useAppHandlers } from "../app/useAppHandlers"

export interface ICreateNote {
    connection?: string,
    private?: boolean,
    text?: string[]
}

export const useNotesHandlers = () => {

    const { getNotes, getSingleNote, createNote, updateNoteAccess, updateNote, deleteNote, deleteNotes } = useNoteRequest()
    const { getWorkspaceUsersPublicAll} = useWorkspaceRequest()
    const { setNotes, setNotesParameters, setInitialNotesFilters, resetNotesPagination, resetNotesStore } = useNotesActions()
    const { setConnections } = useConnectionsActions()
    const { setDestructiveModal, hideModal } = useAppHandlers()

    const { store: { notes, notesFilters, notesParameters }} = useNotesStore()
    const { store: { connections }} = useConnectionsStore()

    // console.log('useNotesHandlers:', {
    //     'searchByText': notesFilters.searchByText,
    //     'searchByConnectionName': notesFilters.searchByConnectionName,
    //     'usersTaggedCondition': notesFilters.usersTaggedCondition,
    //     'createdAtTo': notesFilters.createdAtTo,
    //     'createdAtFrom': notesFilters.createdAtFrom,
    //     'page': notesFilters.page,
    //     'pageSize': notesFilters.pageSize,
    //     'sort': notesFilters.sort,
    //     'userCondition': notesFilters.userCondition
    // })

    const getNotesHandler = async () => {
        let params = ''
        //dule: again if it were one variable mapping would be easy, check connections

        if(notesFilters.searchByText) params += `&text=${notesFilters.searchByText}`
        if(notesFilters.searchByConnectionName) params += `&connectionName=${notesFilters.searchByConnectionName}`
        if(notesFilters.createdAtTo) params += `&createdAtTo=${new Date(notesFilters.createdAtTo).getTime()}`
        if(notesFilters.createdAtFrom) params += `&createdAtFrom=${new Date(notesFilters.createdAtFrom).getTime()}`
        if(notesFilters.userCondition) params += `&userCondition=${notesFilters.userCondition}`
        if(notesFilters.selectedUsersMutual?.length) {
            params += `&usersMutual=${notesFilters?.selectedUsersMutual.map((user: any) => user._id).join(',')}`
            params += `&usersMutualCondition=${notesFilters?.usersMutualCondition}`
        }
        if(notesFilters.selectedUsersTagged?.length) {
            params += `&usersTagged=${notesFilters?.selectedUsersTagged.map((user: any) => user._id).join(',')}`
            params += `&usersTaggedCondition=${notesFilters?.usersTaggedCondition}`
        }
        if(notesFilters.noteAccessType && notesFilters.noteAccessType?.value !== 'all') params += `&noteAccessType=${notesFilters.noteAccessType.value}`


        if(notesParameters.page) params += `&page=${notesParameters.page}`
        if(notesParameters.pageSize) params += `&pageSize=${notesParameters.pageSize}`
        if(notesParameters.sort) params += `&sort=${notesParameters.sort}`

        const result = await getNotes(params + '&total=true') 

        if(result) {
            setNotes(result.response)
            setNotesParameters(result.metadata)
        }
        return result;
    }

    const getSingleNoteHandler = async (noteId: string) => {
        const note = await getSingleNote(noteId)

        setNotes([note])
        setNotesParameters({
            hasNext: false,
            page: 1,
            pageSize: 25,
            sort: "newest",
            total : 1
        })
    }

    const setInitialNotesFiltersHandler = (payload: TInitialNotesParameters) => {
        setInitialNotesFilters(payload)
    }

    const setNotesParametersHandler = (payload: TInitialNotesParameters) => {
        setNotesParameters(payload)
    }

    const createUpdateNoteHandler = async (noteId: string | null, note: ICreateNote) => {
        let result

        if(noteId) {
            const updatedNotes = notes.map((obj: any) => {
                if(obj._id === noteId) return { ...obj, text: [note.text && note?.text[0]], private: note.private }
                else return obj
            })
            setNotes(updatedNotes)
            result = await updateNote(noteId, note)
        } else {
            result = await createNote(note)
        }
        updateConnectionsOnNotesActions(result)
        if(result){
            await getNotesHandler()
            return result
        }
    }

    const updateConnectionsOnNotesActions = (result: any) => {
        let connectionWithCreatedNote = connections.map((connection: any) => {
                if (connection._id === result?._id) {
                    return { ...result, users: connection.users, autoTagsArray: connection.autoTagsArray }
                } else {
                    return connection;
                }
        })
        setConnections(connectionWithCreatedNote)
    }

    const workspaceUsersPublicAllHandler = async (params: any) => {
        const { condition, name, email, getAll } = params

        const result = await getWorkspaceUsersPublicAll({ condition, name, email, getAll })
        return result ?? []
    }

    const deleteNoteHandler = async (noteId: string) => {
        const removedNoteFromArray = notes.filter((note: any) => note._id !== noteId)
        setNotes(removedNoteFromArray)
        const result = await deleteNote(noteId)
        if(result?.success) {
            updateConnectionsOnNotesActions(result.data)
            await getNotesHandler()
        }
        return result
    }

    const deleteNotesHandler = async (ids: string[]) => {
        setDestructiveModal({
            headingText: "Delete notes",
            descriptionText: "Are you sure you want to delete selected notes?",
            onCancelHandler: () => hideModal(),
            onConfirmHandler: () => {
                hideModal()
                deleteNotesConfirmed(ids)
            }
        })
    }

    const deleteNotesConfirmed = async (ids: string[]) => {
        const result = await deleteNotes(ids)
        if(result.success) {
            await getNotesHandler()
        }
    }

    const updateNoteAccessHandler = async (payload: any) => {
        setInitialNotesFiltersHandler({...notesFilters, selectedNotes: [], isAllNotesSelected: false})

        let updatedNotes = []
        
        for(const noteId of payload.ids) {
            updatedNotes = notes.map((obj: any) => {
                if(obj._id === noteId) return { ...obj, private: payload.private }
                else return obj
            })
        }
        const result = await updateNoteAccess(payload)
        if(result?.success) setNotes(updatedNotes)
    }

    const numberOfNotesFiltersHandler = () => {
        let numberOfFilters = 0

        if(notesFilters.searchByText !== '') numberOfFilters += 1
        if(notesFilters.searchByConnectionName !== '') numberOfFilters += 1
        if(notesFilters.createdAtTo !== '' || notesFilters.createdAtFrom !== '') numberOfFilters += 1
        if(notesFilters.selectedUsersMutual?.length) numberOfFilters += 1
        if(notesFilters.selectedUsersTagged?.length) numberOfFilters += 1
        if(notesFilters.noteAccessType && notesFilters.noteAccessType?.value !== 'all') numberOfFilters += 1

        return numberOfFilters
    }

    const setClearNotesFilterHandler = () => {
        resetNotesPagination();
    }

    const resetNotesStoreHandler = () => {
        resetNotesStore();
    }

    return {
        getNotesHandler,
        createUpdateNoteHandler,
        workspaceUsersPublicAllHandler,
        deleteNoteHandler,
        updateNoteAccessHandler,
        setNotesParametersHandler,
        setInitialNotesFiltersHandler,
        numberOfNotesFiltersHandler,
        setClearNotesFilterHandler,
        resetNotesStoreHandler,
        deleteNotesHandler,
        getSingleNoteHandler
    }
}
