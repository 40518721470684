import React, { FunctionComponent, useEffect, useState, useCallback, FormEvent } from 'react'
import styled from "styled-components"
import { isValid } from '../../utils'
import { IFormProperty } from '../../interfaces/common'
import Input from '../../components/Input'
import FormControl from '../../components/FormControl'
import Button from '../../components/Button'
import List from '../../components/list/List'
import InvitationItemFragment from '../../fragments/InvitationItemFragment'
import { useWorkspaceRequest } from '../../services/apis/workspace/useWorkspaceRequest'
import { useAuthHandlers } from '../../services/actions/auth/useAuthHandlers'
import { IWorkspace } from '../../interfaces/models/workspace'
import { useWorkspaceHandlers } from '../../services/actions/workspace/useWorkspaceHandlers'
import { useAuthActions } from '../../stores/auth/useAuthActions'
import { SidebarTypes } from '../../interfaces/store/appStore'
import { useAppHandlers } from '../../services/actions/app/useAppHandlers'
import { useAppStore } from '../../stores/application/useAppStore'
import { useWorkspaceUsersStore } from '../../stores/workspaceUsers/useWorkspaceUsersStore'
import { useWorkspaceUsersActions } from '../../stores/workspaceUsers/useWorkspaceUsersActions'

interface ICreateWorkspaceFormProps {
    loginWorkspace?: boolean,
    workspace?: IWorkspace
}

interface ITeamFormState extends Record<string,any> {
    teamName: IFormProperty,
    companyName: IFormProperty,
    websiteUrl: IFormProperty,
}

const CreateWorkspaceForm: FunctionComponent<ICreateWorkspaceFormProps> = ({loginWorkspace, workspace}) => {
    const { getExistsWorkspace } = useWorkspaceRequest()
    const { createAndLoginToWorkspaceHandler, createWorkspaceHandler } = useAuthHandlers()
    const { updateWorkspaceHandler, sendInvitesHandlers } = useWorkspaceHandlers()
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)
    const { store: { requestLoading } } = useAppStore()
    const { setWorkspace } = useAuthActions()
    const { closeSidebar } = useAppHandlers()

    const formOriginalState: ITeamFormState = {
        teamName: {
            property: "",
            placeholder: "",
            errors: [],
            async isValid(workspaceName = '') {
                this.errors.length = 0
                let requiredError = (this?.property?.length >= 2 && this?.property?.length <= 32) ? undefined : "Team name must be between 2 and 32 characters long!"
                requiredError && this?.errors?.push(requiredError)
                const data = requiredError ? false : await getExistsWorkspace({name: workspaceName})
                data && this?.errors?.push("Name already exists.")
                updateErrors('teamName', this?.errors)
                return this?.errors?.length === 0
            }
        },
        companyName: {
            property: "",
            errors: [],
            async isValid() {
                this.errors.length = 0
                let requiredError = this?.property?.length <= 0 || this?.property?.length > 3 ? undefined : "Company name must be more than 3 characters long!"
                requiredError && this?.errors?.push(requiredError)
                updateErrors('companyName', this?.errors)
                return this?.errors?.length === 0
            }
        },
        websiteUrl: {
            property: "",
            errors: [],
            async isValid() {
                this.errors.length = 0
                let websiteError =  this?.property?.length <= 0 || isValid.url(this?.property) ? undefined : "Wrong website format."
                websiteError && this?.errors?.push(websiteError)
                updateErrors('websiteUrl', this?.errors)
                return this?.errors?.length === 0
            }
        }
    }

    const [teamFormState, setTeamFormState] = useState<ITeamFormState>({ ...formOriginalState })
    const [teamFormValid, setTeamFormValid] = useState<boolean>(false)

    useEffect(() => {
        if(workspace) setTeamFormState({
            ...teamFormState,
            teamName: {
                ...teamFormState.teamName, 
                property: workspace.name
            } 
        })
    }, [])

    useEffect(() => {
        if('createWorkspace' in requestLoading) setSubmitLoading(requestLoading?.['createWorkspace'])
    }, [requestLoading]);

    const validateForm = useCallback(async(value?: string) => {
        setTeamFormValid(false);
        const validForm = await teamFormState?.teamName.isValid(value) && await teamFormState?.companyName.isValid() && await teamFormState?.websiteUrl.isValid();
        setTeamFormValid(validForm);
        return validForm;
    }, [teamFormState])

    const updateForm = useCallback((control: any, newValue: string) => {
        setTeamFormState(previous => {
            let newState = { ...previous, [control]: { ...previous[control], property: newValue.trim()} };
            return newState
        })
    }, [teamFormState]); 

    const updateErrors = useCallback((control: any, newValue: string[]) => {
        setTeamFormState(previous => {
            let newState = { ...previous, [control]: { ...previous[control], errors: [...newValue]} };
            return newState
        })
    }, [teamFormState]); 

    const submitFormHandler = useCallback(async (e: FormEvent) => {
        e.preventDefault();
		const validForm = await validateForm();
        setSubmitLoading(true);
        if(validForm){
            const payload = {
                name: teamFormState.teamName.property,
                ...(teamFormState.companyName.property && { companyName: teamFormState.companyName.property }),
                ...(teamFormState.websiteUrl.property && { websiteUrl: teamFormState.companyName.property }),
                invites: [],
                isPersonal: loginWorkspace ? true : false
            }
        if(!workspace) {
            if(loginWorkspace) {
                const data = await createAndLoginToWorkspaceHandler(payload)
                if(!data) updateErrors('teamName',["Name already exists."])
            }
            else {
                const data = await createWorkspaceHandler(payload)
                if(!data) {
                    updateErrors('teamName',["Name already exists."])
                    setTeamFormValid(false)
                } else {
                    closeSidebar(SidebarTypes.WORKSPACE_CREATE)
                }
            }
        } else {
            setWorkspace({...workspace, name: payload.name})
            await updateWorkspaceHandler({name: payload.name})

            closeSidebar(SidebarTypes.WORKSPACE_CREATE)
        }

        setSubmitLoading(false);

        } else setSubmitLoading(false);
	}, [teamFormState]); 

    return (
        <StyledForm onSubmit={submitFormHandler}>
            <FormControl $orientation="column" label="Workspace name *required (you can edit later)" error={teamFormState?.teamName?.errors?.[0]}>
                <Input 
                    prefilledValue={teamFormState?.teamName?.property} 
                    placeholder={teamFormState?.teamName?.placeholder} 
                    onChangeHandler={async (value: string) => {
                        updateForm('teamName', value)
                        await validateForm(value?.trim())
                    }} 
                />
            </FormControl>
            {!workspace ? (<>
                <FormControl $orientation="column" label="Company name" error={teamFormState?.companyName?.errors?.[0]}>
                    <Input 
                        prefilledValue={teamFormState?.companyName?.property} 
                        placeholder={teamFormState?.companyName?.placeholder} 
                        onChangeHandler={(value: string) => updateForm('companyName', value)}
                        onBlurHandler={async (e: FocusEvent) => await teamFormState?.companyName?.isValid() && await validateForm()}
                    />
                </FormControl>
                <FormControl $orientation="column" label="Company website URL" error={teamFormState?.websiteUrl?.errors?.[0]}>
                    <Input 
                        prefilledValue={teamFormState?.websiteUrl?.property} 
                        placeholder={teamFormState?.websiteUrl?.placeholder} 
                        onChangeHandler={(value: string) => updateForm('websiteUrl', value)} 
                        onBlurHandler={async (e: FocusEvent) => await teamFormState?.websiteUrl?.isValid() && await validateForm()}
                    />
                </FormControl>
            </>) : null}
            <Button
                $type='blue'
                type="submit"
                disabled={
                    (teamFormState.teamName.property.length === 0) ||
                    (typeof loginWorkspace === 'undefined' && (requestLoading['existsWorkspace'] || !teamFormValid))
                }
                isLoading={submitLoading}
            >
                {!workspace ? 'Continue' : 'Update'}
            </Button>
        </StyledForm>
    )
}


const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export default CreateWorkspaceForm;