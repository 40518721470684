import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../components/Column';
import NotesControlBar from '../components/notes/NotesControlBar';
import NotesTable from '../components/notes/NotesTable';
import { useNotesHandlers } from '../../../services/actions/notes/useNotesHandlers';
import { useNotesStore } from '../../../stores/notes/useNotesStore';
import { useLocation, useNavigate } from 'react-router-dom';

interface INoteSectionProps { }

const NoteSection: FunctionComponent<INoteSectionProps> = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { getNotesHandler, getSingleNoteHandler } = useNotesHandlers()
    const [selectedNotes, setSelectedNotes] = useState<any[]>([])
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false)
    const { store: { notes, notesFilters, notesParameters }} = useNotesStore()
    const [controlBarHeight, setControlBarHeight] = useState<number>(0);
    const controlBarRef = useRef<HTMLDivElement>(null);
    const { noteId } = location.state || {}

    const updateControlBarHeight = () => {
        if (controlBarRef.current) {
            const height = controlBarRef.current.offsetHeight - 2;
            setControlBarHeight(height);
        }
    };

    useEffect(() => {
        updateControlBarHeight();
    }, [controlBarRef.current]);

    useEffect(() => {
        noteId ? getSingleNoteHandler(noteId) : getNotesHandler()
    }, [notesParameters.page, notesParameters.pageSize, notesFilters, noteId]) //dule: another reason to use one variable, this is silly

    useEffect(() => {
        const handleUnload = () => navigate(location.pathname, { state: null })
    
        window.addEventListener("beforeunload", handleUnload)
        
        return () => window.removeEventListener("beforeunload", handleUnload)
    }, [navigate, location.pathname])

    const handleDataChange = (selectedNotes: any[]) => {
        setSelectedNotes(selectedNotes);
    };

    return (
        <Container>
            {(
                <Column>
                    <StickyControlBar ref={controlBarRef}>
                        <NotesControlBar
                            selectedNotes={selectedNotes}
                            isAllSelectedProps={{isAllSelected, setIsAllSelected}}
                            notesFilters={notesFilters}
                            notesParameters={notesParameters}
                        />
                    </StickyControlBar>
                    <NotesTable
                        selectedNotes={selectedNotes}
                        isAllSelectedProps={{isAllSelected, setIsAllSelected}}
                        notes={notes}
                        notesFilters={notesFilters}
                        controlBarHeight={controlBarHeight}
                        selectedNotesChange={handleDataChange}
                    />
                </Column>
            )}
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 0px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
`

const StickyControlBar = styled.div`
    position: sticky;
    top: 0;
    z-index: 101;
    background: ${({theme: { tertiaryColor }}) => tertiaryColor};
    padding-top: 16px;
`;

export default NoteSection
