import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styles } from "../../styles/themes/style";
import { Modal } from "react-bootstrap";
import Button from "../../components/Button";
import { AppRoutes } from "../../interfaces/store/appStore";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../stores/auth/useAuthStore";
import { useAuthHandlers } from "../../services/actions/auth/useAuthHandlers";
import { useAppStore } from "../../stores/application/useAppStore";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import Text from "../../components/Text";

const DeleteUserConfirmation = React.forwardRef(() => {
  const [step, setStep] = useState(1);
  const [deleteUserLoading, setDeleteUserLoading] = useState<boolean>(false);

  const { deleteUserAccountHandler, logoutHandler } = useAuthHandlers();
  const { showSuccessToast } = useAppHandlers();

  const {
    store: { user },
  } = useAuthStore();
  const {
    store: { requestLoading },
  } = useAppStore();

  const navigate = useNavigate();

  const firstStepTitle = "Are you sure you want to delete your account?";
  const firstStepDescription2 =
    "This action is permanent and cannot be undone.";
  const secondStepTitle = "Final Warning: This action is permanent";
  const secondStepDescription1 = `You will be redirected to the Chrome Web Store in case you want to "Remove from Chrome" LeadDelta Chrome Extension. Ignore this if you haven’t installed LeadDelta Chrome Extension.`;
  const secondStepDescription2 = "Please confirm the deletion of your profile.";
  const webStoreUrl =
    "http://url1530.leaddelta.com/ls/click?upn=WQjiRiZ5QXBrlipRDLcJClxxOdohzRaMOuO4cA-2BwU8mGUyWmJXhaSejB0L-2FzWeSXyeQASLvoLADDdm2hM8RN-2Fmb8AP-2FF8XgvUbJXJL7sOg0A-2FaA0KhONHiDG4lh3v-2FbLg1pavd9SGBTlQ36a1zJA7Q-3D-3DKPpv_bpBWbMOC6QRgMfkjC5vUAm5tc7MgjQYPBIgyJDgrwM3gOf26HrXUbWknSGQoRp6-2Fabp-2BLayI6Q2AxQJGAyRC6SVrbuGzXVQgToGQW7DjIL-2B0tZRJJNlsQS36qXOCnLN-2Bx7bZIQ6AV17R5I5nX-2BFqy8KH39UMEGrgsLHC73f-2BnMzLWvdJgulk-2BL2JflzzpK-2Fu2vJGRifepXvAB1Gl3MCde-2FT-2FJKBE986L18leU8UF254W5F8aNUiNL8QZXLx7QPjm1LmfaH99vQWqC72wONcSOG7IQRFj8aaLod5eOYNQcynaNPO0xG-2FA-2FfhXV1umiW3aWoKHkmaMJUhk1X3-2Fh4h-2FIw-3D-3D";

  useEffect(() => {
    if ("deleteUser" in requestLoading) {
      setDeleteUserLoading(requestLoading?.["deleteUser"]);
    }
  }, [requestLoading]);

  const deleteUserAccount = async () => {
    await deleteUserAccountHandler();
    showSuccessToast({ message: "Account successfully deleted" });

    setTimeout(() => {
      logoutHandler();
      window.open(webStoreUrl, "_self")?.focus();
      navigate(AppRoutes.SIGNIN);
    }, 5000);
  };

  return step === 1 ? (
    <Container>
      <InnerContainer>
        <StyledHeading>
          <Heading>{firstStepTitle}</Heading>
        </StyledHeading>
        <StyledText>
          <b>Important</b>: All your tags, notes, tasks, reminders, templates,
          pinned messages, saved settings, connections, and more will be
          permanently deleted in this and other workspaces connected to the
          email address: <StyledEmail>{user?.email}</StyledEmail>
        </StyledText>
        <TextWrapper>{`${firstStepDescription2}`}</TextWrapper>
        <StyledModalFooter $center={"end"}>
          <Button
            $type={"cancel"}
            $mediumBigButton
            onClickHandler={(e) =>
              navigate(AppRoutes.SETTINGS_WORKSPACE_ADMIN, {
                state: { selectedSlug: "accountOverview" },
              })
            }
          >
            {"Cancel"}
          </Button>
          <Button
            $type="dark-red"
            $bigButton
            onClickHandler={(e) => setStep(2)}
          >
            {"Delete my Account"}
          </Button>
        </StyledModalFooter>
      </InnerContainer>
    </Container>
  ) : (
    <Container>
      <InnerContainer>
        <StyledHeading>
          <Heading>{secondStepTitle}</Heading>
        </StyledHeading>
        <TextWrapper>{`${secondStepDescription1}`}</TextWrapper>
        <TextWrapper>{`${secondStepDescription2}`}</TextWrapper>
        <StyledModalFooter $center={"end"}>
          <Button
            $type={"cancel"}
            $mediumBigButton
            onClickHandler={(e) =>
              navigate(AppRoutes.SETTINGS_WORKSPACE_ADMIN, {
                state: { selectedSlug: "accountOverview" },
              })
            }
          >
            {"Cancel"}
          </Button>
          <Button
            $type="dark-red"
            $bigButton
            onlySpinner={deleteUserLoading}
            spinnerSvg={true}
            onClickHandler={(e) => deleteUserAccount()}
          >
            {"Confirm Deletion"}
          </Button>
        </StyledModalFooter>
      </InnerContainer>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 116px 470px 0px 470px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  height: 100%;
  background-color: white;
`;

const StyledHeading = styled(Modal.Header)<{ checkmark?: boolean }>`
  border-bottom: none;
  padding: 24px;
  align-items: flex-start;
  gap: 16px;
  justify-content: space-between;
  ${({ checkmark }) => checkmark && "justify-content: center;"}

  & .btn-close {
    padding-top: 18px;
  }
  & .btn-close:focus {
    box-shadow: none;
  }
  /* & .modal-title { ${({ closeButton }) =>
    closeButton ? "margin-left: auto" : "margin: auto;"} } */
`;

const Heading = styled(Modal.Title)`
  display: flex;
  flex-direction: row;
  font-family: NunitoSans;
  align-items: center;
  gap: 6px;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  color: ${({ theme: { primary } }) =>
    primary ? styles.colors.black600 : styles.colors.darkTextcolor};
`;

const TextWrapper = styled.div`
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const StyledText = styled(Text)`
  display: inline-block;
  padding: 0 24px 24px 24px;
  text-align: left;
  font-weight: normal;
  font-size: 14px;
`;

const StyledEmail = styled(Text)`
  display: inline-block;
  color: ${styles.colors.primary600};
`;

const StyledModalFooter = styled(Modal.Footer)`
  border-top: none;
  display: flex;
  flex-wrap: nowrap;
  gap: 15px;
  padding: 0 24px 24px 24px;

  ${({ $center }) => $center && `align-self: ${$center};`}

  & button {
    margin: 0;
  }
`;

export default DeleteUserConfirmation;
