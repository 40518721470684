import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import BoxContainer from '../../../fragments/BoxContainer'
import { useAuthStore } from '../../../stores/auth/useAuthStore'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import List from '../../../components/list/List'
import SvgFragment from '../../../fragments/SvgFragment'
import ListItem, { IListItemProps } from '../../../components/list/ListItem'
import Column from '../../../components/Column'
import Row from '../../../components/Row'
import { styles } from "../../../styles/themes/style";
import { formatNumber, isValidEmail } from '../../../utils'
import { useTasksHandlers } from '../../../services/actions/tasks/useTasksHandlers'
import { ITaskResponse } from '../../../interfaces/response/task'
import { useNavigate } from 'react-router-dom'
import { AppRoutes, SidebarTypes } from '../../../interfaces/store/appStore'
import { useAuthHandlers } from '../../../services/actions/auth/useAuthHandlers'
import { useAppHandlers } from '../../../services/actions/app/useAppHandlers'
import { useAppStore } from '../../../stores/application/useAppStore'
import { useExternalHandlers } from '../../../services/actions/navigation/useExternalHandlers'
import { useAnalyticsHandlers } from '../../../services/actions/analytics/useAnalyticsHandlers'
import { ReactComponent as OnboardingSvg } from '../../../assets/img/Onboarding.svg'
import { ReactComponent as RoadmapSvg } from '../../../assets/img/Roadmap.svg'
import { ReactComponent as SupportSvg } from '../../../assets/img/Support.svg'
import TasksTable from '../components/home/HomeTasksTable'
import moment from 'moment'
import { useInterval } from '../../../wrappers/IntervalContext'
import { useConnectionsStore } from '../../../stores/connections/useConnectionsStore'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ActionText from '../../../components/ActionText'
import { useConnectionsHandlers } from '../../../services/actions/connections/useConnectionsHandlers'
import HomeBirthdateTable from '../components/home/HomeBirthdateTable'
import { useConnectionsRequest } from '../../../services/apis/connections/useConnectionsRequest'

interface IHomeSectionProps { }

const lastSyncAtLocalStorage = window.localStorage.getItem('lastSyncAt')
const lastSyncAt = lastSyncAtLocalStorage && lastSyncAtLocalStorage !== 'undefined' ? lastSyncAtLocalStorage : 0

interface IAppTaskProps extends IListItemProps { $svgWhite?: boolean; $grey?: boolean, $green?: boolean, $blue?: boolean, disabled?: boolean, spaceBetween?: boolean } 

const HomeSection: FunctionComponent<IHomeSectionProps> = () => {
    useEffect(() => {
        document.title = "LeadDelta Home";
      }, []);
    const { store: { user, workspace, authData } } = useAuthStore()
    const { getHomePageTasks, getAndResolveTasksHandler } = useTasksHandlers()
    const { syncConnectionsHandler, getUserHandler, syncConversationsHandler, getMeHandler } = useAuthHandlers()
    const { setSyncConnectionsModalHandler, openSidebar, setConnectionsOverviewModal, setDestructiveModal, hideModal, setRemovalOverviewModal, setAddMembersModal  } = useAppHandlers()
    const { store: { extensionInstalled, requestLoading } } = useAppStore()
    const { visitChromeStore, openOnboarding, openRoadmap, chatWithUs } = useExternalHandlers()
    const [ tasks, setTasks ] = useState<ITaskResponse[]>([])
    const navigate = useNavigate()
    const { getTotalConnectionsOverviewAnalytics } = useAnalyticsHandlers()
    const { store: { scheduledRemovalParameters }} = useConnectionsStore()
    const { startInterval } = useInterval();
    const { cancelConnectionsRemovalHandler } = useConnectionsHandlers()
    const { getConnections } = useConnectionsRequest()

    const externalVideosSection = [
        {
            heading: 'Welcome to LeadDelta',
            label: 'Explore how to simplify connection management, organize your network, and build stronger professional relationships.',
            onClick: () => window.open('https://www.loom.com/embed/e8fdd0aef606407a984379515b119789?sid=44f3f1e6-2217-42a2-9948-a94cee931e1f', '_blank')
        },
        {
            heading: 'How to use LeadDelta as individual',
            label: 'Effortlessly manage connections, organize your network, and unlock LeadDelta`s full potential for meaningful relationships.',
            onClick: () => {}
        },
        {
            heading: 'How to use LeadDelta as team',
            label: 'Effortlessly manage connections, organize your network, and unlock LeadDelta`s full potential for meaningful relationships.',
            onClick: () => {}
        }
    ]

    
    const birthDateFilterSearch = {
        birthDateFrom: moment().utc().startOf('day').toDate(),
        birthDateTo: moment().utc().endOf('day').toDate(),
        isAutotagsFilterOr: 'yes',
        selectedUsersMutual: [
            {
                _id: user._id,
                firstName: user?.firstName,
                lastName: user?.lastName
            }
        ],
        autotagsFilter: [
            {
                "_id": "New Connection",
                "title": "New Connection",
                "color": "#5964ff",
                "isForbidden": true
            },
            {
                "_id": "LinkedIn 1st",
                "title": "LinkedIn 1st",
                "color": "#5964ff",
                "isForbidden": true
            }
        ]
    }

    // const [connectionsOverviewModal, setConnectionsOverviewModal] = useState<boolean>(false)
    const [ analytics, setAnalytics ] = useState<any>({
        'connections-total': 0,
        "connections-imported-csv-total": 0,
        'connections-updated': 0,
        'connections-updated-connected-to-linkedin': 0,
        "connections-disconnected-total": 0
    })
    const [birthdateConnections, setBirthdateConnections] = useState<any>([])
    const [birthdateConnectionsLoading, setBirthdateConnectionsLoading ] = useState<boolean>(true)

    useEffect(() => {
        if(workspace) reloadTasksHandler();
        getTotalConnectionsOverviewAnalyticsHandler()
    }, [workspace])


    useEffect(() => {
        if(extensionInstalled && user?.linkedinData?.publicIdentifier && !user?.isSynced && user?.preferences?.hideDemoVideo
            && !moment().isBefore(moment(lastSyncAt).add(20, 'minutes'))
        ){
            setLastSyncAt()
        }
    }, [extensionInstalled, user])

    const reloadTasksHandler = useCallback(async (resolved?: boolean) => {
        let tasks = await getHomePageTasks(true)
        if(tasks.length && !resolved) loadTasksStateHandler(tasks)
        tasks = !tasks.length ? await getHomePageTasks() : tasks
        setTasks(tasks)
    }, [workspace])

    const loadTasksStateHandler = useCallback(async (tasks: ITaskResponse[]) => {
        await getAndResolveTasksHandler(tasks)
        reloadTasksHandler(true)
    }, [])

    const setLastSyncAt = useCallback(async() => {
        if(extensionInstalled) setSyncConnectionsModalHandler((user) => { 
            syncConnectionsHandler(user, false, authData); 
            if(!user?.isSynced) {
                startInterval(async () => getUserHandler(), 300000)
            }
            syncConversationsHandler() //todo: runtime external messages called only from background, not inbox background
        })
    }, [user, extensionInstalled, workspace, authData])

    const getTotalConnectionsOverviewAnalyticsHandler = useCallback( async () => {
        const statistics = await getTotalConnectionsOverviewAnalytics();
        let statisticsObject = statistics.reduce((accumulator, value) => ({...accumulator, ...{[value.version]: value.value}}), {});
        setAnalytics({...analytics, ...statisticsObject})
    }, [])

    const setConnectionsOverviewModalHandler = async () => {
        await getTotalConnectionsOverviewAnalyticsHandler()
        // setConnectionsOverviewModal(true)
    }
    const lastSyncAtLocalStorage = window.localStorage.getItem('lastSyncAt')
    const lastSyncAt = lastSyncAtLocalStorage && lastSyncAtLocalStorage !== 'undefined' ? lastSyncAtLocalStorage : 0

    const syncConnectionsStartedLocalStorage = window.localStorage.getItem('syncConnectionsStarted')
    const syncConnectionsStarted = syncConnectionsStartedLocalStorage && syncConnectionsStartedLocalStorage !== 'undefined' ? true : false

    const onCancelConnectionsRemovalHandler = async () => {
        setDestructiveModal({
            headingText: "Are you sure you want to cancel connections removal?",
            descriptionText: `${scheduledRemovalParameters?.count} out of ${scheduledRemovalParameters?.total} connections removed.`,
            confirmBtnTitle: 'Yes, Cancel Removal',
            onCancelHandler: () => hideModal(),
            onConfirmHandler: async () => {
                hideModal()
                // TODO - stop executing disconnect
                cancelConnectionsRemovalHandler();
            }
        })
    }

    const getBirthdateConnectionsHandler = useCallback(async () => {
        let connectionData = await getConnections({
            page: 1,
            pageSize: 4,
            birthDateFrom: birthDateFilterSearch.birthDateFrom,
            birthDateTo: birthDateFilterSearch.birthDateTo,
            isAutotagsFilterOr: birthDateFilterSearch.isAutotagsFilterOr,
            autotagsFilter: birthDateFilterSearch.autotagsFilter.map(tag => tag._id).join(','),
            usersMutual: birthDateFilterSearch.selectedUsersMutual.map(user => user._id).join(',')
        });
        if(connectionData) setBirthdateConnections(connectionData.connections)
    }, [])

    useEffect(() => {
        if('getConnections' in requestLoading) setBirthdateConnectionsLoading(requestLoading?.['getConnections'])
    }, [requestLoading]);

    useEffect(() => {
        getBirthdateConnectionsHandler()
    }, [])

    return <Container>
        <StyledBoxedContainer>
            <HeaderRow>
                <Column gap='12px'>
                    <Text $black $heading2 $bold $capitalize={!isValidEmail(user?.firstName)}>{`${isValidEmail(user?.firstName) ? 'Hey there 👋' : `Hi, ${user?.firstName}`}!`}</Text>
                    <Text $lighter>{`Let's work your network.`}</Text>
                </Column>
                {/* <Button $extraBigButton $type='grey' onClickHandler={setConnectionsOverviewModalHandler}> */}
                <Button $extraBigButton $type='grey' onClickHandler={() => setConnectionsOverviewModal(analytics)}>
                    <ButtonRow gap='7px'>
                        <Text $black $bold>{formatNumber(analytics['connections-total'])}</Text>
                        <Text $lighter>{'Number of connections'}</Text>
                    </ButtonRow>
                </Button>
            </HeaderRow>
            <List>
           {!extensionInstalled && <AppTask $grey $svgWhite spaceBetween>
                    <Column>
                        <Text $bold>{'Download LeadDelta Chrome App'}</Text>
                        <Text $lighter>{'Experience full productivity boost and data enrichment.'}</Text>
                    </Column>
                    <Button $customDisabled $width='155px' tooltipMessage={'Download LeadDelta Chrome app first and then sync your connections.'} $type='blue' $SVGtype='import' onClickHandler={() => visitChromeStore()}>Download App</Button>
                </AppTask>
            }
            {!(workspace?.initialSync || moment().isBefore(moment(lastSyncAt).add(20, 'minutes')) || syncConnectionsStarted) && 
            <AppTask $grey $svgWhite disabled={!extensionInstalled} spaceBetween >
                    <Column>
                        <Text $bold $heading5>{'Sync your LinkedIn connections'}</Text>
                        <Text $lighter>{extensionInstalled ? 'Auto-sync you LinkedIn connections daily.' : 'Download LeadDelta Chrome app first and then sync your connections.'}</Text>
                        {/* getLinkedinConnections background script, figure out how to do it in the background, check extension and so  */}
                    </Column>
                    <Button $customDisabled $width='155px' tooltipMessage={''} disabled={!extensionInstalled} $type='blue' $SVGtype='refresh' onClickHandler={()=>setLastSyncAt()}>Sync Connections</Button>
                </AppTask>}
                <AppTask disabled={workspace?.isStarter} spaceBetween $grey>
                    <Column>
                        <Text $bold>{'Import CSV file'}</Text>
                        <Text $lighter>{'Import CSV file.'}</Text>
                    </Column>
                    <Button $customDisabled $width='155px' tooltipMessage={workspace?.isStarter ? 'Upgrade to Pro or Business to unlock this feature' : ''} disabled={workspace?.isStarter} $type='mid-blue' $SVGtype='import' onClickHandler={() => !workspace?.isStarter && navigate(AppRoutes.CSV_IMPORT)}>Import CSV File</Button>
                </AppTask>
                <AppTask disabled={workspace?.isStarter} spaceBetween $grey>
                    <Column>
                        <Text $bold>{'Friends can search each other’s connections.'}</Text>
                        <Text $lighter>{'You can invite your coworkers, investors, friends… peers or your community members.'}</Text>
                    </Column>
                    <Button $customDisabled $width='155px' tooltipMessage={workspace?.isStarter ? 'Upgrade to Pro or Business to unlock this feature' : ''} disabled={workspace?.isStarter} $type='mid-blue' $SVGtype='plus' onClickHandler={() => !workspace?.isStarter && setAddMembersModal()}>Invite Friends</Button>
                </AppTask>
                {scheduledRemovalParameters?.inProgress
                    && (scheduledRemovalParameters?.total > scheduledRemovalParameters?.count)
                    && (scheduledRemovalParameters?.dailyLimit > scheduledRemovalParameters?.dailyCount) &&
                    <AppTask $grey>
                        <LoadingSpinner />
                        <Column>
                            <StyledText>
                                <Text $bold>{`${scheduledRemovalParameters?.total} LinkedIn Connections are scheduled to be removed.`}</Text>
                            </StyledText>
                            <StyledText>
                                <Text $lighter>{`Successfully removed ${scheduledRemovalParameters?.count} LinkedIn Connections.`}
                                    <ActionText onClickHandler={() => setRemovalOverviewModal()}>Removal overview</ActionText>
                                    <ActionText danger onClickHandler={() => onCancelConnectionsRemovalHandler()}>Cancel Removal</ActionText>
                                </Text>
                            </StyledText>
                        </Column>
                    </AppTask>
                }
            </List>

            <WrapRow gap="16px">
                <TaskColumn gap="16px">
                    <HeaderRow>
                        <Text $heading5>{'Your tasks'}</Text>
                        <Button $type='grey' $bigButton onClickHandler={() => navigate(AppRoutes.TASKS)}>{'Manage tasks'}</Button>
                    </HeaderRow>
                    <TasksTable tasks={tasks} />
                </TaskColumn>
                <TaskColumn gap="16px">
                    <HeaderRow>
                        <Text $heading5>{'Today’s birthdays'}</Text>
                        <Button
                            disabled={birthdateConnectionsLoading || !birthdateConnections.length}
                            $type='grey'
                            $bigButton
                            onClickHandler={() =>navigate(AppRoutes.CONNECTIONS, { state: birthDateFilterSearch } )}>{'See all'}</Button>
                    </HeaderRow>
                    <HomeBirthdateTable birthdateConnections={birthdateConnections} birthdateConnectionsLoading={birthdateConnectionsLoading} />
                </TaskColumn>
            </WrapRow>
        </StyledBoxedContainer>
        <StyledBoxedContainer>
            <Row>
                {externalVideosSection.map((section: any) => (
                    <ExternalVideosSection onClick={section.onClick} >
                        <Text $heading6>Watch a video</Text>
                        <Row spaceBetween>
                            <Text className='external-videos-section-title' $heading5 $bold>{section.heading}</Text>
                            <SvgFragment type='openExternal' />
                        </Row>
                        <Text $paragraph3>{section.label}</Text>
                    </ExternalVideosSection>
                ))}
            </Row>
        </StyledBoxedContainer>
        <StyledBoxedContainer>
            <HeadingRow><Text $heading4>{'Help center'}</Text></HeadingRow>
            <WrapRow gap="16px">
                <StyledSupport gap="12px" onClick={()=>openOnboarding()}>
                    <OnboardingSvg />
                    <Column gap="8px">
                        <Text $heading4>{'Onboarding'}</Text>
                        <Text $lighter>{'Just joined LeadDelta? Discover how to maximize your experience and unlock its full potential.'}</Text>
                    </Column>
                </StyledSupport>
                <StyledSupport gap="12px" onClick={()=>openRoadmap()}>
                    <RoadmapSvg />
                    <Column gap="8px">
                        <Text $heading4>{'Roadmap'}</Text>
                        <Text $lighter>{'We build for you! Share your thoughts and contribute your ideas to shape the future of LeadDelta.'}</Text>
                    </Column>
                </StyledSupport>
                <StyledSupport gap="12px" onClick={()=>chatWithUs()}>
                    <SupportSvg />
                    <Column gap="8px">
                        <Text $heading4>{'Support'}</Text>
                        <Text $lighter>{'Need help? Explore our support hub and helpful articles or connect with our LeadDelta support team.'}</Text>
                    </Column>
                </StyledSupport>
            </WrapRow>
        </StyledBoxedContainer>
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
    align-items: center;
`
const StyledBoxedContainer = styled(BoxContainer)`
    max-width: 1024px;
`

const HeaderRow = styled(Row)`
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const ButtonRow = styled(Row)`
    justify-content: center;
`

const WrapRow = styled(Row)`
    width: 100%;
    flex-wrap: wrap;
`
const TaskColumn = styled(Column)`
    position: relative;
    height: 330px;
    overflow: hidden;
    border-radius: 4px;
    flex-grow: 1;
    flex: 1 1;
    min-width: 400px;
`

const AppTask = styled(ListItem)<IAppTaskProps>`
    padding: 10px 16px;
    border-radius: 4px;
    gap: 10px;
    align-items: center;
    justify-content: ${({ spaceBetween}) => (spaceBetween ? 'space-between' : 'flex-start')};
    ${({ $grey, theme: { quinaryColor, senaryColor } }) => $grey && `
        background: ${senaryColor};
        border: 1px solid ${quinaryColor};
    `}
    ${({ $svgWhite, theme: { quinaryColor, senaryColor } }) => $svgWhite && `
        & .svg .path {
            fill: white;
        }
    `}
    ${({ $blue }) => $blue && `
        background: ${styles.colors.blue200};
        border: 1px solid ${styles.colors.primary600};
        & .svg .path {
            fill: ${styles.colors.primary600}
        }
    `}
    ${({ $green }) => $green && `
        background: ${styles.colors.green200};
        border: 1px solid ${styles.colors.green600};
        & .svg .path {
            fill: ${styles.colors.green600}
        }
    `}
    ${({ disabled }) => disabled && `
        & .svg .path {
            fill: ${styles.colors.black200}
        }
    `}
`
const StyledSupport = styled(Column)`
    width: 320px;
    &:hover {
        opacity: 0.75;
        cursor: pointer;
    }
`
const HeadingRow = styled(Row)`
    width: 100%;
`
const StyledText = styled(Text)`
  display: inline-block;
  text-align: left;
`;
const StyledActionText = styled(ActionText)`
    display: flex;
    align-items: baseline;
    span {    
        color: ${styles.colors.black300};
        font-size: 12px;
    }

    &:hover{
        path { fill: ${styles.colors.primary400} }
    }

    div {
        margin-right: 4px;
        svg {
            margin-bottom: 4px;
        }
    }
`

const ExternalVideosSection = styled.div`
    width: calc(100% / 3);
    padding: 0 15px 0 15px;
    cursor: pointer;
    &:hover {
        svg path { fill: ${styles.colors.primary600}};
        .external-videos-section-title { color: ${styles.colors.primary600}};
    } 
`
export default HomeSection

