import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { styles } from "../styles/themes/style";
import Text from "../components/Text";
import Column from "../components/Column";
import Row from "../components/Row";
import { useAuthStore } from "../stores/auth/useAuthStore";
import moment from "moment";
import CustomModal from "../components/modal/CustomModal";
import { useAppStore } from "../stores/application/useAppStore";
import { useConnectionsStore } from "../stores/connections/useConnectionsStore";
import momentTimezone from "moment-timezone"

const ConnectionsOverviewModal: FunctionComponent = () => {
    const { store: { user: { lastSyncAt } } } = useAuthStore()
    const { store: { modal } } = useAppStore()
    const { store: { scheduledRemovalParameters }} = useConnectionsStore()
    const [removalStatus, setRemovalStatus] = useState<string>('');

    const connectionsTotal = modal["connections-total"]
    const connectionsImportedCsvTotal = modal["connections-imported-csv-total"]
    const connectionsUpdatedConnectedToLinkedin = modal["connections-updated-connected-to-linkedin"]
    const connectionsImportedLinkedinTotal = modal["connections-imported-linkedin-total"]
    const connectionsDisconnectedTotal = modal["connections-disconnected-total"]

    useEffect(() => {
        console.log('scheduledRemovalParameters');
        console.log(scheduledRemovalParameters);

        if(scheduledRemovalParameters.inProgress){
            if(scheduledRemovalParameters.dailyCount < scheduledRemovalParameters.dailyLimit){
                setRemovalStatus('Running');
            } else {
                const lastRemovedAt = momentTimezone.tz(scheduledRemovalParameters?.lastRemovedAt, "America/New_York");

                console.log('lastRemovedAt');
                console.log(moment(lastRemovedAt).format('MMM D, YYYY HH:mm'));
                
                let nextAllowedTime = lastRemovedAt.clone().set({ hour: 20, minute: 1, second: 0 });

                console.log('nextAllowedTime');
                console.log(moment(nextAllowedTime).format('MMM D, YYYY HH:mm'));

                if (lastRemovedAt.isAfter(nextAllowedTime)) {
                    nextAllowedTime = nextAllowedTime.add(1, 'day');
                }
                setRemovalStatus(`On pause, will resume at ${moment(nextAllowedTime).format('HH:mm')} EST (NYC)`);
            }
        } else {
            setRemovalStatus(`Inactive`);
        }
      }, [scheduledRemovalParameters])

    return (
        <CustomModal
            $center
            headingText={'Connections Overview'}
            closeButton={true}
            bodyPadding={'0 24px 0px 24px;'}
        >
            <OverviewModal>
                <Column gap="5px" spaceBetween>
                    <StyledColumnDark>
                        <Row spaceBetween>
                            <Text $bold>{`Total number of connections:`}</Text>
                            <Text $bold>{connectionsTotal}</Text>
                        </Row>
                    </StyledColumnDark>
                    <StyledColumn gap="12px">
                        <Row spaceBetween>
                            <Text>{`Synced connections:`}</Text>
                            <Text>{connectionsTotal - connectionsImportedLinkedinTotal}</Text>
                        </Row>
                        <Row spaceBetween>
                            <Text>{`Connections with updated data:`}</Text>
                            <Text>{connectionsUpdatedConnectedToLinkedin}</Text>
                        </Row>
                        <Row spaceBetween>
                            <Text>{`Last connections sync:`}</Text>
                            <Text>{lastSyncAt ? <> {moment(lastSyncAt).format('MMM D, YYYY')} at {moment(lastSyncAt).format('HH:mm')} </> : 'N/A'}</Text>
                        </Row>
                    </StyledColumn>
                    <StyledColumnDark>
                        <Row spaceBetween>
                            <Text $bold>{`Imported connections:`}</Text>
                            <Text $bold>{connectionsImportedLinkedinTotal + connectionsImportedCsvTotal}</Text>
                        </Row>
                    </StyledColumnDark>
                    <StyledColumn gap="12px">
                        <Row spaceBetween>
                            <Text>{`Sidebar imported connections:`}</Text>
                            <Text>{connectionsImportedLinkedinTotal}</Text>
                        </Row>
                        <Row spaceBetween>
                            <Text>{`CSV imported connections:`}</Text>
                            <Text>{connectionsImportedCsvTotal}</Text>
                        </Row>
                    </StyledColumn>
                    <StyledColumnDark>
                            <Row spaceBetween>
                                <Text $bold>{`Total removed connections`}</Text>
                                <Text $bold>{connectionsDisconnectedTotal}</Text>
                            </Row>
                        </StyledColumnDark>
                    <StyledColumn>
                            <Row spaceBetween>
                                <Row alignItems gap="8px">
                                    <Text>{`Scheduled connections for removal`}</Text>
                                </Row>
                                <Text>{scheduledRemovalParameters?.total}</Text>
                            </Row>
                        </StyledColumn>
                        <StyledColumn>
                            <Row spaceBetween>
                                    <Text>{`Last daily limit`}</Text>
                                    <Text>{scheduledRemovalParameters?.dailyLimit}</Text>
                            </Row>
                        </StyledColumn>
                        <StyledColumn>
                            <Row spaceBetween>
                                    <Text>{`Status`}</Text>
                                    <Text>{removalStatus}</Text>
                            </Row>
                        </StyledColumn>
                        <StyledColumn>
                            <Row spaceBetween>
                                <Text>{`Last removed connections`}</Text>
                                <Text>{scheduledRemovalParameters?.lastRemovedAt ? <>{moment(scheduledRemovalParameters?.lastRemovedAt).format('MMM D, YYYY')} at {moment(scheduledRemovalParameters?.lastRemovedAt).format('HH:mm')} </> : 'N/A'}</Text>
                            </Row>
                        </StyledColumn>
                </Column>
            </OverviewModal>
        </CustomModal>
    )
}

const OverviewModal = styled.div`
    border-radius: 5px;
`
const StyledColumn = styled(Column)`
    padding: 8px 16px;
    background: white;
    border-radius: 5px;
`
const StyledColumnDark = styled(Column)`
    padding: 8px 16px;
    border-radius: 5px;
    background-color: ${styles.colors.black100};
`
export default ConnectionsOverviewModal