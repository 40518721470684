import { FunctionComponent, useCallback, useEffect, useState } from 'react'

import styled from 'styled-components'

import Text from '../../../components/Text';
import { styles } from '../../../styles/themes/style';
import { useBillingStore } from '../../../stores/billing/useBillingStore';
import Tag from '../../../components/Tag';
import Button from '../../../components/Button';
import { IBillingPlanV2, IPrice } from '../../../interfaces/models/billingPlan';
import { useBillingHandlers } from '../../../services/actions/billing/useBillingHandlers';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../interfaces/store/appStore';
import { useExternalHandlers } from '../../../services/actions/navigation/useExternalHandlers';
import { formatCurrency, formatPrice, formatPriceTwoDecimals, oldBusinessPrices } from '../../../utils';
import ActionText from '../../../components/ActionText';
import { calculateSaveAmount } from './CycleToggle';
import { useAuthStore } from '../../../stores/auth/useAuthStore';
import SvgFragment from '../../../fragments/SvgFragment';

interface IBillingPlansStyles {
    $backgroundColor?: boolean,
    $border?: boolean,
    $disabled?: boolean
}

interface IBillingPlansProps extends IBillingPlansStyles {
    currency: string,
    selectedCycle: string,
    setShowCompareFeatures?: (e: any) => void
}

const BillingPlans: FunctionComponent<IBillingPlansProps> = ({ selectedCycle, currency, setShowCompareFeatures }) => {
    const { store: { billingPlans } } = useBillingStore()
    const { store: { subscription, trial }} = useAuthStore()
    const { setSelectedPlanAndPriceHandler } = useBillingHandlers()
    const navigate = useNavigate()
    const { openDemoProduct } = useExternalHandlers()

    const enterpriseFeaturesList = [
        {
            "value": "For large organisations"
        },
        {
          "value": "Everything in Business plan and:"
        },
        {
          "value": "Add over 100+ users"
        },
        {
          "value": "Dedicated customer success team"
        },
        {
          "value": "Custom onboarding & team training"
        },
        {
          "value": "Product advisory board seat"
        },
        {
          "value": "SSO, SAML & OIDC"
        },
        {
          "value": "Custom integrations"
        }
    ]
    const cantSelectSame = subscription?.isValid && !trial && !(subscription?.cancelDate && subscription?.isValid)

    let userDefinedPrice: IPrice | undefined
    const selectedUserBillingPlan = billingPlans.find((plan: IBillingPlanV2) => {
        userDefinedPrice = plan.prices.find((price: IPrice) => price.priceId === subscription.priceId && price.productId === subscription.productId)
        if(userDefinedPrice) return true
    })
    //Old prices, feautre compatablility
    const selectedUserOldBillingPlan = oldBusinessPrices().includes(subscription.priceId) 
        ? {name: 'Business'} 
        : undefined;
    const setSelectedPlanAndPrice = useCallback((plan: IBillingPlanV2) => {
        if(cantSelectSame && selectedUserBillingPlan && userDefinedPrice && userDefinedPrice?.currency === currency && userDefinedPrice?.interval === selectedCycle && selectedUserBillingPlan?._id === plan?._id){
            return true
        }
        const changeSeats = cantSelectSame && selectedUserBillingPlan && userDefinedPrice && userDefinedPrice?.currency === currency && userDefinedPrice?.interval !== selectedCycle && selectedUserBillingPlan?._id === plan?._id

        const selectedPrice = setSelectedPlanAndPriceHandler(plan, selectedCycle, currency)
        navigate(AppRoutes.BILLING_PLAN, { state: { changeSeats, preSelectedBillingPlan: plan, preSelectedPrice: selectedPrice }})
        
    }, [cantSelectSame, selectedCycle, currency, userDefinedPrice, selectedUserBillingPlan])

    const billingPlansWithSaveAmount = billingPlans.map((product: any) => {
        const monthlyPrice = product.prices.find((price: any) => price.interval === 'month' && price.currency === currency)
        const annualPrice = product.prices.find((price: any) => price.interval === 'year' && price.currency === currency)
        
        const save = calculateSaveAmount(monthlyPrice, annualPrice)

        const updatedPrices = product.prices.map((price: any) => {
            if (price.interval === 'year' && save > 0) {
                return {
                    ...price,
                    save: `2 months off`
                };
            }
            return price;
        })

        return { 
            ...product,
            prices: updatedPrices
        }
    })

    return (
        <Container>
            {billingPlansWithSaveAmount.map((plan: any, index: number) => {
                const selectedPrice = plan.prices.filter((price: any) => price.interval === selectedCycle && price.currency === currency)

                if (selectedPrice.length) {
                    const price = selectedPrice?.[0]?.interval === 'year' ? selectedPrice?.[0]?.price / 12 : selectedPrice?.[0]?.price
                    const [integerPart, decimalPart] = formatPriceTwoDecimals(price).toString().split('.')
                    const [additionalLicenceIntegerPart, additionalLicencedecimalPart] = formatPrice(selectedPrice?.[0]?.additionalLicence).toString().split('.')
                    const isCurrentPlan = cantSelectSame && selectedUserBillingPlan && userDefinedPrice && userDefinedPrice?.currency === selectedPrice?.[0]?.currency && userDefinedPrice?.interval === selectedPrice?.[0]?.interval && selectedUserBillingPlan?._id === plan?._id
                    const isDisabledBusinessStarter = subscription?.status !== 'canceled' && !trial && (selectedUserBillingPlan?.name === 'Business' || selectedUserOldBillingPlan?.name === 'Business') && plan?.name === 'Starter'
                    return (
                        <div key={index}>
                            <AdvancedColaborationCard $backgroundColor={plan?.name === 'Business'}>
                                {plan?.name === 'Business' ? <Text $bold>Best for advanced collaboration</Text> : null}
                            </AdvancedColaborationCard>
                            <PlanCard $disabled ={isDisabledBusinessStarter} $border={plan?.name === 'Business'}>
                                <TitleSection>
                                    <Text $bold $black $heading5>{plan?.name}</Text>
                                    {plan?.isPopular && <Tag $backgroundColor={styles.colors.yellow600} $borderColor={styles.colors.black600} $color={styles.colors.yellow600} SVGtype='popularStar' title={'Most popular'} />}
                                </TitleSection>
                                <Description><Text $black>{plan?.description}</Text></Description>
                                <Divider />
                                <PriceSection>
                                    <PriceWrapper>
                                        <Text $bold $heading1 $black> {formatCurrency(selectedPrice?.[0]?.currency)}{integerPart} </Text>
                                        {decimalPart !== '00' && <Text $bold $heading5 $black>{`.${decimalPart}`}</Text>}
                                        <FractionalPart>
                                            {selectedPrice?.[0].save && <Tag $color={styles.colors.green600} title={selectedPrice?.[0].save} />}
                                            <Text $paragraph3 $lighter>{`month ${plan.name === 'Starter' || plan.name === 'Pro' ? '/user' : plan.name === 'Business' ? '/team' : ''}`}</Text>
                                        </FractionalPart>
                                    </PriceWrapper>
                                    <Text $black>{ plan.name === 'Business' ? 'One price for 2 to 100 users per workspace' : '\u00A0'}</Text>
                                </PriceSection>
                                <Button 
                                    $type='blue' 
                                    $customDisabled={isCurrentPlan || isDisabledBusinessStarter} 
                                    disabled={isCurrentPlan || isDisabledBusinessStarter} 
                                    onClickHandler={() => setSelectedPlanAndPrice(plan)}>{isCurrentPlan ? 'Your plan' : 'Buy Now'}</Button>
                                <FeatureList>
                                    {plan.featuresList.map((plan: any, index: number) => (
                                        <Feature key={index}>
                                            {index === 0 && <Text $black $bold>{plan.value}</Text>}
                                            {index !== 0 && <>
                                            <StyledSvg primaryColor><SvgFragment type="checkmark" /></StyledSvg>
                                            <Text $black>{plan.value}</Text></>
                                            }
                                        </Feature>
                                    ))}
                                </FeatureList>
                                <StyledButton>
                                    <ActionText $disabled={isDisabledBusinessStarter} onClickHandler={() => setShowCompareFeatures && setShowCompareFeatures(true)}>Compare all features</ActionText>
                                </StyledButton>
                            </PlanCard>
                        </div>
                    )
                }
            })}
            {/* Hardcoded Enterprise plan */}
            <div>
                <AdvancedColaborationCard $backgroundColor={false} />
                <PlanCard>
                    <TitleSection>
                        <Text $bold $black $heading5>{'Enterprise'}</Text>
                        {false && <Tag $backgroundColor={styles.colors.yellow600} $borderColor={styles.colors.black600} $color={styles.colors.yellow600} SVGtype='popularStar' title={'Most popular'} />}
                    </TitleSection>
                    <Description><Text $black>{'Ideal for large organizations to unify their network and collaborate seamlessly across global offices.'}</Text></Description>
                    <Divider />
                    <div style={{
                        height: '64px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: '4px'
                    }}>
                        <Text $heading4>{'Custom pricing'}</Text>
                    </div>
                    <Button $type='blue' onClickHandler={() => openDemoProduct()}>Contact sales</Button>
                    <FeatureList>
                        {enterpriseFeaturesList.map((plan: any, index: number) => (
                            <Feature key={index}>
                                 {index === 0 && <Text $black $bold>{plan.value}</Text>}
                                 {index !== 0 && <>
                                 <StyledSvg primaryColor><SvgFragment type="checkmark" /></StyledSvg>
                                <Text $black>{plan.value}</Text></>
                                }
                            </Feature>))}
                    </FeatureList>
                    <StyledButton>
                        <ActionText onClickHandler={() => setShowCompareFeatures && setShowCompareFeatures(true)}>Compare all features</ActionText>
                    </StyledButton>
                </PlanCard>
            </div>
        </Container>
    )
}

const Container = styled.section<IBillingPlansStyles>`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
`
const AdvancedColaborationCard = styled.div<IBillingPlansStyles>`
    width: 340px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px 4px 0 0;
    color: ${styles.colors.black600};
    ${({ $backgroundColor }) => $backgroundColor ? `background-color: ${styles.colors.yellow600};` : null}
`
const PlanCard = styled.div<IBillingPlansStyles>`
    width: 340px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    background-color: ${styles.colors.black100};
    border: 2px solid ${styles.colors.black100};
    ${({ $border }) => $border ? `
        border: 2px solid ${styles.colors.yellow600};
        border-radius: 0 0 4px 4px;
    ` : `
        border-radius: 4px;
    `}

    &:hover {
        box-shadow: 0px 0px 12px 0px rgba(51, 57, 81, 0.10);
        border: 2px solid ${styles.colors.primary600};
    }
    ${({ $disabled }) => $disabled ? `
            pointer-events: 'none';
            opacity: 0.6;
    ` : null
    }

    `
const TitleSection = styled.section`
    display: flex;
    justify-content: space-between;
`
const PriceSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 4px;
`
const PriceWrapper = styled.div`
    display: flex;
`
const FractionalPart = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 5px;
    justify-content: flex-end;
`
const Feature = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`
const FeatureList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`
const Divider = styled.div`
    height: 1px;
    background: ${styles.colors.black200};
`
const Description = styled.div`
    height: 80px;
`
const StyledButton = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`
const StyledSvg = styled.div<{primaryColor?: boolean}>`

  .svg{
    width: 20px;
    height: 20px;

    ${({primaryColor}) => primaryColor && `
      & path { fill: ${styles.colors.primary600} }
    `}
  }
`
export default BillingPlans