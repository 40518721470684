import { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { styles } from '../../../../styles/themes/style'

import Text from '../../../../components/Text'
import SvgFragment from '../../../../fragments/SvgFragment'
import { useAuthHandlers } from '../../../../services/actions/auth/useAuthHandlers'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'

interface IDemoVideoStyles {
    show?: boolean
}

interface IDemoVideoProps {
    onClick?: (e: any) => void
}

const DemoVideo: FunctionComponent<IDemoVideoProps> = ({ onClick }) => {
    const { saveUserPreferencesHandler } = useAuthHandlers()
    const { showInfoToast } = useAppHandlers()

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setTimeout(() => setVisible(true), 1000)
    }, [])

    const videoSrc = "https://www.loom.com/embed/e8fdd0aef606407a984379515b119789?sid=44f3f1e6-2217-42a2-9948-a94cee931e1f?autoplay=true&hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" 

    const onClickHandler = () => {
        setVisible(false)
        setTimeout(() => {
            onClick && onClick(true)
            saveUserPreferencesHandler({hideDemoVideo: true})
            showInfoToast({message: 'You can still watch the demo video anytime on your LeadDelta home page'})
        }, 500) // Match the transition duration
    }

    return (
        <Container show={visible}>
        <Wrapper>
            <Header>
                <Text $heading3>Watch a quick demo video</Text>
                <StyledSvgFragmentWrapper onClick={onClickHandler}>
                    <SvgFragment type='close' />
                </StyledSvgFragmentWrapper>
            </Header>
            <StyledText>Explore how to simplify connection management, organize your network, and build stronger professional relationships.</StyledText>
            <StyledDemoVideoFrame src={videoSrc} allowFullScreen frameBorder="0" />
        </Wrapper>
    </Container>
    )
}

const Container = styled.div<IDemoVideoStyles>`
    position: absolute;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    width: 100%;
    height: 100%;
    background-color: ${styles.colors.black600}B3;
    z-index: 1;
    top: 0px;

    opacity: ${({show}) => (show ? 1 : 0)};
    transition: opacity 0.5s ease-in-out;
`
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 690px;
    height: 520px;
    background-color: white;
    padding: 24px;
    border-radius: 4px;
`
const Header = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
`
const StyledSvgFragmentWrapper = styled.div`
    cursor: pointer;
    width: 34px;
    height: 34px;
    position: absolute;
    right: 0;
`
const StyledText = styled(Text)`
    text-align: center;
`
const StyledDemoVideoFrame = styled.iframe<IDemoVideoStyles>`
    width: 640px;
    height: 360px;
    margin-top: 16px;
`

export default DemoVideo