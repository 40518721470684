import { useAxios } from "../useAxios"
import { useAuthStore } from "../../../stores/auth/useAuthStore"
import { ICreateNote } from "../../actions/notes/useNotesHandlers"

export interface INoteQueryParams {

}

export const useNoteRequest = () => {
    const { sendRequest } = useAxios()
    const { store: { workspace} } = useAuthStore()

    const route = '/workspaces'

    const api = '/669a367f6b79fbd3de532b9e/notes?usersTaggedCondition=or&&page=1&pageSize=50&sort=newest&userCondition=or&total=true'

    const getNotes = (params: string) => {
        return sendRequest({
            slug: 'getNotes',
            url: `${route}/${workspace._id}/notes?${params}`,
            method: 'GET'
        })
    }

    const getSingleNote = (noteId: string) => {
        return sendRequest({
            slug: 'getSingleNote',
            url: `${route}/${workspace._id}/notes/${noteId}`,
            method: 'GET'
        })
    }

    const createNote = (note: ICreateNote) => {
        return sendRequest({
            slug: 'createNote',
            url: `${route}/${workspace._id}/notes`,
            method: 'POST',
            payload: note
        })
    }

    const updateNoteAccess = (payload: ICreateNote) => {
        return sendRequest({
            slug: 'updateNoteAccess',
            url: `${route}/${workspace._id}/notes`,
            method: 'PUT',
            payload,
            all: true
        })
    }

    const updateNote = (noteId: string, note: ICreateNote) => {
        return sendRequest({
            slug: 'updateNote',
            url: `${route}/${workspace._id}/notes/${noteId}`,
            method: 'PATCH',
            payload: note
        })
    }

    const deleteNote = (noteId: string) => {
        return sendRequest({
            slug: 'deleteNote',
            url: `${route}/${workspace._id}/notes/${noteId}`,
            method: 'DELETE',
            all: true
        })
    }

    const deleteNotes = async (ids: string[]) => {
        return sendRequest({
            slug: 'deleteNotes',
            url: `${route}/${workspace._id}/notes/bulk/delete`,
            method: 'DELETE',
            payload: {notes: ids},
            all: true
        });
    }

    return {
        getNotes,
        getSingleNote,
        createNote,
        updateNoteAccess,
        updateNote,
        deleteNote,
        deleteNotes
    }
}