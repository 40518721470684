import { FunctionComponent, useCallback } from "react";
import SvgFragment from "../../../../fragments/SvgFragment";
import Text from "../../../../components/Text";
import EmptyPage from "../../../../components/EmptyPage";
import Column from "../../../../components/Column";
import styled from "styled-components";
import Row from "../../../../components/Row";
import { styles } from "../../../../styles/themes/style";
import Button from "../../../../components/Button";
import { useExternalHandlers } from "../../../../services/actions/navigation/useExternalHandlers";
import { useAppStore } from "../../../../stores/application/useAppStore";
import { useAuthStore } from "../../../../stores/auth/useAuthStore";
import { useAppHandlers } from "../../../../services/actions/app/useAppHandlers";
import { useAuthHandlers } from "../../../../services/actions/auth/useAuthHandlers";
import { useInterval } from "../../../../wrappers/IntervalContext";
import { Flex, Progress } from 'antd';

interface IConnectionTableEmptyPageProps {
    props: 'noExtensionInstalled' | 'workspaceNotSynced' | 'syncConnectionsInProgress' | 'syncConnectionsDone' | 'default'
}

const ConnectionTableEmptyPage: FunctionComponent<IConnectionTableEmptyPageProps> = ({ props }) => {
    const { visitChromeStore  } = useExternalHandlers()
    const { store: { extensionInstalled, syncCompletionPercentage } } = useAppStore()
    const { store: { user, workspace, authData } } = useAuthStore()

    const { setSyncConnectionsModalHandler } = useAppHandlers()
    const { syncConnectionsHandler, getUserHandler, syncConversationsHandler, getMeHandler } = useAuthHandlers()
    const { startInterval } = useInterval();
    
    const noResultTitle = "No Connections To Show";
    const noResultDescription = "Install Chrome Extension, Sync Connections, or wait for the Sync to complete. You can also import Connections via CSV import.";

    const setLastSyncAt = useCallback(async() => {
        if(extensionInstalled) setSyncConnectionsModalHandler((user) => { 
            syncConnectionsHandler(user, false, authData); 
            if(!user?.isSynced) {
                getMeHandler()
                startInterval(async () => getUserHandler(), 300000)
            }
        })
    }, [user, extensionInstalled, workspace, authData])

    const noExtensionInstalled = 
    <Column alignItems gap="10px">
            <Text $heading3>Download LeadDelta extension</Text>
            <Text $lighter $paragraph4>Sync your LinkedIn connections continuously, manage your inbox and a lot more.</Text>
            <StyledRow gap="24px">
                <Box alignItems gap="5px">
                    <SvgFragment type="syncConnections" />
                    <Text $heading3>Auto-sync</Text>
                    <Text $lighter $paragraph4>Keep your LinkedIn connections up to date.</Text>
                </Box>
                <Box alignItems gap="5px">
                    <SvgFragment type="clockBlue" />
                    <Text $heading3>Save time</Text>
                    <Text $lighter $paragraph4>No need to export/import your LinkedIn files.</Text>
                </Box>
                <Box alignItems gap="5px">
                    <SvgFragment type="checkmarkBlue" />
                    <Text $heading3>Set and forget</Text>
                    <Text $lighter $paragraph4>Simple one-time setup let’s you set and forget.</Text>
                </Box>
            </StyledRow>
            <Button $type="blue" $customWidth tooltipMessage='' onClickHandler={() => visitChromeStore()}>Go to Chrome Web Store</Button>
    </Column>

    const workspaceNotSynced = 
    <Column gap="24px" alignItems>
        <Oval><SvgFragment type="syncConnections" /></Oval>
        <StyledColumn gap="8px" alignItems>
            <Text $heading3>Sync your LinkedIn connections</Text>
            <Text $lighter $paragraph4>Sync your LinkedIn connections for the first time, manage your inbox and a lot more. After this initial set up LeadDelta will daily auto-sync. Set it and forget it.</Text>
        </StyledColumn>
        <Button $type="blue" $bigButton tooltipMessage='' onClickHandler={() => setLastSyncAt()}>Sync Connections</Button>
    </Column>

    const syncConnectionsInProgress = 
    <Column gap="24px" alignItems>
        <Progress percent={syncCompletionPercentage} size="small" />
        <StyledColumn gap="8px" alignItems>
            <Text $heading3>Syncing connections</Text>
            <Text $lighter $paragraph4>Please hold on while we complete the sync of your LinkedIn connections. This may take a few moments.</Text>
        </StyledColumn>
    </Column>

    const syncConnectionsDone = 
    <Column gap="24px" alignItems>
        <Oval green><SvgFragment type="checkmarkBig" /></Oval>
        <StyledColumn gap="8px" alignItems>
            <Text $heading3>Syncing connections finished</Text>
            <Text $lighter $paragraph4>You can now refresh your page to see the connections.</Text>
        </StyledColumn>
        <Button $type="blue" $bigButton tooltipMessage='' onClickHandler={() => window.location.reload()}>Refresh Your Page</Button>
    </Column>

    const contentMap = {
        noExtensionInstalled,
        workspaceNotSynced,
        syncConnectionsInProgress,
        syncConnectionsDone,
        default: <EmptyPage svgType='templateEmpty' title={noResultTitle} label={noResultDescription} />
    };

    return <>{contentMap[props]}</>;
};
const StyledRow = styled(Row)`
    margin: 24px 0;
`
const Box = styled(Column)`
    width: 245px;
    padding: 24px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid ${styles.colors.black200};
    background: ${styles.colors.black100};
`
const StyledColumn = styled(Column)`
    width: 360px;
    text-align: center;
`
const Oval = styled.div<{ green?: boolean }>`
    padding: 8px;
    background-color: #FFF;
    filter: drop-shadow(0px 0px 12px rgba(51, 57, 81, 0.10));
    border-radius: 50%;

    ${({ green }) => green && `
        & svg path { fill: ${styles.colors.green600} }
    `}
`
export default ConnectionTableEmptyPage