import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { styles } from "../styles/themes/style";
import Text from "../components/Text";
import Column from "../components/Column";
import Row from "../components/Row";
import moment from "moment";
import CustomModal from "../components/modal/CustomModal";
import { useConnectionsStore } from "../stores/connections/useConnectionsStore";
import ActionText from "../components/ActionText";
import momentTimezone from "moment-timezone"
import { useAnalyticsHandlers } from "../services/actions/analytics/useAnalyticsHandlers";
import LoadingSpinner from "../components/LoadingSpinner";
import TooltipWrapper from "../components/TooltipWrapper";
import SvgFragment from "./SvgFragment";

const RemovalOverviewModal: FunctionComponent = () => {
    const [removalStatus, setRemovalStatus] = useState<string>('');
    const [nextAllowedTime, setNextAllowedTime] = useState<string>('');
    const [ analytics, setAnalytics ] = useState<any>({
       // "connections-disconnected-total": 0
    })

    const { getTotalConnectionsOverviewAnalytics } = useAnalyticsHandlers()
    const { store: { scheduledRemovalParameters }} = useConnectionsStore()

    const dailyLimitInfo = 'Trial users can remove 25 connections total. On paid plans, LeadDelta removes 2–80 daily, depending on LinkedIn API limits. Your computer must be on, with a stable internet connection, and you must be logged into LinkedIn and LeadDelta with the extension installed.';
    const guidelinesURL = 'https://help.leaddelta.com/en/article/troubleshooting-connection-removal-1c3wc7i/?bust=1739825153544';
    const statusInfo = `Removal will resume at ${nextAllowedTime} EST (NYC). If this time has passed, please refresh your browser or log in again.`; 

    useEffect(() => {
        getTotalConnectionsOverviewAnalyticsHandler();

        if(scheduledRemovalParameters.inProgress){
            if(scheduledRemovalParameters.dailyCount < scheduledRemovalParameters.dailyLimit){
                setRemovalStatus('Running');
            } else {
                const lastRemovedAt = momentTimezone.tz(scheduledRemovalParameters?.lastRemovedAt, "America/New_York");
                let nextAllowedTime = lastRemovedAt.clone().set({ hour: 20, minute: 1, second: 0 });
                if (lastRemovedAt.isAfter(nextAllowedTime)) {
                    nextAllowedTime = nextAllowedTime.add(1, 'day');
                }
                setRemovalStatus(`On pause`);
                setNextAllowedTime(moment(nextAllowedTime).format('HH:mm'));
            }
        } else {
            setRemovalStatus(`Inactive`);
        }
      }, [scheduledRemovalParameters])
    
    const getTotalConnectionsOverviewAnalyticsHandler = useCallback( async () => {
            const statistics = await getTotalConnectionsOverviewAnalytics();
            let statisticsObject = statistics.reduce((accumulator, value) => ({...accumulator, ...{[value.version]: value.value}}), {});
            setAnalytics({...analytics, ...statisticsObject})
    }, [])

    return (
        <CustomModal
            $center
            headingText={'Connections Removal Overview'}
            closeButton={true}
            bodyPadding={'0 24px 0px 24px;'}
        >
            <OverviewModal>
                <Column gap="0px" spaceBetween>
                    <StyledColumnDark>
                        <Row spaceBetween>
                            <Text $bold>{`Total removed connections`}</Text>
                            <Text $bold>{analytics["connections-disconnected-total"]}</Text>
                        </Row>
                    </StyledColumnDark>
                    <StyledColumn>
                        <Row spaceBetween>
                            <Row alignItems gap="8px">
                                {scheduledRemovalParameters?.inProgress && <LoadingSpinner />}
                                <Text>{`Scheduled connections for removal`}</Text>
                            </Row>
                            <Text>{scheduledRemovalParameters?.total}</Text>
                        </Row>
                    </StyledColumn>
                    <StyledColumn>
                        <Row spaceBetween>
                                <Text>{`Last daily limit`}
                                    <TooltipWrapper tooltipText={dailyLimitInfo} textAlignment={'left'}>
                                        <StyledSvg>
                                            <SvgFragment type="info"/>
                                        </StyledSvg>
                                    </TooltipWrapper>
                                </Text>
                                <Text>{scheduledRemovalParameters?.dailyLimit}</Text>
                        </Row>
                    </StyledColumn>
                    <StyledColumn>
                        <Row spaceBetween>
                                <Text>{`Status`}
                                    {removalStatus === 'On pause' && <TooltipWrapper tooltipText={statusInfo} textAlignment={'left'}>
                                        <StyledSvg>
                                            <SvgFragment type="info"/>
                                        </StyledSvg>
                                    </TooltipWrapper>}
                                </Text>
                                <Text>{removalStatus}</Text>
                        </Row>
                    </StyledColumn>
                    <StyledColumn>
                        <Row spaceBetween>
                            <Text>{`Last removed connections`}</Text>
                            <Text>{scheduledRemovalParameters?.lastRemovedAt ? <>{moment(scheduledRemovalParameters?.lastRemovedAt).format('MMM D, YYYY')} at {moment(scheduledRemovalParameters?.lastRemovedAt).format('HH:mm')} </> : 'N/A'}</Text>
                        </Row>
                    </StyledColumn>
                    <StyledColumnInfo gap="12px">
                        <Column>
                            <Text $paragraph3>{`Remember - in order to successfully auto-remove connections:`}</Text>
                            <Text $paragraph3>{"• Your computer must be turned on"}</Text>
                            <Text $paragraph3>{`• With stable internet connection and`}</Text>
                            <Text $paragraph3>{`• You must be logged to your LinkedIn + LeadDelta.`}</Text>
                            <StyledText>
                                <Text $paragraph3>{`Learn more `}
                                    <ActionText $paragraph3 onClickHandler={() => window.open(`${guidelinesURL}`, '_blank')}>here.</ActionText>
                                </Text>
                            </StyledText>
                        </Column>
                    </StyledColumnInfo>
                </Column>
            </OverviewModal>
        </CustomModal>
    )
}

const OverviewModal = styled.div`
    border-radius: 5px;
`
const StyledColumnDark = styled(Column)`
    padding: 8px 16px;
    border-radius: 5px;
    background-color: ${styles.colors.black100};
`
const StyledColumn = styled(Column)`
    padding: 8px 16px;
    background: white;
    border-radius: 5px;
`
const StyledColumnInfo = styled(Column)`
    margin-top: 8px;
    padding: 8px 16px;
    background-color: ${styles.colors.yellow300};
    border-radius: 5px;
`
const StyledText = styled(Text)`
  display: inline-block;
  text-align: left;
  margin-top: 20px;
`
const StyledSvg = styled.div`
  &:hover {
    cursor: pointer;
  }
  & svg{
    width: 20px;
    height: 20px;
    & path { 
        fill: ${styles.colors.black300}
    }
    & path:hover { 
        fill: ${styles.colors.primary600}
    }
  }
`;
export default RemovalOverviewModal