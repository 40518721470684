import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import Row from "../../../../../components/Row";
import { useAuthStore } from "../../../../../stores/auth/useAuthStore";
import Text from "../../../../../components/Text";
import Column from "../../../../../components/Column";
import Button from "../../../../../components/Button";
import { styles } from "../../../../../styles/themes/style";
import ProfilePhoto from "../../../../../components/ProfilePhoto";
import { useAuthHandlers } from "../../../../../services/actions/auth/useAuthHandlers";
import ActionText from "../../../../../components/ActionText";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../../interfaces/store/appStore";

interface IAccountOverviewProps {}

const AccountOverview: FunctionComponent<IAccountOverviewProps> = () => {
  const {
    store: { user },
  } = useAuthStore();
  const [disableDelete, setDisableDelete] = useState<boolean>(true);
  const { canDeleteUserAccountHandler } = useAuthHandlers();
  const navigate = useNavigate();

  const helpUrl =
    "https://help.leaddelta.com/en/article/how-to-delete-my-leaddelta-account-vl3mg0/";

  const canDeleteUserAccount = async () => {
    const canDeleteAccount = await canDeleteUserAccountHandler();
    setDisableDelete(!canDeleteAccount);
  };

  useEffect(() => {
    canDeleteUserAccount();
  }, []);

  return (
    <Container>
      <StyledRow spaceBetween>
        <StyledColumn>
          <StyledContainer>
            <ProfilePhoto
              width="40px"
              height="40px"
              tooltipMessage={`${user?.linkedinData?.firstName} ${user?.linkedinData?.lastName}`}
              capitalLetters={`${user?.linkedinData?.firstName
                ?.charAt(0)
                .toUpperCase()}${user?.linkedinData?.lastName
                ?.charAt(0)
                .toUpperCase()}`}
            />
            <Column>
              <Text $bold $black>
                {user?.linkedinData?.firstName +
                  " " +
                  user?.linkedinData?.lastName}
              </Text>
              <Label>{user?.email}</Label>
            </Column>
          </StyledContainer>
        </StyledColumn>
      </StyledRow>
      <Divider />
      <StyledRow spaceBetween>
        <StyledColumn gap="16px">
          <Text $bold $black $paragraph1>
            Delete your LeadDelta account
          </Text>
          <StyledText>
            <b>Important note:</b> This action cannot be undone. All
            connections, data and actions will be permanently deleted, in this
            and all other workspaces (if you have any). If the “Delete Account”
            is disabled please read the following{" "}
            <StyledActionText
              onClickHandler={() => window.open(`${helpUrl}`, "_blank")}
            >
              help article.
            </StyledActionText>
          </StyledText>
        </StyledColumn>
        <StyledColumn>
          <StyledRow $direction={"row-reverse"}>
            <Button
              $type="dark-red"
              $bigButton
              disabled={disableDelete}
              onClickHandler={() =>
                navigate(AppRoutes.DELETE_USER_CONFIRMATION)
              }
            >
              Delete Account
            </Button>
          </StyledRow>
        </StyledColumn>
      </StyledRow>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow-y: auto;
  height: 100%;

  @media (max-width: 786px) {
    padding: 0px;
    align-items: start;
  }
`;
const StyledRow = styled(Row)<{ $direction?: string }>`
  width: 100%;
  ${({ $direction }) => $direction && `flex-direction: ${$direction};`}
`;
const StyledColumn = styled(Column)`
  width: 100%;
`;
const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;
const Label = styled(Text)`
  color: ${styles.colors.black400};
  font-size: 14px;
  line-height: 20px;
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgb(218, 224, 232);
`;
const StyledText = styled(Text)`
  display: inline-block;
  text-align: left;
  font-weight: normal;
  font-size: 14px;
`;
const StyledActionText = styled(ActionText)`
  display: inline-block;
  & span {
    font-size: 14px;
  }
`;
export default AccountOverview;
