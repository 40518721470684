import { useAxios } from "../useAxios"


export const useNotificationsRequest = () => {
    const { sendRequest } = useAxios()

    const route = '/notifications'

    const getNotifications = () => {
        return sendRequest({
            slug: 'getNotifications',
            url: `${route}`,
            method: 'GET'
        })
    };

    const markAsRead = (payload: any) => {
        return sendRequest({
            slug: 'markAsRead',
            url: `${route}/mark-as-read`,
            method: 'PATCH',
            displayToast: false,
            payload
        })
    };

    return {
        getNotifications,
        markAsRead
    };
}