import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import Table from '../../../../components/Table'
import Checkbox from '../../../../components/Checkbox'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import { AppRoutes, SidebarTypes } from '../../../../interfaces/store/appStore'
import { useTagsStore } from '../../../../stores/tags/useTagsStore'
import { ITag } from '../../../../interfaces/models/tag'
import moment from 'moment'
import ColorSelectDropdownFragment from '../../../../fragments/ColorSelectDropdownFragment'
import { styles } from '../../../../styles/themes/style'
import { useTagsHandlers } from '../../../../services/actions/tags/useTagsHandlers'
import EditDeleteActionDropdown from '../../../../fragments/dropdowns/EditDeleteActionDropdown'
import { adjustedColumnsHandler, capitalLetters } from '../../../../utils'
import { useAppStore } from '../../../../stores/application/useAppStore'
import ActionText from '../../../../components/ActionText'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Text from '../../../../components/Text'
import EmptyPage from '../../../../components/EmptyPage'
import Column from '../../../../components/Column'
import ProfilePhoto from '../../../../components/ProfilePhoto'
import useMediaQuery from '../../../../components/useMediaQuery'
import { useAuthStore } from '../../../../stores/auth/useAuthStore'
import SelectAllCheckbox from '../../../../components/SelectAllCheckbox'

interface ITagTableProps {
    controlBarHeight?: number;
    isAllSelectedProps?: {
        isAllSelected: boolean
        setIsAllSelected: (e: any) => void
    }
    selectedTags: any[]
    selectedTagsChange: (tags: any[]) => void;
}

const TagTable: FunctionComponent<ITagTableProps> = ({ isAllSelectedProps, controlBarHeight, selectedTags, selectedTagsChange }) => {
    const location = useLocation()
    const tableRef = useRef<HTMLDivElement>(null)
    const isSmall = useMediaQuery("(max-width: 768px)")
    const isMedium = useMediaQuery("(max-width: 1240px)")
    const { store: { tags, tagsParameters, tagsFilter } } = useTagsStore()
    const navigate = useNavigate()
    const { openSidebar, setCustomColorModalHandler } = useAppHandlers()
    const { editTagHandler, deleteTagHandler, setTagsFilterHandler } = useTagsHandlers()
    const { store: { requestLoading }} = useAppStore()
    const [ tagsLoading, setTagsLoading ] = useState<boolean>(true)
    const { selectedUsersMutual } = location.state || {}
    const { store: { user, workspaceOwner }} = useAuthStore()

    const { isAllSelected, setIsAllSelected  } = isAllSelectedProps || {}
    const { pageSize, total } = tagsParameters

    const noResultTitle = "No Tags to Show";
    const noResultDescription = "Create and edit tags, keep them to yourself, or share them with your workspace members.";

    useEffect(() => {
        if('getTagsPaginate' in requestLoading) setTagsLoading(requestLoading?.['getTagsPaginate'])
    }, [requestLoading]);

    useEffect(() => {
        // Filtering passed as location state
        if (selectedUsersMutual) {
            setTagsFilterHandler({
                selectedUsersMutual,
                searchByName: '',
                usersMutualCondition: 'And',
                tagAccessType: { value: 'all', label: 'All' },
                sort: '',
                selectedTags: []
            })
        }
    }, []);

    const sortHandler = (props: any) => {
        const adjustedColumns = adjustedColumnsHandler(columnsInitialState, columns, tableRef, isSmall, isMedium, sortHandler)
        const selectedColumnIndex = columns.findIndex(column => column.key === props.key)
        if (selectedColumnIndex !== -1) {

            props.sort = (props.sort === '' || props.sort === 'za') ? 'az' : 'za'
            adjustedColumns[selectedColumnIndex] = props

            setColumns(adjustedColumns)

            setTagsFilterHandler({...tagsFilter, sort: getSortParams(props.header, props.sort) })
        }
    }

    const getSortParams = (header: string, sort: string): string => {
        switch (header) {
            case 'Tag name': return `name.${sort}`;
            default: return ''
        }
    }

    const selectAllCheckboxHandler = () => {
        const selectedTagsIds = !isAllSelected ? (workspaceOwner ? tags.map((tag: any) => tag._id) : tags.filter((tag: any) => tag?.users && tag?.users[0] && tag?.users[0]?._id === user._id).map((tag: any) => tag._id)) : []
        selectedTagsChange(selectedTagsIds)
        setIsAllSelected && setIsAllSelected(!isAllSelected)
    }

    const selectCheckboxHandler = (e: React.MouseEvent<HTMLElement>, id: any) => {
        e.stopPropagation()
        const index = selectedTags.indexOf(id)
        if (index !== -1) {
            const updatedIds = selectedTags.filter((tagId: any) => tagId !== id);
            selectedTagsChange(updatedIds);
        } else {
            const updatedIds = [...selectedTags, id];
            selectedTagsChange(updatedIds);
        }
    }

    const selectOnThisPageHadler = () => {
        const filteredTags = workspaceOwner ? tags.map((tag: any) => tag._id) : tags.filter((tag: any) => tag?.users && tag?.users[0] && tag?.users[0]?._id === user._id).map((tag: any) => tag._id)        
        selectedTagsChange([...filteredTags]);
    }

    const clearHandler = () => {
        selectedTagsChange([]);
        setIsAllSelected && setIsAllSelected(false)
    }

    const onEditColorHandler = useCallback(async (tag: ITag, newColor: string) => {
        await editTagHandler(tag._id, { title: tag.title, private: tag.private, color: newColor });
    }, [tags])

    const openCustomColorModalHandler = (tag: ITag) => setCustomColorModalHandler(tag?.color ?? styles.colors.primary600, (newColor) => onEditColorHandler(tag, newColor))

    const columnsInitialState = [
        { header: 'Color', key: ['colorSelect'], show: true, width: '75px', showSmall: true },
        { header: 'Tag name', key: ['title'], show: true, sort: '', sortHandler: (e: any) => sortHandler(e), showSmall: true },
        { header: 'Tagged items', key: ['connectionsCountColumn'], show: true, showSmall: true },
        { header: 'Date created', key: ['createdAtMoment'], show: true },
        { header: 'Shared tags', key: ['mutualTags'], show: true },
        { header: 'Access', key: ['access'], show: true, width: '75px' },
        { header: 'Actions', key: ['actions'], show: true, showSmall: true }
    ]

    const [columns, setColumns] = useState(columnsInitialState)

    useEffect(() => {
        const adjustedColumns = adjustedColumnsHandler(columnsInitialState, columns, tableRef, isSmall, isMedium, sortHandler)
        setColumns(adjustedColumns)
    }, [isSmall, isMedium])

    return <Table
        ref={tableRef}
        pageLoading={tagsLoading ? pageSize : undefined}
        noDataPage={<EmptyPage svgType='tagEmpty' title={noResultTitle} label={noResultDescription} />}
        $controlBarHeight={controlBarHeight}
        columns={[
            {
                header:
                <SelectAllCheckbox
                    isCheckboxChecked={selectedTags?.length > 0 || (isAllSelected ?? false)}
                    pageSize={pageSize}
                    total={total}
                    paginated={workspaceOwner ? tags.length : tags.filter((tag: any) => tag?.users && tag?.users[0] && tag?.users[0]?._id === user._id).length}
                    isAllSelected={isAllSelected ?? false}
                    selected={selectedTags?.length}
                    label={'tags'}
                    selectAllHandler={selectAllCheckboxHandler}
                    selectOnThisPageHadler={selectOnThisPageHadler}
                    clearHandler={clearHandler}
                />,
                key: ['checkbox'],
                width: '36px',
                showSmall: true
            },
            ...columns
        ]}
        data={tags.map((tag: ITag) => {
            return (
                {
                    object: { ...tag },
                    ...!tag.isForbidden ? { onRowClick: () => openSidebar(SidebarTypes.TAG_EDIT_CREATE, { tag }) } : {},
                    checkbox: (
                        <StyledCheckbox
                            disabled={!workspaceOwner && !(tag?.users && tag?.users[0] && tag?.users[0]?._id === user._id)}
                            checked={selectedTags.some((selectedId: any) => selectedId === tag?._id)}
                            checkboxId={tag._id}
                            onClickHandler={(e: React.MouseEvent<HTMLElement>) => selectCheckboxHandler(e, tag._id)}
                        />
                    ),
                    mutualTags: (
                        <Column>
                            {tag?.users?.map((user: any) => { 
                                return <ProfilePhoto tooltipMessage={capitalLetters(user, true)} capitalLetters={capitalLetters(user)} />})}
                        </Column>
                    ),
                    access: tag.private ? 'Private' : 'Public',
                    createdAtMoment: (
                        moment(tag.createdAt).format('MMM D, YYYY [at] HH:mm')
                    ),
                    colorSelect: (
                        <div onClick={(e: any) => e.stopPropagation()}>
                            <ColorSelectDropdownFragment
                                disabled={tag.isForbidden}
                                customDesign={tag.isForbidden}
                                color={tag?.color ?? styles.colors.primary600}
                                onColorChangedHandler={(color: string) => onEditColorHandler(tag, color)}
                                openCustomColorModalHandler={() => openCustomColorModalHandler(tag)}
                            />
                        </div>
                    ),
                    connectionsCountColumn: (<>{
                        tag.connectionsCount > 0 ? 
                            <ActionText onClickHandler={(e: any) => {e.stopPropagation(); navigate(AppRoutes.CONNECTIONS,  { state: { tag } })}}>{tag.connectionsCount + ' Tagged'}</ActionText>
                                : <Text $faded $bold>{tag.connectionsCount + ' Tagged'}</Text>
                        }</>
                    ),
                    actions: !tag.isForbidden ? (
                        <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={(e: any) => e.stopPropagation()}>
                            <EditDeleteActionDropdown
                                object={tag}
                                type='tag'
                                onDeleteHandler={() => deleteTagHandler(tag._id)}
                                onEditHandler={() => openSidebar(SidebarTypes.TAG_EDIT_CREATE, { tag })}
                            />
                        </div>) : null,
                    ...tag
                }
            )
        })}
    />
}
const StyledCheckbox = styled(Checkbox)`
    height: fit-content;
`

export default TagTable
