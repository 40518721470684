import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { styles } from "../styles/themes/style";
import CustomModal from "../components/modal/CustomModal";
import { useAppHandlers } from "../services/actions/app/useAppHandlers";
import letter from '../assets/img/letter.png';
import Column from "../components/Column";
import Text from "../components/Text";
import Input from "../components/Input";
import FormControl from "../components/FormControl";
import List from '../components/list/List'
import { IFormProperty } from "../interfaces/common";
import { isValid } from "../utils";
import InvitationItemFragment from '../fragments/InvitationItemFragment'
import { useWorkspaceUsersActions } from "../stores/workspaceUsers/useWorkspaceUsersActions";
import { useWorkspaceHandlers } from "../services/actions/workspace/useWorkspaceHandlers";
import { useAppStore } from "../stores/application/useAppStore";
import { useWorkspaceUsersStore } from "../stores/workspaceUsers/useWorkspaceUsersStore";

interface ITeamFormState extends Record<string,any> {
    inviteTeammates: IFormProperty
}

const AddMembersModal: FunctionComponent = () => {
    const { hideModal } = useAppHandlers()
    const { setWorkspaceUsersFilters} = useWorkspaceUsersActions()
    const { sendInvitesHandlers } = useWorkspaceHandlers()
    const { store: { requestLoading } } = useAppStore()
    const { store: { workspaceUsersFilters } } = useWorkspaceUsersStore()

    const formOriginalState: ITeamFormState = {
        inviteTeammates: {
            property: "",
            placeholder: "coworker@company.com",
            errors: [],
            listValue: [],
            async isValid() {
                this.errors.length = 0
                let emailError = this?.property?.length > 0 && isValid.email(this?.property) ? undefined : "Wrong email format."
                emailError && this?.errors?.push(emailError)
                let alreadExists = !emailError && this?.property?.length > 0 && this.listValue.length && this.listValue.some((invite: { email: string }) => invite.email === this?.property)
                alreadExists && this?.errors?.push("Email already in the list.")
                updateErrors('inviteTeammates', this?.errors)
                return this?.errors?.length === 0
            }
        }
    }

    const [teamFormState, setTeamFormState] = useState<ITeamFormState>({ ...formOriginalState })
    const [invites, setInvites] = useState<{ email: string }[]>([]);

    const updateErrors = useCallback((control: any, newValue: string[]) => {
        setTeamFormState(previous => {
            let newState = { ...previous, [control]: { ...previous[control], errors: [...newValue]} };
            return newState
        })
    }, [teamFormState]); 

    const updateForm = useCallback((control: any, newValue: string) => {
        setTeamFormState(previous => {
            let newState = { ...previous, [control]: { ...previous[control], property: newValue.trim()} };
            return newState
        })
    }, [teamFormState]); 

    const onAddInvite = useCallback(async (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (await teamFormState?.inviteTeammates?.isValid()) {
                let newInvites = [...invites, { email: teamFormState?.inviteTeammates?.property }]
                setInvites([...newInvites])
                setTeamFormState(previous => ({ ...previous, inviteTeammates: { ...previous.inviteTeammates, property: '', listValue: [...newInvites] } }))
            }	
        }
    }, [teamFormState]); 

    const onRemoveInvite = useCallback(async (removingInvite: string) => {
        const filteredInvites = invites.filter(invite => invite.email !== removingInvite)
        setInvites([...filteredInvites])
        setTeamFormState(previous => ({ ...previous, inviteTeammates: { ...previous.inviteTeammates, property: '', listValue: filteredInvites } }))
    }, [invites]); 

    return (
        <CustomModal
            headingText={'Invite members'}
            headingTextCenter
            bodyPadding={'0 24px 0px 24px;'}
            cancelButton={{
                text: 'Cancel',
                bigButton: true,
                onClickHandler: () => {
                    setInvites([])
                    hideModal()
                }
            }}
            confirmButton={{
                text: 'Send Invites',
                bigButton: true,
                customDisabled: true,
                svgType: 'invitation',
                disable: invites.length === 0 || requestLoading?.['sendInvites'],
                isLoading: requestLoading?.['sendInvites'],
                onClickHandler: async () => {
                    const result = await sendInvitesHandlers({invites: invites})
                    if(result) {
                        setWorkspaceUsersFilters({...workspaceUsersFilters})
                        hideModal()
                    }
                }
            }}
        >
            <Container gap="16px">
                <Letter justifyCenter alignItems>
                    <Oval justifyCenter alignItems><img src={letter} alt='' style={{ width: 50 }} /></Oval>
                </Letter>
                <div style={{textAlign: 'center'}}><Text $paragraph4>Friends can search each other’s connections. You can invite your coworkers, investors, friends… peers or your community members.</Text></div>
                <div style={{margin: '12px 0 10px 0'}}>
                    <FormControl $orientation="column" label="Email address" error={teamFormState?.inviteTeammates?.errors?.[0]}>
                        <Input 
                            description='press enter'
                            prefilledValue={teamFormState?.inviteTeammates?.property}
                            placeholder={teamFormState?.inviteTeammates?.placeholder}
                            onChangeHandler={(value: string) => updateForm('inviteTeammates', value)}
                            onKeyDownHandler={(e: KeyboardEvent) => onAddInvite(e)}
                        />
                    </FormControl>
                </div>
                {!!invites?.length && (
                    <InvitesContainer>
                        {invites.map((invite, index) => 
                            (<InvitationItemFragment key={index} invite={invite.email} removeInvite={(invite) => onRemoveInvite(invite)}/>))}
                    </InvitesContainer>
                )}
            </Container>
        </CustomModal>
    )
}

const Container = styled(Column)`
    border-radius: 5px;
    margin-bottom: 25px;
`
const Letter = styled(Column)`
    height: 116px;
    background-color: ${styles.colors.primary600};
    border-radius: 4px;
`
const Oval = styled(Column)`
    width: 68px;
    height: 68px;
    background-color: #FFF;
    filter: drop-shadow(0px 0px 12px rgba(51, 57, 81, 0.10));
    border-radius: 50%;
`
const InvitesContainer = styled(List)`
    max-height: 200px;
    overflow-y: auto;
`
export default AddMembersModal