import { ICreateTag, ITag } from "../../../interfaces/models/tag";
import { SidebarTypes } from "../../../interfaces/store/appStore";
import { ITagsFilterState, TTagsParametersState } from "../../../interfaces/store/tagStore";
import { useAuthStore } from "../../../stores/auth/useAuthStore";
import { useTagsActions } from "../../../stores/tags/useTagsActions";
import { useTagsStore } from "../../../stores/tags/useTagsStore";
import { ITagsQueryParams, useTagsRequest } from "../../apis/tags/useTagsRequest";
import { useWorkspaceRequest } from "../../apis/workspace/useWorkspaceRequest";
import { useAppHandlers } from "../app/useAppHandlers";

export const useTagsHandlers = () => {

    const { getTagsPaginate, createTag, updateTag, deleteTag, deleteTags, updateTagsAccess } = useTagsRequest();
    const { setTags, setTagsFilter, setTagsParameters, resetTagsPagination, resetTagsStore } = useTagsActions()
    const { closeSidebar, setDestructiveModal, hideModal } = useAppHandlers()
    const { store: { tagsFilter, tags, tagsParameters } } = useTagsStore()
    const { getWorkspaceUsersPublicAll} = useWorkspaceRequest()
    const { store: { workspace }} = useAuthStore()

    const createTagHandler = async (payload: ICreateTag) => {
        const result = await createTag(payload);
        if(result) {
            await getTotalPaginatedTags()
            closeSidebar(SidebarTypes.TAG_EDIT_CREATE)
            return result
        }
    }

    const editTagHandler = async (id: string, payload: ICreateTag) => {
        const result = await updateTag(id, payload);
        if(result) {
            let newTags = tags.map((tag: ITag) => {
                if(tag._id === id) return ({ ...tag, ...payload})
                return tag
            })
            setTags(workspace?.showAutoTags ? newTags : newTags.filter((tag: ITag) => !tag.isForbidden))
            closeSidebar(SidebarTypes.TAG_EDIT_CREATE)
        }
    }

    const updateTagsAccessHandler = async (payload: any) => {
        const body = {
            ids: payload?.selectedTags,
            private: payload?.private
        }

        const result = await updateTagsAccess(body)

        if(result?.success) {
            let newTags = tags.map((tag: any) => {
                const updatedObj = payload?.selectedTags?.find((tagId: any) => tagId === tag._id)
                return updatedObj ? { ...tag, private: payload?.private } : tag
            })
            setTags(workspace?.showAutoTags ? newTags : newTags.filter((tag: ITag) => !tag.isForbidden))
        }
    }

    const deleteTagHandler = async (id: string) => {
        setDestructiveModal({
            headingText: "Delete tag",
            descriptionText: "Are you sure you want to delete this tag?",
            onCancelHandler: () => hideModal(),
            onConfirmHandler: () => {
                hideModal()
                deleteTagConfirmed(id)
            }
        })
    }

    const deleteTagConfirmed = async (id: string) => {
        const result = await deleteTag(id)
        if(result.success) {
            await getTotalPaginatedTags()
        }
    }
    const deleteTagsHandler = async (ids: string[]) => {
        setDestructiveModal({
            headingText: "Delete tags",
            descriptionText: "Are you sure you want to delete selected tags?",
            onCancelHandler: () => hideModal(),
            onConfirmHandler: () => {
                hideModal()
                deleteTagsConfirmed(ids)
            }
        })
    }

    const deleteTagsConfirmed = async (ids: string[]) => {
        const result = await deleteTags(ids)
        if(result.success) {
            await getTotalPaginatedTags()
        }
    }

    //Changed to paginate, we have to see if get tags is viable anywhere
    const getTagsHandler = async (params: ITagsQueryParams) => {
        const mappedParams: ITagsQueryParams = {
           searchByName: tagsFilter.searchByName || params.searchByName,
           page: params.page,
           pageSize: params.pageSize,
           usersMutual: tagsFilter.selectedUsersMutual?.length ? tagsFilter?.selectedUsersMutual.map((user: any) => user._id).join(',') : null,
           usersMutualCondition: tagsFilter.selectedUsersMutual?.length ? tagsFilter?.usersMutualCondition : null,
           tagAccessType: tagsFilter.tagAccessType && tagsFilter.tagAccessType?.value !== 'all' ? tagsFilter.tagAccessType.value : null,
           sort: tagsFilter.sort !== '' ? tagsFilter.sort : null
        }

        const result = await getTagsPaginate(mappedParams);
        if(result) {
            setTags(!result?.tags ? [] : workspace?.showAutoTags ? result?.tags : result?.tags?.filter((tag: ITag) => !tag.isForbidden))
            setTagsParameters({ total: result?.metadata?.totalPaginated ?? 0 })
        }
        return result
    }

    //there is no need to save total paginated in the store and since this has to be rewritten to be separate call we'll keep it like this
    const getTotalPaginatedTags = async () => {
        const mappedParams = {
            page: tagsParameters?.page,
            pageSize: tagsParameters?.pageSize,
            ...tagsFilter
        }
        return await getTagsHandler(mappedParams);
    }

    const setTagsFilterHandler = async (payload: ITagsFilterState) => {
        setTagsFilter(payload);
    }

    const numberOfTagsFiltersHandler = () => {
        let numberOfFilters = 0
        if(tagsFilter.selectedUsersMutual?.length) numberOfFilters += 1
        if(tagsFilter.tagAccessType && tagsFilter.tagAccessType?.value !== 'all') numberOfFilters += 1
        return numberOfFilters
    }

    const setTagsParametersHandler = (payload: TTagsParametersState) => {
        setTagsParameters(payload)
    }

    const workspaceUsersPublicAllHandler = async (params: any) => {
        const { condition, name, email } = params

        const result = await getWorkspaceUsersPublicAll({ condition, name, email })
        return result ?? []
    }

    const setClearTagsFilterHandler = () => {
        resetTagsPagination();
    }

    const resetTagsStoreHandler = () => {
        resetTagsStore();
    }

    return {
        createTagHandler,
        getTagsHandler,
        setTagsFilterHandler,
        editTagHandler,
        updateTagsAccessHandler,
        deleteTagHandler,
        deleteTagsHandler,
        getTotalPaginatedTags,
        numberOfTagsFiltersHandler,
        setTagsParametersHandler,
        workspaceUsersPublicAllHandler,
        setClearTagsFilterHandler,
        resetTagsStoreHandler
    };
}
