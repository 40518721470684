import React, { FunctionComponent } from "react"
import DropdownItem from "../DropdownItem";
import DropdownMenu from "../DropdownMenu";
import { useAuthStore } from "../../../stores/auth/useAuthStore";
import { useAppStore } from "../../../stores/application/useAppStore";
import Text from "../../Text"
import DropdownSeparator from "../DropdownSeparator";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../interfaces/store/appStore";
import { useExternalHandlers } from "../../../services/actions/navigation/useExternalHandlers";
import { useAppActions } from "../../../stores/application/useAppActions";
import styled from 'styled-components';
import { styles } from "../../../styles/themes/style";
import { useAuthHandlers } from "../../../services/actions/auth/useAuthHandlers";
import { isValidEmail } from "../../../utils";

interface IProfilePhotoDropdown { }

const ProfilePhotoDropdown: FunctionComponent<IProfilePhotoDropdown> = () => {
    const { store: { user, disablePremiumFeatures, workspaceOwner, workspaceAdmin } } = useAuthStore();
    const { store: { darkMode, extensionInstalled } } = useAppStore();
    const { setDarkMode } = useAppActions()
    const navigate = useNavigate();
    const { openHelpDesk, openOnboarding, openAbout, openRoadmap, chatWithUs } = useExternalHandlers()
    const { logoutHandler } = useAuthHandlers()

    const onLogout = () => {
        logoutHandler();

        navigate(AppRoutes.SIGNIN);
    };


    const onLightMode = React.useCallback(() => {
        setDarkMode(!darkMode);
    }, [darkMode])


    return (
        <DropdownMenu profileImage={true} $hideToggle={true} imgSource={user?.linkedinData?.profilePicture}>
            {user && <DropdownItem $nonHoverable>
                {!isValidEmail(user?.firstName) && (user?.linkedinData?.firstName && user?.linkedinData?.lastName && <Text $bold $black>{`${user.linkedinData.firstName} ${user.linkedinData.lastName}`}</Text>)}
                <Text $lighter>{user?.email}</Text>
            </DropdownItem>}

            {user &&  <DropdownSeparator />}

            <DropdownItem onClickHandler={() => openHelpDesk()} >
                <Text $bold>Need Help?</Text>
            </DropdownItem>
            <DropdownItem onClickHandler={() => openRoadmap()}>
                <Text $bold>Roadmap</Text>
            </DropdownItem> 
            <DropdownItem onClickHandler={() => openOnboarding()}>
                <Text $bold>Onboarding</Text>
            </DropdownItem>
            <DropdownItem onClickHandler={() => openAbout()}>
                <Text $bold>About Us</Text>
            </DropdownItem>


            {!(!workspaceOwner && disablePremiumFeatures) && <DropdownItem onClickHandler={() => disablePremiumFeatures ? navigate(AppRoutes.SETTINGS_BILLING) : (workspaceOwner || workspaceAdmin) ? navigate(AppRoutes.SETTINGS_WORKSPACE_ADMIN) :
                extensionInstalled ? navigate(AppRoutes.SETTINGS_EXTENSION) : navigate(AppRoutes.SETTINGS_DATA_MANAGEMENT) }>
                <Text $bold>Settings</Text>
            </DropdownItem>}
          

            <DropdownItem onClickHandler={() => chatWithUs()}>
                <Text $bold>Chat With Us</Text>
            </DropdownItem>

            {/* <LightMode
                onClick={onLightMode}

                className={`ld-mode-${!darkMode ? 'light' : 'dark'}`}
            >
                <span
                    className='ld-mode-box'
                />

                <span
                    className='ld-mode-option'
                >
                    {!darkMode ? <IconSun /> : <IconSunOutlined />}

                    <span>Light</span>
                </span>

                <span
                    className='ld-mode-option'
                >
                    {darkMode ? <IconMoon /> : <IconMoonOutlined />}

                    <span>Dark</span>
                </span>
            </LightMode> */}

            {user && <>
                <DropdownSeparator />

                <DropdownItem onClickHandler={onLogout}>
                    <Text $bold $red>Log out</Text>
                </DropdownItem>
            </>}
        </DropdownMenu>
    )
}

const LightMode = styled.div`
    position: relative; 
    display: inline-flex;
    flex-direction: row; 
    width: 150px;
    height: 36px; 
    border-radius: 4px; 
    background: ${({theme: { primary }}) => primary ? styles.colors.primary100 : styles.colors.darkBlack600}; 
    cursor: pointer; 
    user-select: none; 
    transition: transform .3s; 
    margin: 6px 20px 16px; 

    &:active {
        transform: scale(0.94); 
    }

    & .ld-mode-box { 
        position: absolute; 
        top: 0;
        left: 0;
        width: 75px;
        height: 100%; 
        background: ${styles.colors.primary600};
        border-radius: 4px;  
        transition: transform .150s ease-out; 
        z-index: 0; 
    }

    & > .ld-mode-option {
        display: inline-flex; 
        align-items: center; 
        justify-content: center; 
        padding: 0 10px; 
        flex: 1 1 75px; 
        gap: 6px;
        font-family: 'NunitoSans';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        width: 75px; 
        overflow: hidden; 
        transform: color .150s ease-out; 
        color: ${styles.colors.black300}; 
        z-index: 1; 

        & svg { 
            fill: ${({theme: { primary }}) => primary ? styles.colors.primary300 : styles.colors.black300};
            transform: fill .150s ease-out; 
        }
    } 

    &.ld-mode-light {
        & > .ld-mode-option:nth-child(2) { 
            gap: 4px; 
            color: ${({theme: { primary }}) => primary ? styles.colors.white : styles.colors.darkTextcolor}; 

            & svg { 
                height: auto; 
                width: 18px;
                fill: ${({theme: { primary }}) => primary ? styles.colors.white : styles.colors.darkTextcolor};
            }
        }
    }

    &.ld-mode-dark {
        & > .ld-mode-box {
            transform: translateX(75px); 
        }

        & > .ld-mode-option:nth-child(3) {
            gap: 6.4px; 
            color: ${({theme: { primary }}) => primary ? styles.colors.white : styles.colors.darkTextcolor}; 

            & svg { 
                height: auto; 
                width: 15px;
                fill: ${({theme: { primary }}) => primary ? styles.colors.white : styles.colors.darkTextcolor};
            }
        }
    }
`;


export default ProfilePhotoDropdown