import { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import TabsView, { ITabProperties } from '../../../../components/TabsView'
import TeamManagement from './admin/TeamManagement'
import AccountOverview from './admin/AccountOverview'
import { useLocation } from 'react-router-dom'

interface IWorkspaceAdminProps { }

const WorkspaceAdmin: FunctionComponent<IWorkspaceAdminProps> = () => {
    const location = useLocation()
    let { preSelectedSlug } = location.state || {}
    const [ selectedSlug, setSelectedSlug ] = useState<string>('teamManagement')

    const adminTabs = [{
        slug: "teamManagement",
        title: "Team Management",
        selected: true
    },
    {
        slug: "accountOverview",
        title: "Account Overview",
        selected: false
    }];

    const [ tabs, setTabs ] = useState<ITabProperties[]>(adminTabs);

    useEffect(() => {
        if(preSelectedSlug ) {
            setSelectedSlug(preSelectedSlug);
            switchTabs(preSelectedSlug);
        }
    }, [preSelectedSlug])

    const renderComponent: {[key: string]: ReactNode} = {
        teamManagement: <TeamManagement/>,
        accountOverview: <AccountOverview />,
    }

    const switchTabs = useCallback((slug: string) =>{
        setSelectedSlug(slug)
        setTabs(tabs.map(tab => ({...tab, selected: tab.slug === slug})))
    }, [tabs])

    return <Container>
                <TabsView onSwitchTab={(slug)=>switchTabs(slug)} tabs={tabs}>
                    {renderComponent[selectedSlug]}
                </TabsView>
            </Container>

}
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow-y:auto;
    height: 100%;

    @media (max-width: 786px) {
        padding: 0px;
        align-items: start;
    }
`
export default WorkspaceAdmin
