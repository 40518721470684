import { ForwardedRef, FunctionComponent, ReactComponentElement, ReactNode, forwardRef, useEffect } from "react";
import styled from "styled-components";
import Text from "./Text";
import { styles } from "../styles/themes/style";
import Column from "./Column";
import LoaderCell from "./LoaderCell";
import Row from "./Row";

//Duleeee, these changes did not impact the set column width, anyway one of the things that is happening is that when click on the sorting
//the teble width varies and makes  everything look askew, you are the only one that can do this!!
export interface IColumn {
    header: string | ReactNode;
    key: string[];
    width?: string;
    sort?: string;
    show?: boolean;
    showSmall?: boolean;
    showMedium?: boolean;
    sortHandler?: (column: IColumn) => void;
}

interface IStyledTableData {
    $bodySectionGap?: string;
    $width?: string;
    $height?: string;
    $hover?: boolean;
    $justifyRight?: boolean;
    isConnectionTable?: boolean;
    $hideColumns?: boolean;
    $hideSmall?: boolean;
    $hideMedium?: boolean;
    ref?: React.Ref<HTMLDivElement>;
    $controlBarHeight?: number
    onScrollHandler?: (e: any) => void
}

interface ITableProps extends IStyledTableData {
    columns: IColumn[];
    data: Record<string, any>[];
    pageLoading?: number; //whole data refresh preloader getting the page Size to mimick full table
    appendLoading?: boolean; //infinite scroll preloader
    noDataPage?: ReactNode;
}

interface IDropdownArrowProps {
    rotateArrow?: string
    hoverEffect?: boolean
}

interface IStyledBodyRowStyle {
    onRowClick?: boolean;
    $backgroundColor?: string;
    $borderColor?: string;
}

const DropdownArrow: FunctionComponent<IDropdownArrowProps> = ({ rotateArrow, hoverEffect }) =>
    <StyledSVG rotateArrow={rotateArrow} hoverEffect={hoverEffect} xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7">
        <path fill="#5964FF" fillRule="evenodd" d="M6.193 14.215l.623.6c.257.246.674.247.932 0L12 10.747l4.252 4.068c.258.247.675.247.932 0l.623-.6c.257-.247.257-.648 0-.896l-5.34-5.133c-.258-.248-.676-.248-.933 0l-5.34 5.133c-.258.247-.258.649 0 .896z" transform="translate(-209 -182) translate(30 120) translate(11 54) translate(162)" />
    </StyledSVG>

//It can go by column number but it looks ugly, change 5 with columnNumber
const TablePreloader: FunctionComponent<{rowNumber: number, columnNumber: number}> = ({ rowNumber, columnNumber }) => 
<Column gap='3px'>
    {Array(rowNumber).fill(undefined).map((x: any, index: number) => <PreloaderRow key={index} gap="18px">
        { Array(5).fill(undefined).map((x: any, indexCol: number) => <LoaderCell key={indexCol} $width={`${100/5}%`} $height="37px"/>) }
    </PreloaderRow>)}
</Column>

const Table = forwardRef<HTMLDivElement, ITableProps>(({pageLoading = undefined, appendLoading = false, noDataPage, columns, data, isConnectionTable, $hideColumns, $height, $controlBarHeight, onScrollHandler, $bodySectionGap }, ref) => {
    return (
        <Container ref={ref}>
            <HeaderSection $hideColumns={$hideColumns} $controlBarHeight={$controlBarHeight}>
                {columns.map((column, index) => {
                    if(column?.hasOwnProperty('show') ? column.show : true) {
                        return (
                            <TableHeader 
                                key={index} 
                                $hover={!!column.sortHandler} 
                                $width={column.width} 
                                onClick={() => column.sortHandler && column.sortHandler(column)} 
                                $justifyRight={columns?.length > 1 && index === columns?.length - 1} 
                                $hideSmall={!column?.showSmall}
                                $hideMedium={!column?.showSmall && !column?.showMedium}
                                >
                                 <Row alignItems gap="5px" >
                                    <Text $lighter $heading6>
                                        {column.header}
                                    </Text>
                                    {!!column.sortHandler && (column.sort?.length ? <DropdownArrow rotateArrow={column.sort} /> : <div style={{width: '12px'}}></div>)}
                                </Row>
                            </TableHeader>
                        )
                    }
                })}
            </HeaderSection>
            {pageLoading ? ( <TablePreloader rowNumber={pageLoading} columnNumber={columns.length}/> ) :
                (
                    data?.length === 0 && noDataPage ? (
                        <NoResultContainer>{noDataPage}</NoResultContainer>
                ) : (
                    <BodySection $height={$height} $bodySectionGap={$bodySectionGap} onScroll={onScrollHandler}>
                        {data.map(({onRowClick, object, ...row}, rowIndex) => (
                            <StyledBodyRow 
                                key={rowIndex}
                                $backgroundColor={object?.backgroundColor}
                                $borderColor={object?.borderColor}
                                onRowClick={typeof onRowClick === 'function'}
                                onClick={() => onRowClick && onRowClick(object)}
                            >
                                {columns.map((column, colIndex) => {
                                    if(column?.hasOwnProperty('show') ? column.show : true) {
                                        return (
                                            <TableData 
                                                onClick={() => console.log(column.key)} 
                                                key={colIndex} 
                                                $width={column.width} 
                                                $hideSmall={!column?.showSmall}
                                                $hideMedium={!column?.showSmall && !column?.showMedium}
                                                >
                                                {column.key.map((key, index) => (
                                                    <>{row[key]}</>
                                                ))}
                                            </TableData>
                                        )
                                    }
                                })}
                            </StyledBodyRow>
                        ))}
                        {appendLoading && <TablePreloader rowNumber={2} columnNumber={columns.length}/>}
                    </BodySection> 
                    )
                )
            }
        </Container>
    );
});

const Container = styled.div`
    width: 100%;
`;

const HeaderSection = styled.div<IStyledTableData>`
    display: ${({$hideColumns}) => $hideColumns ? 'none' : 'flex'};
    align-items: center;
    padding: 10px 15px;
    justify-content: space-between;

    ${({ $controlBarHeight, theme }) => 
        $controlBarHeight !== undefined && `
            position: sticky;
            top: ${$controlBarHeight ? `${$controlBarHeight}px` : '0px'};
            z-index: 100;
            background: ${theme.tertiaryColor};
        `
    }
`;

const BodySection = styled.div<IStyledTableData>`
    display: flex;
    flex-direction: column;
    gap: ${({$bodySectionGap}) => $bodySectionGap ?? '2px'};
    ${({ $height}) => $height && `
        max-height: ${$height};
        overflow-y: auto;
    `}
`;

const TableHeader = styled.div<IStyledTableData>`
    display: flex;
    align-items: center;
    padding: 0 2px;
    ${({ $justifyRight }) => $justifyRight && `justify-content: flex-end;`}
    ${({ $width }) => $width?.length ? `width: ${$width}` : `flex: 1`};

    ${({ $hover }) => $hover && `
        cursor: pointer;

        &:hover { 
            & span { color: ${styles.colors.primary600}; }
        }
    `}
    @media (max-width: 768px) {
        ${({ $hideSmall }) => $hideSmall && `
        display: none;
    `}
    }
    @media (max-width: 1240px) {
        ${({ $hideMedium }) => $hideMedium && `
            display: none;
    `}
    }
`;

const StyledBodyRow = styled.div<IStyledBodyRowStyle>`
    display: flex;
    align-items: flex-start;
    padding: 10px 15px;
    background: ${({ theme: { secondaryColor }, $backgroundColor }) => $backgroundColor ?? secondaryColor};
    ${({ $borderColor }) => $borderColor && `
        border: 1px solid ${$borderColor};
        
        &:hover {
            border: 1px solid ${styles.colors.primary600};
        }
    `};
    border-radius: 4px;
    justify-content: space-between;

    ${({onRowClick}) => onRowClick ? `
        cursor: pointer;
        &:hover { box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1); }
    ` : null}
`;

const TableData = styled.div<IStyledTableData>`
    padding: 0 2px;
    overflow: unset;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // overflow: unset;
    ${({ $width }) => $width?.length ? `width: ${$width}` : `flex: 1`};
    @media (max-width: 768px) {
        ${({ $hideSmall }) => $hideSmall && `
        display: none;
    `}
    }
    @media (max-width: 1240px) {
        ${({ $hideMedium }) => $hideMedium && `
            display: none;
    `}
`;
const StyledSVG = styled.svg<IDropdownArrowProps>`
    position: relative;
    transition: all .2s ease;
    ${({ rotateArrow }) => rotateArrow === 'az' ? 'transform: rotate(180deg);' : ''}
    ${props => props.hoverEffect && 'cursor: pointer;'}
    ${props => props.hoverEffect && 'fill: #8C96AD;'}
`;
const PreloaderRow = styled(Row)`
    background: ${({ theme: { secondaryColor } }) => secondaryColor};
    align-items: center;
    padding: 10px 5px;
`
const NoResultContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 60px 15px;
    flex-direction: column;
    gap: 16px;
    font-size: 14px;
`;
export default Table;
